import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import { IEinteilungForm } from "../../stores/einteilungforms"
import einteilungforms from "../../stores/einteilungforms"
import locations from "../../stores/locations"
import userdata from "../../stores/userdata"
import Loading from "../../components/loading"
import { jumpTo } from "."
import { Send } from "@material-ui/icons"
import Table from "../../components/table"
import { sendGeneralMail } from "../../api/mailservice"
import { toast } from "react-toastify"

interface ICustomMailState {
    statusfilter: string[]
    clubfilter: string[]
    mailcontent: string
}

export const renderStatus = (obj) => {
    switch (obj.status) {
        case "0":
            return "Entwurf"
        case "1":
            return "Offen"
        case "2":
            return "Geschlossen"
        default:
            return ""
    }
}

@observer
class CustomMail extends Component<{}, ICustomMailState> {
    constructor(props) {
        super(props)

        this.state = {
            statusfilter: ["0", "1", "2"],
            clubfilter: ["1", "2", "3", "4", "5", "6"],
            mailcontent: "",
        }

        this.filter = this.filter.bind(this)
        this.confirmFilter = this.confirmFilter.bind(this)
        this.onSelectAll = this.onSelectAll.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { columns, data } = einteilungforms.mailView("all")
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading />}
                <div className="leftsection">
                    {/* <HeaderFilter
                        filteroptions={einteilungforms.filteroptions(true)}
                        currentstate={
                            {
                                "Status": this.state.statusfilter,
                                "Club": this.state.clubfilter
                            }}
                        onConfirm={this.confirmFilter}
                        onOptionFilter={this.filter}>
                    </HeaderFilter> */}
                    <Table
                        columns={columns}
                        data={data}
                        onSort={this.onSort}
                        sortKey={einteilungforms.sortCol}
                        colSort={einteilungforms.sortAsc}
                        onSelect={this.onSelect}
                        onSelectAll={this.onSelectAll}
                    ></Table>
                </div>
                <div className="rightsection">
                    {this.renderCustomtextField()}
                    {this.renderSendbutton()}
                </div>
            </React.Fragment>
        )
    }

    public componentDidMount() {
        if (!locations.loaded) {
            locations.load()
        }
        if (!userdata.loaded) {
            userdata.load()
        }
        if (einteilungforms.loaded) {
            einteilungforms.setFilterObj({ status: ["0", "1", "2"] })
        } else {
            einteilungforms.load({})
        }
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    private onOpen(item: IEinteilungForm) {
        jumpTo({ type: "view", id: item.einteilungID })
    }

    private onSelect(item: IEinteilungForm) {
        if (item.selected) {
            item.unselect()
        } else {
            item.select()
        }
    }

    private onSelectAll(data) {
        // const relevantEinteilungen = einteilungforms.filter({ status: only === "onlyopen" ? "1" : "2" })
        // relevantEinteilungen.map((item) => {
        //     if (data) {
        //         item.select()
        //     } else {
        //         item.unselect()
        //     }
        // })
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, statusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        statusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                statusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "Status") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private confirmFilter(filter) {
        einteilungforms.setFilterObj({ locationIDs: filter.Club, status: filter.Status })
    }

    private renderCustomtextField() {
        const { mailcontent } = this.state
        return <textarea value={mailcontent} onChange={(val) => this.updateTextcontent(val)}></textarea>
    }

    private updateTextcontent(ev) {
        ev.persist()
        this.setState({
            mailcontent: ev.target.value,
        })
    }

    private renderSendbutton() {
        // const { only } = this.props
        if (this.state.mailcontent !== "" && einteilungforms.countSelected() > 0) {
            return (
                <div
                    className="action-icon"
                    onClick={() => {
                        const allSelected = einteilungforms.items.filter((el) => el.selected === true)
                        const entered = allSelected.map((el) => el.entered)
                        const allentered: any[] = []
                        entered.forEach((el) => {
                            el.forEach((item) => {
                                if (allentered.indexOf(item.id) === -1) {
                                    allentered.push(item.id)
                                }
                            })
                        })
                        const content = this.state.mailcontent
                        sendGeneralMail("custom", [], allentered, content).then((res) => {
                            if (res === "success") {
                                toast.success("Nachrichten gesendet", { autoClose: 2500 })
                            }
                        })
                    }}
                >
                    <Send></Send>
                    <span>Senden</span>
                </div>
            )
        } else if (this.state.mailcontent === "") {
            return (
                <div className="action-icon disabled">
                    <Send></Send>
                    <span>Leeres Textfeld</span>
                </div>
            )
        } else if (einteilungforms.countSelected() <= 0) {
            return (
                <div className="action-icon disabled">
                    <Send></Send>
                    <span>Noch keine Auswahl</span>
                </div>
            )
        }
    }
}

export default CustomMail
