import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

interface IAddToAbrechnung {
    date: string
    locationID: string
    personaldata: IAddToAbrechnungPersonaldata[]
}

export interface IAddToAbrechnungPersonaldata {
    userid: string
    position: string
    starttime: string
}

export const addEinteilungToAbrechnung = (data: IAddToAbrechnung): Promise<any> => {
    const { date, locationID, personaldata } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `applytoabrechnung`,
                userid: getCookie("userid"),
                date,
                locationID,
                personaldata,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                if (res !== "fail") {
                    //   einteilungforms.addToEinteilungen(res[0]);
                }
                resolve(result)
            })
            .always((res: any) => resolve(res.responseText))
    })
}
