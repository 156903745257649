import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import positions from "../../stores/positions"
import einteilungforms, { IEinteilungForm } from "../../stores/einteilungforms"
import locations from "../../stores/locations"
import { jumpTo } from "."
import DetailView from "../../components/detailview"
import { updateEinteilungform } from "../../api/neweinteilungform"
import { deleteEinteilung } from "../../api/geteinteilungen"
import { toast } from "react-toastify"

interface IEinteilungProps {
    einteilung: IEinteilungForm
    filter: any
}

interface IEinteilungState {
    status: string
}

@observer
class EinteilungEdit extends Component<IEinteilungProps, IEinteilungState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)

        this.state = {
            status: this.props.einteilung.status,
        }

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.renderStatusDropdown = this.renderStatusDropdown.bind(this)
    }

    public render() {
        const { einteilung, filter } = this.props
        const location = locations.find(einteilung.locationID)
        if (getCookie("token").length === 0) {
            return <Redirect to="/" />
        }
        return (
            <React.Fragment>
                <DetailView
                    header={() => this.renderHeader(einteilung, location)}
                    content={() => {
                        return <React.Fragment>{this.renderStatusDropdown()}</React.Fragment>
                    }}
                    onClose={() => jumpTo({ type: "list", filter })}
                    onDelete={() => {
                        const id = einteilung.einteilungID
                        deleteEinteilung(id).then((res) => {
                            if (res === "success") {
                                toast.success("Erfolgreich gelöscht", { autoClose: 2500 })
                                einteilungforms.load({})
                                jumpTo({ type: "list", filter })
                            } else {
                                toast.error("Löschen fehlgeschlagen", { autoClose: 2500 })
                            }
                        })
                    }}
                    onSave={() =>
                        this.saveFormUpdate(() => {
                            jumpTo({ type: "list", filter })
                        })
                    }
                ></DetailView>
            </React.Fragment>
        )
    }

    public componentDidMount() {
        positions.load()
    }

    private renderHeader(einteilung: IEinteilungForm, location: any) {
        return (
            <React.Fragment>
                {location && <div className="locationname">{location.locationName}</div>}
                <div className="einteilung">Einteilung {einteilung.readableDate}</div>
                <div className="einteilung-title">{einteilung.title}</div>
                <span>Eingetragen:</span>
            </React.Fragment>
        )
    }

    private renderStatusDropdown(): JSX.Element {
        return (
            <select
                value={this.state.status}
                onChange={(ev) => {
                    ev.persist()
                    this.setState({ status: ev.target.value })
                }}
            >
                <option value="0">Entwurf</option>
                <option value="1">Offen</option>
                <option value="2">Geschlossen</option>
            </select>
        )
    }

    private saveFormUpdate(nextstep) {
        const { einteilung } = this.props
        const operation = "updatestatus"
        const newstatus = this.state.status
        einteilung.updateStatus(newstatus)
        updateEinteilungform(einteilung.einteilungID, newstatus, operation, nextstep)
    }
}

export default EinteilungEdit
