import React, { Component } from "react"
import { observer } from "mobx-react"
import "./detailview.css"

interface IDetailViewProps {
  header?: () => JSX.Element
  content: () => JSX.Element | JSX.Element[]
  onClose?: () => void
  onSave?: () => void
  onCancel?: () => void
  onDelete?: () => void
  onEdit?: (allowed?: boolean) => void
  allowSave?: boolean
  allowEdit?: boolean
  customActions?: React.ReactNode[]
}

@observer
class DetailView extends Component<IDetailViewProps, {}> {
  constructor(props: any) {
    super(props)

    this.renderFooter = this.renderFooter.bind(this)
  }

  public render() {
    const { header, content } = this.props
    return (
      <React.Fragment>
        {header &&
          <div className="section-head">{header()}</div>
        }
        <div className="section-content">{content()}</div>
        <div className="section-footer">{this.renderFooter()}</div>
      </React.Fragment>
    )
  }

  private renderFooter() {
    const { onClose, onSave, onEdit, onCancel, allowEdit, onDelete, customActions } = this.props
    return (
      <React.Fragment>
        {onClose && (
          <div className="formbutton close" onClick={() => onClose()}>
            Schließen
          </div>
        )}
        {onSave && (
          <div className={"formbutton bg-green-700" + (allowEdit ? " allow" : " disallow")} onClick={() => onSave()}>
            Speichern
          </div>
        )}
        {onEdit && (
          <div className={"formbutton" + (allowEdit ? " allow" : " disallow")} onClick={() => onEdit()}>
            Bearbeiten
          </div>
        )}
        {onCancel && (
          <div className={"formbutton"} onClick={() => onCancel()}>
            Abbrechen
          </div>
        )}
        {onDelete && (
          <div className={"formbutton delete"} onClick={() => onDelete()}>
            Löschen
          </div>
        )}
        {customActions && customActions.map((el) => el)}
      </React.Fragment>
    )
  }

}

export default DetailView
