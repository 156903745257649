import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"
import { myeinteilung } from "../stores"
import einteilungforms from "../stores/einteilungforms"

export interface INewEinteilungForm {
    date: string
    ts: number
    title: string
    locationID: string
    additionalPos: string
    additionalInfo: string
    status: string
    postimes: string
    mode: number
}

export interface IAddToEinteilung {
    einteilungID: string
    name: string
    myID: string
    position?: string
}

export const addEinteilungForm = (data: INewEinteilungForm): Promise<any> => {
    const { date, ts, title, locationID, additionalInfo, additionalPos, status, postimes, mode } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `addeinteilungform`,
                date,
                ts,
                title,
                locationID,
                additionalPos,
                additionalInfo,
                mode: mode.toString(),
                status,
                postimes,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                if (res !== "fail") {
                    einteilungforms.load({})
                }
                resolve(result)
            })
            .always((res: any) => resolve(res.responseText))
    })
}

export const bulkupdateEinteilungFormStatus = (data: { ids: string[]; status }): Promise<any> => {
    const { ids, status } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `bulkupdateeinteilungstatus`,
                ids: JSON.stringify(ids),
                status,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                if (res !== "fail") {
                    // einteilungforms.addToEinteilungen(res[0])
                }
                resolve(result)
            })
            .always((res: any) => resolve(res.responseText))
    })
}

export const addMeToEinteilung = (data: IAddToEinteilung): Promise<any> => {
    const { myID, name, einteilungID } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `addentered`,
                einteilungID,
                userObj: { id: myID, name, position: "" },
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                if (res.responseText === "successful") {
                    //   addToMyEntered(parseInt(myID), einteilungID);
                }
                resolve(res.responseText)
            })
    })
}

export const addMeToEinteilungConfirmed = (data: IAddToEinteilung): Promise<any> => {
    const { myID, name, einteilungID } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `addconfirmed`,
                einteilungID,
                userObj: { id: myID, name, position: "" },
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                if (res.responseText === "successful") {
                    // removeFromMyEntered(parseInt(myID), einteilungID);
                    // addToMyConfirmed(myID, einteilungID)
                }
                resolve(res.responseText)
            })
    })
}

export const removeMeFromEinteilung = (data: IAddToEinteilung): Promise<any> => {
    const { myID, name, einteilungID } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `removeentered`,
                einteilungID,
                userObj: { id: myID, name, position: "" },
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                if (res.responseText === "successful") {
                    // removeFromMyEntered(parseInt(myID), einteilungID).then((response) => {

                    // })
                    myeinteilung.removeById(einteilungID)
                }
                resolve(res.responseText)
            })
    })
}

export const updateEinteilungform = (
    einteilungID,
    data,
    operation: "updatestatus" | "updatetitle" | "updateeinteilung",
    nextstep?: () => void,
): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `updateeinteilungform`,
                operation,
                einteilungID,
                data,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                if (nextstep) {
                    if (operation === "updatestatus") {
                        const einteilunglist = einteilungforms.filter((el) => el.einteilungID === einteilungID)
                        if (einteilunglist.length > 0) {
                            einteilunglist[0].updateStatus(data)
                            nextstep()
                        }
                    } else if (operation === "updateeinteilung") {
                        nextstep()
                    }
                }
                // resolve(res.responseText)
            })
    })
}

export const confirmEinteilungForUsers = (
    einteilungID: string,
    userIDs: Array<{ id: string; position: string; time: string }>,
    availableUsers: string[],
): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `confirmeinteilungforusers`,
                einteilungID,
                userIDs,
                availableUsers,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                // if (res.responseText === "successful") {
                //     removeFromMyEntered(parseInt(myID), einteilungID)
                // }
                // resolve(res.responseText)
            })
    })
}
