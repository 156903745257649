import React, { Component } from "react"
import { observer } from "mobx-react"
import "./locationpicker.css"
import locations from "../../stores/locations"

interface ILocationPickerProps {
    onSelect: (event: any) => void
}

@observer
class LocationPicker extends Component<ILocationPickerProps, {}> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)

        this.state = {
            dropopen: false,
        }

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
    }

    public render() {
        return (
            <>
                <select onChange={this.props.onSelect}>
                    {
                        locations.items.map((el, idx) => {
                            return (<option key={idx} value={el.locationID}>{el.locationName}</option>)
                        })
                    }
                </select>
            </>
        )
    }

    public componentDidMount() {
        if (!locations.filled) {
            locations.load()
        }
    }
}

export default LocationPicker
