import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import einteilungforms from "../../stores/einteilungforms"
import positions from "../../stores/positions"
import locations from "../../stores/locations"
import { updateApphead } from "../../utils/dommanip"
import Loading from "../../components/loading"
import "./einteilungsview.css"
import EinteilungOverviewList from "./einteilung_list"
import EinteilungView from "./einteilung_view"
import qs from "qs"
import bind from "bind-decorator"
import { DateTime } from "luxon"

const basepath = "/einteilung"

interface IGetUrlEinteilung {
    id: string
    filter: any
    type: string
}

type TGetUrlExtended = TGetUrl | IGetUrlEinteilung

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrlExtended): string => {
    const filter = opt.filter !== undefined ? "?" + qs.stringify(opt.filter) : ""
    switch (opt.type) {
        case "view":
            return `${basepath}/view/${opt.id}${filter}`
        case "multiview":
            return `${basepath}/multiview/${opt.id}`
        case "list":
            return `${basepath}/list${filter}`
        default:
            return basepath
    }
})

interface IEinteilungProps
    extends RouteComponentProps<{
        id: string
    }> { }

@observer
class Einteilung extends Component<IEinteilungProps, {}> {
    constructor(props: any, context?: any) {
        super(props, context)

        this.list = this.list.bind(this)
        this.listHandler = this.listHandler.bind(this)
        // this.showMain = this.showMain.bind(this);
        this.viewItem = this.viewItem.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "list" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "view", id: ":id" })} exact render={this.viewItem} />
                <Route path={getUrl({ type: "multiview", id: ":id" })} exact render={this.viewMulti} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Einteilungen", basepath)
        if (!userdata.loaded) {
            userdata.load()
        }
        if (!einteilungforms.loaded) {
            einteilungforms.load({})
        }
        if (!positions.loaded) {
            positions.load()
        }
        if (!locations.loaded) {
            locations.load()
        }
    }

    private listHandler({ match, location }: IEinteilungProps): JSX.Element {
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        return this.list(filter, "", "list")
    }

    private list(searchparams: any, einteilungid: string, type: string): JSX.Element {
        if (searchparams) {
            const filterObject = searchparams
            filterObject.status = "2"
            einteilungforms.setFilterObj(filterObject)
        }
        const defaultClub = JSON.parse(getCookie("recnews"))
        // let clubnum = "1"
        if (defaultClub.length > 0) {
            // clubnum = defaultClub[0] + ""
        }
        return (
            <EinteilungOverviewList
                type={type}
                clubfilter={searchparams.locationIDs ? searchparams.locationIDs : ""}
                id={einteilungid}
                status={"2"}
            />
        )
    }

    @bind
    private viewMulti({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params

        const ids = id.split(";")
        const className = `einteilung-multi parting-${ids.length}`
        const einteilungen = ids.map((el) => einteilungforms.find(el))
        return (
            <div className="einteilung-multi-wrp">
                {einteilungen.map((eint, idy) => {
                    if (eint) {
                        const locationPosList = positions.items.map((el) => {
                            if (el.locationID === eint.locationID) {
                                if (el) {
                                    return el
                                }
                            }
                            return undefined
                        })

                        const filteredList = locationPosList.filter((el) => el !== undefined)

                        filteredList.sort((a, b) => {
                            if (a && b) {
                                if (eint.locationID === "1" || eint.locationID === "6") {
                                    return parseInt(a.sortNo, 10) > parseInt(b.sortNo, 10)
                                        ? 1
                                        : parseInt(b.sortNo, 10) > parseInt(a.sortNo, 10)
                                            ? -1
                                            : 0
                                }
                                return parseInt(a.positionID, 10) > parseInt(b.positionID, 10)
                                    ? 1
                                    : parseInt(b.positionID, 10) > parseInt(a.positionID, 10)
                                        ? -1
                                        : 0
                            }
                            return 0
                        })
                        const parsedDate = DateTime.fromFormat(eint.date, "yyyy-MM-dd").toFormat("dd.MM.yyyy")
                        const club = locations.find(eint.locationID)
                        if (filteredList.length <= 0) {
                            return <Redirect to={getUrl({ type: "list" })}></Redirect>
                        }
                        return (
                            <div className={className} key={idy}>
                                <span className={`parting-${ids.length}`}>
                                    {club ? club.locationName : ""} {parsedDate}
                                </span>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Position</th>
                                            <th>Mitarbeiter</th>
                                            <th>Start</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredList.map((item, idx) => {
                                            if (item) {
                                                const eingeteilt = eint.findForPos(item.positionID)
                                                const isObl =
                                                    eint.oblpos.filter((elem) => elem === item.positionID).length > 0
                                                const show =
                                                    item.showineinteilung === "1" || eingeteilt != null || isObl
                                                if (show) {
                                                    if (eingeteilt) {
                                                        const usr = userdata.find(eingeteilt.id)
                                                        let name = eingeteilt.name
                                                        if (usr) {
                                                            name = usr.realName
                                                        }
                                                        return (
                                                            <tr key={idx}>
                                                                <td>{item.positionName}</td>
                                                                <td>{name}</td>
                                                                <td>{this.renderTime(item, eint)}</td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <tr key={idx} className={`${isObl ? "needed" : ""}`}>
                                                                <td>{item.positionName}</td>
                                                                <td>{isObl ? "offen" : "-"}</td>
                                                                <td>{this.renderTime(item, eint)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                }
                                            }
                                            return <></>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )
                    }
                    return <></>
                })}
            </div>
        )
    }

    @bind
    private renderTime(el, einteilung) {
        const einteilungPos = einteilung.customtimes.filter((elem) => elem.pos === el.positionID)
        if (einteilungPos.length > 0) {
            if (einteilungPos[0].time !== "") {
                return einteilungPos[0].time
            }
        }
        return el.starttime
    }

    private viewItem({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params
        const einteilung = einteilungforms.find(id)
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        if (einteilung) {
            return (
                <React.Fragment>
                    <div className="leftsection">{this.list(filter, "", "view")}</div>
                    <div className="rightsection">
                        <EinteilungView filter={filter} einteilung={einteilung}></EinteilungView>
                    </div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Einteilung
