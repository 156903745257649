import React, { Component } from "react"
import { observer } from "mobx-react"
import DatePicker from "react-datepicker"
import "./datepicker.css"
import { DateTime } from "luxon"
import "react-datepicker/dist/react-datepicker.css"
interface IDatePickerState {
    from: Date
    to: Date
}

interface IDatePickerProps {
    onDateConfirm: (data) => void
}

// tslint:disable: max-line-length
const defaultfrom = DateTime.local()
    .plus({ month: 2 })
    .toJSDate()
const defaultto = DateTime.local()
    .minus({ day: 1 })
    .toJSDate()
// tslint:enable: max-line-length

@observer
class HeaderDatePicker extends Component<IDatePickerProps, IDatePickerState> {
    constructor(props: any) {
        super(props)

        this.state = {
            from: defaultfrom,
            to: defaultto,
        }

        this.handleFromChange = this.handleFromChange.bind(this)
        this.handleToChange = this.handleToChange.bind(this)
        this.confirmDateSelection = this.confirmDateSelection.bind(this)
    }

    public render() {
        return (
            <div className="header-datepicker">
                {this.renderDatePickerTo()}
                {this.renderDatePickerFrom()}
                {this.confirmSelectionButton()}
            </div>
        )
    }

    private renderDatePickerFrom() {
        return <DatePicker dateFormat="dd.MM.yyyy" selected={this.state.from} onChange={this.handleFromChange} />
    }

    private renderDatePickerTo() {
        return <DatePicker dateFormat="dd.MM.yyyy" selected={this.state.to} onChange={this.handleToChange} />
    }

    private confirmSelectionButton() {
        return (
            <div className="date-confirm" onClick={() => this.confirmDateSelection()}>
                OK
            </div>
        )
    }

    private handleFromChange(value: any) {
        // const d = new Date(value)
        // const n = d.getDay()
        this.setState({
            from: value,
        })
    }

    private handleToChange(value: any) {
        // const d = new Date(value)
        // const n = d.getDay()
        this.setState({
            to: value,
        })
    }

    private confirmDateSelection() {
        const { onDateConfirm } = this.props
        const { from, to } = this.state
        const parsedFrom = from.toISOString()
        const parsedTo = to.toISOString()
        onDateConfirm({ from: parsedFrom, to: parsedTo })
    }
}

export default HeaderDatePicker
