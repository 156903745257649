import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import einteilungforms, { IEinteilungForm } from "../../stores/einteilungforms"
import { myeinteilung } from "../../stores"
import locations, { LOCATIONS } from "../../stores/locations"
import guistate from "../../stores/guistate"
import userdata from "../../stores/userdata"
import Loading from "../../components/loading"
import { jumpTo } from "."
import {
    CheckBox,
    CheckBoxTwoTone,
    IndeterminateCheckBoxTwoTone,
    OfflinePinTwoTone,
    HttpsTwoTone,
    CheckBoxOutlineBlank,
    Close,
} from "@material-ui/icons"
import Table from "../../components/table"
import HeaderFilter from "../../components/Headerfilter"
import { DateTime } from "luxon"
import bind from "bind-decorator"

interface IListState {
    mystatusfilter: string[]
    clubfilter: string[]
    blockDialog: string[]
    blockDialogOpen: boolean
    progressOpen: boolean
    progress: [number, number]
}

export const renderOutAndClosedIcon = (): JSX.Element => {
    return <HttpsTwoTone></HttpsTwoTone>
}

export const renderInAndClosedIcon = (): JSX.Element => {
    return <OfflinePinTwoTone></OfflinePinTwoTone>
}

export const renderInIcon = (): JSX.Element => {
    return <CheckBoxTwoTone></CheckBoxTwoTone>
}

export const renderOutIcon = (): JSX.Element => {
    return <IndeterminateCheckBoxTwoTone></IndeterminateCheckBoxTwoTone>
}

export const DAYS = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]

interface IEnterEinteilungListProps {
    userid: string
    clubfilter: string
    mystatusfilter: string
    type: string
    id: string
}

@observer
class EnterEinteilungList extends Component<IEnterEinteilungListProps, IListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            mystatusfilter: ["0", "1", "2"],
            clubfilter: JSON.parse(getCookie("recnews")).map((el) => "" + el),
            blockDialog: [],
            blockDialogOpen: false,
            progressOpen: false,
            progress: [0, 0]
        }

        this.filter = this.filter.bind(this)
        this.onOpen = this.onOpen.bind(this)
        this.filterFunc = this.filterFunc.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { userid, id } = this.props

        let prefLocations = guistate.preferredLocations

        const allowBlock = prefLocations.includes("2") || prefLocations.includes("3") || prefLocations.includes("4")

        // const { clubfilter, mystatusfilter } = this.state
        const { clubfilter, mystatusfilter } = this.props
        const { columns, data } = einteilungforms.tableView(false, [clubfilter], userid, [mystatusfilter])
        if (einteilungforms.loaded) {
            einteilungforms.items.forEach((item) => {
                if (item.entered) {
                    const entereditems = item.entered.filter((el) => el.id === userid)
                    if (entereditems.length > 0) {
                        myeinteilung.addToConfirmedList(item)
                    }
                }
                if (item.available) {
                    const availableitems = item.available.filter((el) => el.id === userid)
                    if (availableitems.length > 0) {
                        myeinteilung.addToAvailableList(item)
                    }
                }
            })
        }
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading></Loading>}
                <HeaderFilter
                    filteroptions={einteilungforms.filteroptions(false, true)}
                    currentstate={{
                        locationIDs: clubfilter,
                        mystatus: mystatusfilter,
                    }}
                    hidestatus={true}
                    onConfirm={this.confirmFilter}
                    removeFilter={this.removeFilter}
                    withdatefilter={true}
                    withfilterbutton={true}
                    onDateConfirm={this.onDateConfirm}
                    onOptionFilter={this.filterFunc}
                    customContent={allowBlock ? this.renderBlockMulti() : undefined}
                ></HeaderFilter>
                {this.renderProgressPopup()}
                <Table
                    columns={columns}
                    data={data}
                    onSort={this.onSort}
                    openId={id}
                    // unfolded={tag.unfolded}
                    sortKey={einteilungforms.sortCol}
                    colSort={einteilungforms.sortAsc}
                    onOpen={this.onOpen}
                ></Table>
            </React.Fragment>
        )
    }

    public componentDidMount() {

        locations.load()
        if (!userdata.loaded && !userdata.loading) {
            userdata.load()
        }
        if (!einteilungforms.loaded) {
            einteilungforms.load({})
        }
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    @bind
    private renderProgressPopup() {
        const { progress, progressOpen } = this.state

        if (progressOpen) {
            return <div className="absolute z-10 flex justify-center w-full h-full bg-gray-400 opacity-80">
                <div className="mt-40">
                    Blocken: {progress[0]} / {progress[1]}
                </div>
            </div>
        }

        return <></>
    }

    @bind
    private renderBlockMulti() {
        const { blockDialog, blockDialogOpen } = this.state
        return <>
            {blockDialogOpen && <div key="block-multi-dialog" className="absolute top-0 left-0 flex items-center justify-center w-full h-full block-multi-dialog">
                <div className="cursor-default filter-dialog">
                    <div className="flex justify-between p-3">
                        <div>Club blocken</div>
                        <div className="cursor-pointer" onClick={() => this.setState({
                            ...this.state,
                            blockDialogOpen: false
                        })}><Close /></div>
                    </div>
                    <hr />
                    <div className="flex flex-col justify-between py-3 h-2/5">
                        {
                            ["2", "3", "4"].map((locationID, idx) => {
                                return <div className="flex justify-between px-6 cursor-pointer" onClick={() => {
                                    let newList = blockDialog
                                    if (!blockDialog.includes(locationID)) {
                                        newList.push(locationID)
                                    } else {
                                        newList = blockDialog.filter((el) => {
                                            return el !== locationID
                                        })
                                    }
                                    this.setState({
                                        ...this.state,
                                        blockDialog: newList
                                    })
                                }} key={idx}>
                                    {blockDialog.includes(locationID) && <CheckBox />}
                                    {!blockDialog.includes(locationID) && <CheckBoxOutlineBlank />}
                                    <div>
                                        {LOCATIONS[locationID]}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div onClick={() => {

                        let num = 0
                        let step = 0

                        blockDialog.forEach((el) => {
                            num += einteilungforms.countForLocation(el, "1")
                        })

                        this.setState({
                            ...this.state,
                            progressOpen: true,
                            progress: [0, num]
                        })

                        einteilungforms.items.forEach(el => {
                            if (el.status === "1") {
                                if (blockDialog.includes(el.locationID)) {
                                    setTimeout(() => {
                                        myeinteilung.addToAvailable(el)
                                        step++
                                        this.setState({
                                            ...this.state,
                                            progressOpen: step !== num,
                                            progress: [step, num]
                                        })
                                    }, 100)
                                }
                            }

                        })

                    }} className="absolute bottom-0 flex items-center justify-center w-full h-8 bg-gray-400 cursor-pointer hover:bg-blue-500">
                        Blocken
                    </div>
                </div>
            </div>}
            <div className="mr-5 filter-button" onClick={() => this.setState({
                ...this.state,
                blockDialogOpen: true
            })}>
                Blocken
            </div>
        </>
    }

    private onOpen(item: IEinteilungForm) {
        const { clubfilter, mystatusfilter } = this.props
        const parsedFilter: any = {
            locationIDs: clubfilter,
            mystatus: mystatusfilter,
            status: ["1", "2"],
        }
        jumpTo({ type: "view", id: item.einteilungID, filter: parsedFilter })
    }

    private removeFilter(data: { type: string; val: string }) {
        const { type } = this.props
        switch (data.type) {
            case "locationIDs":
                const parsedFilter: any = {
                    locationIDs: "",
                    mystatus: this.props.mystatusfilter,
                    status: ["1", "2"],
                }
                jumpTo({ type, filter: parsedFilter })
                break
            case "mystatus":
                const parsedFilterWoStatus: any = {
                    locationIDs: this.props.clubfilter,
                    mystatus: "",
                    status: ["1", "2"],
                }
                jumpTo({ type, filter: parsedFilterWoStatus })
                break
        }
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(DateTime.local().plus({ month: 2 }).toMillis() / 1000)
        }
        if (data.to == null) {
            to = Math.round(DateTime.local().minus({ month: 2 }).toMillis() / 1000)
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        einteilungforms.load({ from, to })
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, mystatusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        mystatusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                mystatusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "MyStatus") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        mystatusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        mystatusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private filterFunc(
        filterstr: string | string[] | boolean | null | undefined,
        truncate: boolean,
        filtertype: string,
    ) {
        let einteilungFilter: any = {}
        const savedState: any = einteilungforms.filterObj || {}
        if (!truncate) {
            Object.keys(savedState).forEach((elem: any) => {
                if (savedState[elem] == null || savedState[elem] === "") {
                    einteilungFilter[elem] = null
                } else {
                    einteilungFilter[elem] = savedState[elem]
                }
            })
            einteilungFilter[filtertype] = filterstr
        } else {
            einteilungFilter = {}
        }
        // console.log(this.props.type, ": ", einteilungFilter)
        jumpTo({
            type: this.props.type ? this.props.type : "list",
            id: this.props.id,
            filter: einteilungFilter,
        })
    }

    private confirmFilter(filter) {
        // jumpTo({ type: "view", id: item.einteilungID });
        // einteilungforms.setFilterObj({ locationIDs: filter["Club"], status: ["1"], mystatus: filter["MyStatus"] })
    }
}

export default EnterEinteilungList
