import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import EnterEinteilungList from "./entereinteilung_list"
import EnterEinteilungDetail from "./entereinteilung_detail"
import { myeinteilung } from "../../stores"
import einteilungforms from "../../stores/einteilungforms"
import { updateApphead } from "../../utils/dommanip"
import Loading from "../../components/loading"
import "./entereinteilung.css"
import qs from "qs"

const basepath = "/entereinteilung"

interface IGetUrlEinteilung {
    id: string
    filter: any
    type: string
}

type TGetUrlExtended = TGetUrl | IGetUrlEinteilung

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrlExtended): string => {
    const filter = opt.filter !== undefined ? "?" + qs.stringify(opt.filter) : ""
    switch (opt.type) {
        case "view":
            return `${basepath}/view/${opt.id}${filter}`
        case "list":
            return `${basepath}/list${filter}`
        default:
            return basepath
    }
})

interface IEinteilungProps
    extends RouteComponentProps<{
        id: string
        filter: string
    }> { }

@observer
class Einteilung extends Component<IEinteilungProps, {}> {
    constructor(props: any, context?: any) {
        super(props, context)

        this.list = this.list.bind(this)
        this.listHandler = this.listHandler.bind(this)
        this.viewItem = this.viewItem.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "list" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "view", id: ":id" })} exact render={this.viewItem} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Eintragen", basepath)
        if (!userdata.loaded && !userdata.loading) {
            userdata.load()
        }
        if (!einteilungforms.loaded) {
            einteilungforms.load({})
        }
    }

    private listHandler({ match, location }: IEinteilungProps): JSX.Element {
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        return this.list(filter, "", "list")
    }

    private list(searchparams: any, einteilungid: string, type: string): JSX.Element {
        const id = getCookie("userid")

        if (einteilungforms.loaded) {
            einteilungforms.items.forEach((item) => {
                if (item.available) {
                    const availableItems = item.available.filter((el) => el.id === id)
                    if (availableItems.length > 0) {
                        myeinteilung.addToAvailableList(item)
                    }
                }
            })
        }

        if (!userdata.loaded && !userdata.loading) {
            userdata.load()
        }

        if (userdata.loading) {
            return <Loading />
        }

        if (searchparams) {
            const filterObject = searchparams
            einteilungforms.setFilterObj(filterObject)
        }

        return (
            <EnterEinteilungList
                id={einteilungid}
                type={type}
                clubfilter={searchparams.locationIDs ? searchparams.locationIDs : ""}
                mystatusfilter={searchparams.mystatus ? searchparams.mystatus : ""}
                userid={id}
            />
        )
    }

    private viewItem({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params
        const einteilung = einteilungforms.find(id)
        // const userid = getCookie("userid");
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))

        if (einteilung) {
            if (einteilungforms.loaded) {
                einteilungforms.items.forEach((item) => {
                    if (item.entered) {
                        // if (item.entered.indexOf(parseInt(userid)) > -1) {
                        //     myeinteilung.addToList(item)
                        // }
                    }
                })
                einteilungforms.setActive(id)
            }


            const einteilungDate = einteilung.date

            const sameDay = einteilungforms.items.filter((el) => {
                if (el.date === einteilungDate) {

                    return true
                }
                return false
            })

            return (
                <React.Fragment>
                    <div className="leftsection">{this.list(filter, id, "view")}</div>
                    <div className="rightsection">
                        <EnterEinteilungDetail sameDay={sameDay} filter={filter} einteilung={einteilung}></EnterEinteilungDetail>
                    </div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Einteilung
