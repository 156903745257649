import React, { Component } from "react"
import { observer } from "mobx-react"
import guistate from "../../stores/guistate"
import { Redirect } from "react-router"
import { getCookie, setadditionalcookie } from "../../utils/cookiemanager"
import Switch from "react-switch"
import ReactTooltip from "react-tooltip"
import { updatePw, updateData } from "../../api/profile"
import "./Profil.css"
import { updateApphead } from "../../utils/dommanip"
import DetailView from "../../components/detailview"
import Card from "../../components/card"
import { CheckBox, CheckBoxOutlineBlank, ExpandMore, ChevronRight } from "@material-ui/icons"
import { jumpTo } from "."
import bind from "bind-decorator"
import { toast } from "react-toastify"

interface IProfileState {
    pw: string
    confpw: string
    edit: boolean
    recNews: boolean
    email: string
    pwchanged: boolean
    pwok: boolean
    mailvalid: boolean
    mia: boolean
    zcham: boolean
    zkoz: boolean
    heart: boolean
    hoursbefore: number[]
    daysbefore: number[]
    weeksbefore: number[]
    attime: number
    attimeneeded: boolean
    detaildataopen: boolean
    meta: {
        address: string
        mobile: string
    }
}

@observer
class EditProfile extends Component<{}, IProfileState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    public pwConfirmRef: React.RefObject<HTMLInputElement>
    public mailRef: React.RefObject<HTMLInputElement>
    public newsRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)
        const mailsettings = JSON.parse(getCookie("mailsetting"))
        let metacookie = getCookie("meta")
        if (metacookie === "") {
            metacookie = '{"address": "", "mobile": ""}'
        }
        const meta = JSON.parse(metacookie)
        this.state = {
            pw: "",
            confpw: "",
            pwok: true,
            mailvalid: getCookie("mail").length > 0 ? true : false,
            pwchanged: false,
            edit: false,
            recNews: getCookie("recnews") ? getCookie("recnews") === "1" : false,
            email: getCookie("mail") ? getCookie("mail") : "",
            mia: getCookie("recnews").indexOf("2") > -1,
            zcham: getCookie("recnews").indexOf("3") > -1,
            zkoz: getCookie("recnews").indexOf("4") > -1,
            heart: getCookie("recnews").indexOf("1") > -1,
            hoursbefore: mailsettings[0],
            daysbefore: mailsettings[1],
            weeksbefore: mailsettings[2],
            attime: mailsettings[3][0],
            meta,
            attimeneeded: false,
            detaildataopen: false,
        }

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.pwConfirmRef = React.createRef()
        this.mailRef = React.createRef()
        this.newsRef = React.createRef()
        this.uploadProfileEdit = this.uploadProfileEdit.bind(this)
        this.uploadPasswordEdit = this.uploadPasswordEdit.bind(this)
        this.handleSwitchChange = this.handleSwitchChange.bind(this)
        this.changeApproved = this.changeApproved.bind(this)
        this.renderMyReminders = this.renderMyReminders.bind(this)
        this.onItemToggle = this.onItemToggle.bind(this)
        this.editProfile = this.editProfile.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return <React.Fragment>{this.renderEditContent()}</React.Fragment>
    }

    public componentDidMount() {
        updateApphead("Profil", "")
    }

    private renderEditContent(): JSX.Element {
        return (
            <DetailView
                content={() => this.editProfile()}
                onCancel={() => {
                    this.onCancel()
                }}
                allowSave={this.allFieldsValid()}
                onSave={() => {
                    this.onSave()
                }}
            ></DetailView>
        )
    }

    private onCancel() {
        jumpTo({ type: "view" })
    }

    private onSave() {
        if (this.state.pwchanged) {
            this.uploadPasswordEdit()
        } else {
            this.uploadProfileEdit()
        }
    }

    private editProfile() {
        return (
            <React.Fragment>
                <Card
                    label="Name"
                    content={
                        <input
                            type="password"
                            value={this.state.pw}
                            onChange={(evt) => this.passwordChanged(evt)}
                            placeholder="Neues Passwort"
                        />
                    }
                ></Card>
                <Card
                    label="Nutzername"
                    content={
                        <input
                            type="password"
                            className={this.getConfirmPwClass()}
                            value={this.state.confpw}
                            onChange={(evt) => this.confirmPwChanged(evt)}
                            placeholder="Neues Passwort bestätigen"
                        />
                    }
                ></Card>
                <span className={this.getConfirmPwClass()}>Passwörter stimmen nicht überein</span>
                <Card
                    label="E-Mail"
                    content={
                        <input
                            type="text"
                            className={this.getMailClass()}
                            value={this.state.email}
                            onChange={(evt) => this.handleMailChange(evt)}
                            placeholder="e-mail"
                        />
                    }
                />
                <span className={this.getMailClass()}>Ungültige e-Mail Adresse</span>
                {
                    <div className="my-clubs">
                        {/* <Switch onChange={this.handleSwitchChange} checked={this.state.recNews}></Switch> */}
                        <h3>Meine Clubs:</h3>
                        <div className="club-newsselect">
                            {this.state.mia && (
                                <CheckBox
                                    onClick={() => {
                                        this.setState({
                                            mia: false,
                                        })
                                    }}
                                ></CheckBox>
                            )}
                            {!this.state.mia && (
                                <CheckBoxOutlineBlank
                                    onClick={() => {
                                        this.setState({
                                            mia: true,
                                        })
                                    }}
                                ></CheckBoxOutlineBlank>
                            )}
                            <span>MIA Nightclub</span>
                        </div>
                        <div className="club-newsselect">
                            {this.state.zcham && (
                                <CheckBox
                                    onClick={() => {
                                        this.setState({
                                            zcham: false,
                                        })
                                    }}
                                ></CheckBox>
                            )}
                            {!this.state.zcham && (
                                <CheckBoxOutlineBlank
                                    onClick={() => {
                                        this.setState({
                                            zcham: true,
                                        })
                                    }}
                                ></CheckBoxOutlineBlank>
                            )}
                            <span>Cafe Zinnober Cham</span>
                        </div>
                        <div className="club-newsselect">
                            {this.state.zkoz && (
                                <CheckBox
                                    onClick={() => {
                                        this.setState({
                                            zkoz: false,
                                        })
                                    }}
                                ></CheckBox>
                            )}
                            {!this.state.zkoz && (
                                <CheckBoxOutlineBlank
                                    onClick={() => {
                                        this.setState({
                                            zkoz: true,
                                        })
                                    }}
                                ></CheckBoxOutlineBlank>
                            )}
                            <span>Cafe Zinnober Bad Kötzting</span>
                        </div>
                        <div className="club-newsselect">
                            {this.state.heart && (
                                <CheckBox
                                    onClick={() => {
                                        this.setState({
                                            heart: false,
                                        })
                                    }}
                                ></CheckBox>
                            )}
                            {!this.state.heart && (
                                <CheckBoxOutlineBlank
                                    onClick={() => {
                                        this.setState({
                                            heart: true,
                                        })
                                    }}
                                ></CheckBoxOutlineBlank>
                            )}
                            <span>HEART Regensburg</span>
                        </div>
                    </div>
                }
                {this.renderMyReminders()}
                {!this.state.mailvalid && (
                    <React.Fragment>
                        <ReactTooltip id="invalid-switch" type="error">
                            <span>Keine gültige e-mail Adresse</span>
                        </ReactTooltip>
                        <div data-tip data-for="invalid-switch">
                            <Switch
                                disabled={true}
                                onChange={() => {
                                    return
                                }}
                                checked={false}
                            ></Switch>
                        </div>
                    </React.Fragment>
                )}
                <br></br>
                {this.renderDetailDataInput()}
                {/*<div className="footer">
                    <button className="action-button" disabled={!this.allFieldsValid()} onClick={() => {
                    }}>Speichern</button>
                </div>*/}
            </React.Fragment>
        )
    }

    private renderMyReminders() {
        const { hoursbefore, daysbefore, weeksbefore, attime } = this.state
        const timehashalfhour = (attime + "").split(".")[1] != null
        let parsedTime = ""
        const possHours = [0.5, 1, 2, 4]
        const possDays = [1, 2, 3, 4, 5, 6]
        const possWeeks = [1, 2]
        if (timehashalfhour) {
            if ((attime + "").split(".")[0].length === 1) {
                parsedTime = "0" + (attime + "").split(".")[0] + ":30"
            } else {
                parsedTime = (attime + "").split(".")[0] + ":30"
            }
        } else {
            if ((attime + "").split(".")[0].length === 1) {
                parsedTime = "0" + (attime + "").split(".")[0] + ":00"
            } else {
                parsedTime = (attime + "").split(".")[0] + ":00"
            }
        }
        const timeneeded = this.state.daysbefore.length > 0 || this.state.weeksbefore.length > 0
        return (
            <div className="my-reminders">
                <h3>Meine Erinnerungen:</h3>
                <div className="selectablehours-container">
                    {possHours.map((h, idx) => {
                        const hourselected = hoursbefore.indexOf(h) > -1
                        return (
                            <div
                                key={idx}
                                onClick={() => this.onItemToggle(hourselected, "hours", h)}
                                className={`selectablehours ${hourselected ? "selected" : "unselected"}`}
                            >
                                {idx === 0 ? "30" : h}
                                {idx === 0 ? " min" : " Std"}
                            </div>
                        )
                    })}
                    <div>vorher</div>
                </div>
                <hr></hr>
                <div className="selectablehours-container">
                    {possDays.map((h, idx) => {
                        const dayselected = daysbefore.indexOf(h) > -1
                        return (
                            <div
                                key={idx}
                                onClick={() => this.onItemToggle(dayselected, "days", h)}
                                className={`selectablehours ${dayselected ? "selected" : "unselected"}`}
                            >
                                {h}
                            </div>
                        )
                    })}
                    <div>Tage vorher</div>
                </div>
                <div className="selectablehours-container">
                    {possWeeks.map((h, idx) => {
                        const weekselected = weeksbefore.indexOf(h) > -1
                        return (
                            <div
                                key={idx}
                                onClick={() => this.onItemToggle(weekselected, "weeks", h)}
                                className={`selectablehours ${weekselected ? "selected" : "unselected"}`}
                            >
                                {h}
                            </div>
                        )
                    })}
                    <div>Wochen vorher</div>
                </div>
                {timeneeded && (
                    <div>
                        <div>Nachricht um: (30min-Schritte)</div>
                        <input
                            type="time"
                            value={parsedTime}
                            onChange={(ev) => {
                                ev.persist()
                                const time = ev.target.value.split(":")
                                const hours = time[0] ? time[0] : "18"
                                const newtime = time[1] !== "30" ? parseInt(hours, 10) : parseInt(hours, 10) + 0.5
                                this.setState({
                                    attime: newtime,
                                })
                            }}
                            step="1800"
                        />
                    </div>
                )}
            </div>
        )
    }

    @bind
    private renderDetailDataInput() {
        const { detaildataopen } = this.state
        return (
            <div>
                {detaildataopen && (
                    <div className="toggle-line">
                        <ExpandMore
                            onClick={() => {
                                this.setState({
                                    detaildataopen: false,
                                })
                            }}
                        ></ExpandMore>
                        <span
                            onClick={() => {
                                this.setState({
                                    detaildataopen: false,
                                })
                            }}
                        >
                            Details
                        </span>
                        <div className="details-list">
                            <div className="detail-input-item">
                                <input
                                    type="text"
                                    placeholder="Musterstraße 1, 93053 Regensburg"
                                    value={this.state.meta.address}
                                    onChange={(ev) => {
                                        ev.persist()
                                        const address = ev.target.value
                                            .replace("ö", "oe")
                                            .replace("ü", "ue")
                                            .replace("ß", "ss")
                                            .replace("ä", "ae")
                                        this.setState({
                                            meta: {
                                                address,
                                                mobile: this.state.meta.mobile,
                                            },
                                        })
                                    }}
                                ></input>
                                <div className="label">Adresse</div>
                            </div>
                            <br></br>
                            <div className="detail-input-item">
                                <input
                                    type="text"
                                    value={this.state.meta.mobile}
                                    onChange={(ev) => {
                                        ev.persist()
                                        this.setState({
                                            meta: {
                                                mobile: ev.target.value,
                                                address: this.state.meta.address,
                                            },
                                        })
                                    }}
                                ></input>
                                <div className="label">Telefon</div>
                            </div>
                            <br></br>
                        </div>
                    </div>
                )}
                {!detaildataopen && (
                    <div
                        className="toggle-line"
                        onClick={() => {
                            this.setState({
                                detaildataopen: true,
                            })
                        }}
                    >
                        <ChevronRight></ChevronRight>
                        <span>Details</span>
                    </div>
                )}
            </div>
        )
    }

    private onItemToggle(remove: boolean, target: "hours" | "days" | "weeks", value: number) {
        switch (target) {
            case "hours":
                if (!remove) {
                    const newarray = this.state.hoursbefore.map((el) => el)
                    newarray.push(value)
                    this.setState({
                        hoursbefore: newarray.sort(),
                    })
                } else {
                    const smallerarray = this.state.hoursbefore.filter((el) => el !== value)
                    this.setState({
                        hoursbefore: smallerarray.sort(),
                    })
                }
                break
            case "days":
                if (!remove) {
                    const newarray = this.state.daysbefore.map((el) => el)
                    newarray.push(value)
                    this.setState({
                        daysbefore: newarray.sort(),
                    })
                } else {
                    const smallerarray = this.state.daysbefore.filter((el) => el !== value)
                    this.setState({
                        daysbefore: smallerarray.sort(),
                    })
                }
                break
            case "weeks":
                if (!remove) {
                    const newarray = this.state.weeksbefore.map((el) => el)
                    newarray.push(value)
                    this.setState({
                        weeksbefore: newarray.sort(),
                    })
                } else {
                    const smallerarray = this.state.weeksbefore.filter((el) => el !== value)
                    this.setState({
                        weeksbefore: smallerarray.sort(),
                    })
                }
                break
        }
    }

    private allFieldsValid(): boolean {
        if (this.getMailClass() === "ok" && this.getConfirmPwClass() === "ok") {
            return true
        }
        return false
    }

    private getMailClass() {
        if (!this.state.mailvalid) {
            return "not-ok"
        }
        return "ok"
    }

    private handleMailChange(evt) {
        const newMail = evt.target.value
        this.setState({ email: evt.target.value })
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([A-Z]{2}|de|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/
        if (this.state.email.length < 8) {
            this.setState({
                mailvalid: false,
                recNews: false,
            })
        } else if (re.test(newMail.toLowerCase())) {
            this.setState({
                mailvalid: true,
                recNews: true,
            })
        } else {
            this.setState({
                mailvalid: false,
                recNews: false,
            })
        }
    }

    private getConfirmPwClass(): string {
        if (!this.state.pwok) {
            return "not-ok"
        }
        return "ok"
    }

    private passwordChanged(evt) {
        const password = evt.target.value.replace(/\s/g, "")
        if (password.length === 0) {
            this.setState({
                pw: evt.target.value,
                pwchanged: false,
            })
        } else {
            this.setState({
                pw: evt.target.value,
                pwchanged: true,
            })
        }
    }

    private confirmPwChanged(evt) {
        const password = evt.target.value
        if (password !== this.state.pw) {
            this.setState({
                confpw: password,
                pwok: false,
            })
        } else {
            this.setState({
                confpw: password,
                pwok: true,
            })
        }
    }

    private handleSwitchChange(recNews: boolean) {
        if (!this.state.mailvalid) {
            // filler
        } else {
            this.setState({ recNews })
        }
    }

    private changeApproved() {
        const relevantClubs: number[] = []
        const { hoursbefore, daysbefore, weeksbefore, attime, meta } = this.state
        if (this.state.heart) {
            relevantClubs.push(1)
        }
        if (this.state.mia) {
            relevantClubs.push(2)
        }
        if (this.state.zcham) {
            relevantClubs.push(3)
        }
        if (this.state.zkoz) {
            relevantClubs.push(4)
        }

        const metaaddress = meta.address
        metaaddress
            .replace("ö", "oe")
            .replace("ü", "ue")
            .replace("ß", "ss")
            .replace("ä", "ae")

        this.setState({
            meta: {
                mobile: meta.mobile,
                address: metaaddress,
            },
        })
        setadditionalcookie({ name: "recnews", cookiecontent: JSON.stringify(relevantClubs) }, 7)
        setadditionalcookie({ name: "meta", cookiecontent: JSON.stringify(meta) }, 7)
        setadditionalcookie(
            {
                name: "mailsetting",
                cookiecontent: JSON.stringify([hoursbefore, daysbefore, weeksbefore, [attime]]),
            },
            7,
        )
        jumpTo({ type: "view" })
    }

    private uploadProfileEdit() {
        const relevantClubs: string[] = []
        const { hoursbefore, daysbefore, weeksbefore, attime, meta } = this.state
        if (this.state.heart) {
            relevantClubs.push("1")
        }
        if (this.state.mia) {
            relevantClubs.push("2")
        }
        if (this.state.zcham) {
            relevantClubs.push("3")
        }
        if (this.state.zkoz) {
            relevantClubs.push("4")
        }

        guistate.setEmail(this.state.email)
        setadditionalcookie({ name: "mail", cookiecontent: this.state.email }, 7)
        const mail = this.state.email
        const news = relevantClubs
        const newmailsetting = [hoursbefore, daysbefore, weeksbefore, attime ? [attime] : []]
        updateData({
            email: mail,
            news,
            remindersettings: newmailsetting,
            meta,
        }).then((res) => {
            if (res === "updated") {
                toast.success("Erfolgreich gespeichert", { autoClose: 2000 })
                this.changeApproved()
            } else {
                toast.error("Änderungen wurden nicht gespeichert", { autoClose: 2000 })
            }
        })
    }

    private uploadPasswordEdit() {
        const relevantClubs: string[] = []
        const { hoursbefore, daysbefore, weeksbefore, attime, meta } = this.state
        if (this.state.heart) {
            relevantClubs.push("1")
        }
        if (this.state.mia) {
            relevantClubs.push("2")
        }
        if (this.state.zcham) {
            relevantClubs.push("3")
        }
        if (this.state.zkoz) {
            relevantClubs.push("4")
        }
        const mail = this.state.email
        const news = relevantClubs
        const password = this.state.pw
        const newmailsetting = [hoursbefore, daysbefore, weeksbefore, [attime]]
        updatePw({
            email: mail,
            news,
            remindersettings: newmailsetting,
            meta,
            password,
        }).then((res) => {
            if (res === "updated") {
                toast.success("Erfolgreich gespeichert", { autoClose: 2000 })
                this.changeApproved()
            } else {
                toast.error("Änderungen wurden nicht gespeichert", { autoClose: 2000 })
            }
        })
    }
}

export default EditProfile
