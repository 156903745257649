import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import positions from "../../stores/positions"
import einteilungforms from "../../stores/einteilungforms"
import { myeinteilung } from "../../stores"
import { updateApphead } from "../../utils/dommanip"
import Loading from "../../components/loading"
import MyEinteilungList from "./myeinteilung_list"
import qs from "qs"
import "./myeinteilung.css"

const basepath = "/meineeinteilung"

interface IGetUrlEinteilung {
    id: string
    filter: any
    type: string
}

type TGetUrlExtended = TGetUrl | IGetUrlEinteilung

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrlExtended): string => {
    const filter = opt.filter !== undefined ? "?" + qs.stringify(opt.filter) : ""
    switch (opt.type) {
        case "view":
            return `${basepath}/view/${opt.id}${filter}`
        case "list":
            return `${basepath}/list${filter}`
        default:
            return basepath
    }
})

interface IEinteilungProps
    extends RouteComponentProps<{
        id: string
    }> { }

@observer
class MyEinteilung extends Component<IEinteilungProps, {}> {
    constructor(props: any, context?: any) {
        super(props, context)

        this.list = this.list.bind(this)
        this.listHandler = this.listHandler.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "list" })} exact render={this.listHandler} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Meine Einteilung", basepath)
        if (!userdata.loaded) {
            userdata.load()
        }
        positions.load()
        if (!einteilungforms.loaded) {
            einteilungforms.load({})
        }
    }

    private listHandler({ match, location }: IEinteilungProps): JSX.Element {
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        return this.list(filter, "", "list")
    }

    private list(searchparams: any, einteilungid: string, type: string): JSX.Element {
        const id = getCookie("userid")
        if (searchparams) {
            const filterObject = searchparams
            filterObject.status = "2"
            einteilungforms.setFilterObj(filterObject)
        }
        if (einteilungforms.loaded) {
            einteilungforms.items.forEach((item) => {
                if (item.entered) {
                    const entereditems = item.entered.filter((el) => el.id === id)
                    if (entereditems.length > 0) {
                        myeinteilung.addToConfirmedList(item)
                    }
                }
                if (item.available) {
                    const availableitems = item.available.filter((el) => el.id === id)
                    if (availableitems.length > 0) {
                        myeinteilung.addToAvailableList(item)
                    }
                }
            })
            return (
                <MyEinteilungList
                    // clubfilter={searchparams.locationIDs ? searchparams.locationIDs : ""}
                    userid={id}
                />
            )
        } else {
            return <Loading></Loading>
        }
    }
}

export default MyEinteilung
