import { Instance, types, applySnapshot } from "mobx-state-tree"
import { IUserDataResponse, getUserData, getUserLohn } from "../api/getpersonaldata"
import ListLoader from "./mixins/listloader"
import einteilungforms from "./einteilungforms"
import { ITableObject } from "../components/table"
import { DateTime } from "luxon"
import { sortFn } from "../utils/sort"
import { get as oGet } from "object-path"
import { evaluate } from "../utils/predicates"

export type IUserItem = Instance<typeof UserItem>
export type IUserData = Instance<typeof UserData>
export type IEnteredObj = Instance<typeof EnteredObj>

export const ROLES = {
    "1": "ADMIN",
    "2": "Betriebsleiter",
    "3": "CvD",
    "4": "Mitarbeiter",
}

const ILohnObj = types.model({
    month: types.string,
    lohn: types.number,
})

const EnteredObj = types.model({
    einteilungID: types.string,
    position: types.string,
    time: types.maybe(types.string),
})

export const UserItem = types
    .model({
        userID: types.string,
        username: types.string,
        realName: types.string,
        role: types.string,
        mail: types.string,
        personalID: types.string,
        lastonline: types.string,
        loehne: types.array(ILohnObj),
        loadedloehne: types.optional(types.boolean, false),
        // entered: types.maybeNull(types.optional(types.array(types.string), [])),
        // confirmed: types.maybeNull(types.optional(types.array(EnteredObj), [])),
        meta: types.string,
        stbID: types.string,
        preferredclubs: types.string,
        lastDay: types.string,
    })
    .volatile((self) => ({}))
    .actions((self) => {
        const actions = {
            pushto: (data: { month: any; lohn }) => {
                try {
                    self.loehne.push(data)
                } catch (e) {
                    // fill
                }
            },
            replaceLoehne: (data) => {
                self.loehne.replace(data)
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            preferredLocations: () => {
                return self.preferredclubs.replace("[", "").replace("]", "").split(",")
            },
            summarizeLoehne: (list) => {
                const monthlist: { [key: string]: number } = {
                    "01": 0,
                    "02": 0,
                    "03": 0,
                    "04": 0,
                    "05": 0,
                    "06": 0,
                    "07": 0,
                    "08": 0,
                    "09": 0,
                    "10": 0,
                    "11": 0,
                    "12": 0,
                }
                list.forEach((el) => {
                    const month = el.date.substr(5, 2)
                    const year = el.date.substr(0, 4)
                    const currentYear = DateTime.local()
                    if (year === currentYear.year.toString()) {
                        monthlist["" + month] += parseInt(el.salary, 10)
                    }
                })
                Object.keys(monthlist).forEach((el) => {
                    if (self.loehne.length < 12) {
                        self.pushto({ month: el, lohn: monthlist[el] })
                    }
                })
            },
        }
        return views
    })
    .actions((self) => {
        const actions = {
            setLoehneLoaded: () => {
                self.loadedloehne = true
            },
            updateMail: (mail: string) => {
                self.mail = mail
            },
            updatePreferredLocations: (locations: string) => {
                self.preferredclubs = locations
            },
            updateName: (name: string) => {
                self.realName = name
            },
            updateRole: (role: string) => {
                self.role = role
            },
            updateStbID: (id: string) => {
                self.stbID = id
            },
            loadUserLoehne: () => {
                if (!self.loadedloehne) {
                    try {
                        getUserLohn(self.personalID).then((res) => {
                            self.summarizeLoehne(res)
                            actions.setLoehneLoaded()
                        })
                    } catch (e) {
                        // fill
                    }
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get lastonlineint() {
                return parseInt(self.lastonline, 10)
            },
            get lastDayInt() {
                // return 1593963858
                return parseInt(self.lastDay, 10)
            },
            get warnLastDaySince(): boolean {
                const today = Date.now() / 1000

                // const prev = 1609455619
                const prev = this.lastDayInt
                // const prev = 1593963858

                if (prev === 0) {
                    return false
                }
                const diff = today - prev

                const diffDays = diff / (60 * 60 * 24)

                if (diffDays >= 60) {
                    return true
                }
                return false
            },
            get readableLastDay() {
                if (!this.warnLastDaySince) {
                    return ""
                }
                return DateTime.fromSeconds(this.lastDayInt).toFormat("dd.MM.yyyy")
            },
            get lastonlinesortval() {
                if (self.lastonline === "0") {
                    return "-"
                }
                return parseInt(self.lastonline, 10).toString()
            },
            confirmed: (): IEnteredObj[] => {
                const einteilungen: IEnteredObj[] = []
                einteilungforms.items.forEach((el) => {
                    el.entered.forEach((elem) => {
                        if (elem.id === self.userID) {
                            einteilungen.push({
                                einteilungID: el.einteilungID,
                                position: elem.position,
                                time: elem.time,
                            })
                        }
                    })
                })
                return einteilungen
            },
            available: (): IEnteredObj[] => {
                const einteilungen: IEnteredObj[] = []
                einteilungforms.items.forEach((el) => {
                    el.available.forEach((elem) => {
                        if (elem.id === self.userID) {
                            einteilungen.push({
                                einteilungID: el.einteilungID,
                                position: elem.position,
                                time: elem.time,
                            })
                        }
                    })
                })
                return einteilungen
            },
            get readableMail() {
                return self.mail.length === 0 ? "-" : self.mail
            },
            get parsedMeta(): { address: string; mobile: string } {
                return JSON.parse(self.meta)
            },
            get readableonline() {
                if (self.lastonline === "0") {
                    return "-"
                }
                return DateTime.fromSeconds(views.lastonlineint).toFormat("dd.MM.yyyy HH:mm")
            },
            get _searchable() {
                return [self.mail.toLowerCase(), self.username.toLowerCase(), self.realName.toLowerCase()].join("|")
            },
        }
        return views
    })

export const getSortEl = (item: IUserItem) => {
    // if (item.locationName != null) {
    //     return item.locationName || 0
    // }
    return 0
}

const sortGetVAl = (obj: IUserItem, options: string): null | number | string => {
    return oGet(obj, options)
}

// const sortGetVAl = (obj: IUserItem, options: string): null | number | string => {
//     return oGet(obj, options)
// }

const loadItems = (): Promise<any> => getUserData()

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const UserData = types.compose(
    types
        .model({
            items: types.array(UserItem),
            loaded: types.optional(types.boolean, false),
            loading: types.optional(types.boolean, false),
        })
        .volatile((self) => ({
            searchstring: "",
            filterObj: {},
            sortAsc: true,
            sortCol: "lastonlineint",
        }))
        .actions((self) => {
            const actions = {
                setSearchString: (crit: string) => {
                    return (self.searchstring = crit)
                },
                setSortCol: (col: string) => {
                    return (self.sortCol = col)
                },
                setSortDir: (asc: boolean) => {
                    return (self.sortAsc = asc)
                },
                setFilterObj: (fObj: IClientFilter) => {
                    return (self.filterObj = fObj)
                },
                addItems: (item: IUserItem) => {
                    if (!actions.isContained(item.userID)) {
                        self.items.push(item)
                    }
                },
                clearList: () => {
                    self.items.clear()
                },
                isContained: (id: string) => {
                    let contains = 0
                    self.items.toJSON().forEach((el) => {
                        if (el.userID === id) {
                            contains++
                        }
                    })
                    return contains !== 0
                },
                setLoaded: () => {
                    self.loaded = true
                    self.loading = false
                },
                setLoading: () => {
                    self.loaded = false
                    self.loading = true
                },
                load: () => {
                    // if (guistate.client_id == null || loaded) {
                    //     return
                    // }
                    if (!self.loaded) {
                        actions.setLoading()
                        try {
                            actions.clearList()
                            getUserData()
                                .then((res: IUserDataResponse[]) => {
                                    applySnapshot(self.items, res)
                                })
                                .finally(() => {
                                    actions.setLoaded()
                                })
                            actions.setLoaded()
                        } catch (err) {
                            console.error(err)
                        }
                    }
                },
            }
            return actions
        })
        .views((self) => {
            const views = {
                count: () => {
                    return self.items.length
                },
                find: (id: string) => {
                    return self.items.find((el) => el.userID === id)
                },
                findPersonal: (id: string) => {
                    return self.items.find((el) => el.personalID === id)
                },
                first: () => {
                    return self.items[0]
                },
                filteroptions: () => {
                    return []
                },
                filtered: () => {
                    return self.items
                        .filter((el) => {
                            return evaluate(
                                { op: "contains", path: "_searchable", arg: self.searchstring.toLowerCase() },
                                el,
                            )
                        })
                        .sort(sortFn(sortGetVAl, self.sortAsc, self.sortCol))
                },
                get detailTable() {
                    const lastonline = (obj) => {
                        return obj.readableonline
                    }
                    const data: ITableObject = {
                        columns: [
                            {
                                label: "Name",
                                small: true,
                                valkey: "realName",
                                sortable: true,
                            },
                            {
                                label: "E-Mail",
                                small: true,
                                valkey: "readableMail",
                                sortable: true,
                            },
                            {
                                label: "Nutzername",
                                small: true,
                                valkey: "username",
                                sortable: false,
                            },
                            {
                                label: "Zuletzt online",
                                small: true,
                                valkey: "lastonlinesortval",
                                viewFn: lastonline,
                                sortable: true,
                            },
                        ],
                        data: views.filtered(),
                    }
                    return data
                },
            }
            return views
        }),
    ListLoader<any>(UserItem, loadItems),
)
const listInstance = UserData.create({})
export default listInstance
