import React, { Component } from "react"
import { observer } from "mobx-react"
import { IEinteilungForm } from "../../stores/einteilungforms"
import positions from "../../stores/positions"
import userdata from "../../stores/userdata"
import locations from "../../stores/locations"
import einteilungstate from "../../stores/einteilungstate"
import DetailView from "../../components/detailview"
import { jumpTo } from "."
import Loading from "../../components/loading"
import einteilungforms from "../../stores/einteilungforms"
import { getSnapshot } from "mobx-state-tree"
import { updateEinteilungform } from "../../api/neweinteilungform"
import { AutoSuggest } from "../../components/autosuggest"
import { Cancel } from "@material-ui/icons"
import "rc-tooltip/assets/bootstrap.css"
import bind from "bind-decorator"

interface ITestAppState {
    dragging: boolean
    starttimes: Array<{
        pos: string
        time: string
    }>
}

interface ITestAppProps {
    einteilung: IEinteilungForm
    filter: any
}

const enteredUsers = (einteilung): Array<{ name: string; userid: string; position: string }> | undefined => {
    if (einteilung.entered.length !== 0) {
        const availablelist = einteilung.entered.map((el) => {
            // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
            const usr = userdata.find(el.id)
            let name = el.name
            if (usr) {
                name = usr.realName
            }
            return { name, userid: el.id, position: el.position }
        })
        return availablelist
    }
}

const availableUsers = (einteilung): Array<{ name: string; userid: string }> | undefined => {
    if (einteilung.available.length !== 0) {
        const availablelist = einteilung.available.map((el) => {
            // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
            const usr = userdata.find(el.id)
            let name = el.name
            if (usr) {
                name = usr.realName
            }
            return { name, userid: el.id }
        })
        return availablelist
    }
}

@observer
class EinteilungManageMobile extends Component<ITestAppProps, ITestAppState> {
    constructor(props: any) {
        super(props)

        this.state = {
            dragging: false,
            starttimes: positions.items.map((el) => {
                return { pos: el.positionID, time: el.starttime }
            }),
        }
        this.renderTimeInput = this.renderTimeInput.bind(this)
        this.changeStarttime = this.changeStarttime.bind(this)
        this.onSelectName = this.onSelectName.bind(this)
    }

    public render() {
        const { einteilung, filter } = this.props
        const availablelist = availableUsers(einteilung)
        const enteredlist = enteredUsers(einteilung)
        const availablenamelist = availablelist ? availablelist : []
        const enterednamelist = enteredlist ? enteredlist : []
        const einteilungstateitem = einteilungstate.items.filter(
            (elem) => elem.einteilungID === einteilung.einteilungID,
        )[0]

        availablenamelist.forEach((el, idx) => {
            if (el != null) {
                if (einteilungstateitem) {
                    // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
                    const usr = userdata.find(el.userid)
                    let name = el.name
                    if (usr) {
                        name = usr.realName
                    }
                    einteilungstateitem.addToAvailable(el.userid, name)
                }
            }
        })

        enterednamelist.forEach((el, idx) => {
            if (el != null) {
                if (einteilungstateitem) {
                    // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
                    const usr = userdata.find(el.userid)
                    let name = el.name
                    if (usr) {
                        name = usr.realName
                    }
                    einteilungstateitem.addToPosition(el.userid, name, el.position)
                }
            }
        })

        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        const locationPosList = positions.items.map((el) => {
            if (el.locationID === einteilung.locationID) {
                if (el) {
                    return el
                }
            }
            return undefined
        }).filter((el) => el != null)

        const filteredList = locationPosList.filter((el) => el !== undefined)

        filteredList.sort((a, b) => {
            if (a && b) {
                if (einteilung.locationID === "1" || einteilung.locationID === "6") {
                    return parseInt(a.sortNo, 10) > parseInt(b.sortNo, 10)
                        ? 1
                        : parseInt(b.sortNo, 10) > parseInt(a.sortNo, 10)
                            ? -1
                            : 0
                }
                return parseInt(a.positionID, 10) > parseInt(b.positionID, 10)
                    ? 1
                    : parseInt(b.positionID, 10) > parseInt(a.positionID, 10)
                        ? -1
                        : 0
            }
            return 0
        })

        return (
            <div className="container-drag">
                <div className="rightsection">
                    <DetailView
                        header={() => this.renderHeader()}
                        content={() => this.renderContent()}
                        onClose={() => jumpTo({ type: "list", filter })}
                        onSave={() => this.saveEinteilung(() => jumpTo({ type: "list", filter }))}
                    ></DetailView>
                </div>
            </div>
        )
    }

    public componentDidMount() {
        positions.load()
        if (!locations.loaded) {
            locations.load()
        }
        if (!userdata.loaded) {
            userdata.load()
        }
    }

    private renderTimeInput(el): string {
        const { einteilung } = this.props
        const customtime = einteilung.customtimes.filter((elem) => elem.pos === el)
        if (customtime.length > 0) {
            if (customtime[0].time !== "") {
                return customtime[0].time
            }
        }
        const storetime = positions.items.filter((item) => item.positionID === el)[0]
        if (storetime) {
            return storetime.starttime
        }
        return "21:00"
    }

    private changeStarttime(ev, pos: string) {
        ev.persist()
        const position = positions.find(pos)
        const { einteilung } = this.props
        if (position) {
            position.changeStarttime(ev.target.value)
            const tobeupdated = einteilung.entered.filter((el) => el.position === pos)[0]
            if (tobeupdated) {
                einteilung.updateEnteredTime(pos, ev.target.value)
            }
        }
    }

    private renderHeader() {
        const { einteilung } = this.props
        const location = locations.find(einteilung.locationID)
        const usernames = userdata.items.map((el) => {
            return { name: el.realName, id: el.userID }
        })
        // const usernames = [{name: "Marco Jakob", id: "1"}, {name: "Fabian Stauber", id: "2"}]
        return (
            <React.Fragment>
                {location && <div className="locationname">{location.locationName}</div>}
                <div className="einteilung">Einteilung {einteilung.readableDate}</div>
                <div className="einteilung-title">{einteilung.title}</div>
                {this.renderAdminUserAdd(usernames)}
            </React.Fragment>
        )
    }

    private renderContent() {
        return (
            <React.Fragment>
                <div>{this.renderPosList()}</div>
                <div className="mt-2">{this.renderAvailable()}</div>
            </React.Fragment>
        )
    }

    private renderPosList() {
        const { einteilung } = this.props
        // const positionlist = positions.items.map((el) => {
        //   if (el.locationID == einteilung.locationID) {
        //     return el
        //   }
        // })
        const locationPosList = positions.items.map((el) => {
            if (el.locationID === einteilung.locationID) {
                if (el) {
                    return el
                }
            }
            return undefined
        }).filter((el) => el != null)

        const filteredList = locationPosList.filter((el) => el !== undefined)

        filteredList.sort((a, b) => {
            if (a && b) {
                if (einteilung.locationID === "1" || einteilung.locationID === "2" || einteilung.locationID === "6") {
                    return parseInt(a.sortNo, 10) > parseInt(b.sortNo, 10)
                        ? 1
                        : parseInt(b.sortNo, 10) > parseInt(a.sortNo, 10)
                            ? -1
                            : 0
                }
                return parseInt(a.positionID, 10) > parseInt(b.positionID, 10)
                    ? 1
                    : parseInt(b.positionID, 10) > parseInt(a.positionID, 10)
                        ? -1
                        : 0
            }
            return 0
        })
        if (filteredList) {
            return (
                <div className="einteilung-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Position</th>
                                <th>Mitarbeiter</th>
                                <th>Start</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((item, idx) => {
                                if (item) {
                                    const eingeteilt = einteilung.findForPos(item.positionID)
                                    const isObl =
                                        einteilung.oblpos.filter((elem) => elem === item.positionID).length > 0
                                    const show =
                                        item.showineinteilung === "0" ||
                                        item.showineinteilung === "1" ||
                                        eingeteilt != null ||
                                        isObl
                                    if (show) {
                                        if (eingeteilt) {
                                            const usr = userdata.find(eingeteilt.id)
                                            let name = eingeteilt.name
                                            if (usr) {
                                                name = usr.realName
                                            }
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.positionName}</td>
                                                    <td>
                                                        {this.renderEmployeeSelector(eingeteilt, name, item.positionID)}
                                                    </td>
                                                    <td className="time-td">
                                                        <input
                                                            type="time"
                                                            value={this.renderTimeInput(item.positionID)}
                                                            onChange={(val) => {
                                                                this.changeStarttime(val, item.positionID)
                                                            }}
                                                        ></input>
                                                    </td>
                                                </tr>
                                            )
                                        } else {
                                            return (
                                                <tr key={idx} className={`${isObl ? "needed" : ""}`}>
                                                    <td>{item.positionName}</td>
                                                    <td>{this.renderEmployeeSelector(null, null, item.positionID)}</td>
                                                    <td className="time-td">
                                                        <input
                                                            type="time"
                                                            value={this.renderTimeInput(item.positionID)}
                                                            onChange={(val) => {
                                                                this.changeStarttime(val, item.positionID)
                                                            }}
                                                        ></input>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                }
                                return <></>
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    @bind
    private renderEmployeeSelector(eingeteilt: { id; name; position } | null, name, posid) {
        const { einteilung } = this.props
        if (eingeteilt) {
            return (
                <div className="removable-name">
                    <span>{name}</span>
                    <div
                        className="remove-icon"
                        onClick={() => {
                            this.removeFromEingeteilt(eingeteilt.id, eingeteilt.position)
                        }}
                    >
                        <Cancel></Cancel>
                    </div>
                </div>
            )
        } else if (einteilung.available.length > 0) {
            return (
                <div className="choose-available">
                    <select
                        onChange={(ev) => {
                            ev.persist()
                            const id = ev.target.value
                            const usr = userdata.find(id)
                            const employeeName = usr ? usr.realName : ""

                            this.addToEingeteilt(id, employeeName, posid)
                        }}
                    >
                        {<option>offen</option>}
                        {einteilung.available.map((el, idx) => {
                            return (
                                <option key={idx} value={el.id}>
                                    {el.name
                                        .replace("u00f6", "ö")
                                        .replace("u00fc", "ü")
                                        .replace("u00e4", "ä")
                                        .replace("u00e1", "á")}
                                </option>
                            )
                        })}
                    </select>
                </div>
            )
        }
        return <div></div>
    }

    private renderAvailable() {
        const { einteilung } = this.props
        return (
            <div className="einteilung-available">
                <table>
                    <tbody>
                        {einteilung.available.map((el, idx) => {
                            const usr = userdata.find(el.id)
                            let name = el.name
                            if (usr) {
                                name = usr.realName
                            }
                            return (
                                <tr key={idx}>
                                    <td>{name}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    private renderTime(el) {
        const { einteilung } = this.props
        const einteilungPos = einteilung.customtimes.filter((elem) => elem.pos === el.positionID)
        if (einteilungPos.length > 0) {
            if (einteilungPos[0].time !== "") {
                return einteilungPos[0].time
            }
        }
        return el.starttime
    }

    private renderAdminUserAdd(list) {
        return <AutoSuggest onSelectName={this.onSelectName} list={list}></AutoSuggest>
    }

    private onSelectName(name, id) {
        const { einteilung } = this.props
        einteilung.addToAvailable(id, name)
    }

    @bind
    private removeFromEingeteilt(id, posid) {
        const { einteilung } = this.props
        const position = positions.find(posid)
        if (position) {
            const alreadyEntered = einteilung.entered.filter((el) => el.position === position.positionID)
            if (alreadyEntered.length > 0) {
                const oldID = alreadyEntered[0].id
                const oldName = alreadyEntered[0].name
                einteilung.addToAvailable(oldID, oldName)
                einteilung.removeFromEntered(oldID, oldName)
            }
        }
    }

    @bind
    private addToEingeteilt(id, name, posid) {
        const { einteilung } = this.props

        einteilung.addToEntered(id, name, posid, "")
        einteilung.removeFromAvailable(id)
    }

    private saveEinteilung(nextstep) {
        const { einteilung } = this.props
        const entered = JSON.stringify(getSnapshot(einteilung.entered))
        const available = JSON.stringify(getSnapshot(einteilung.available))
        const oblpos = JSON.stringify(getSnapshot(einteilung.oblpos))
        const customtime = JSON.stringify(getSnapshot(einteilung.customtimes))
        updateEinteilungform(
            einteilung.einteilungID,
            [entered, available, oblpos, customtime],
            "updateeinteilung",
            nextstep,
        )
        // this.saveAndConfirm()
    }
}

export default EinteilungManageMobile
