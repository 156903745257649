import { observer } from "mobx-react"
import * as React from "react"
import { ITableColumn } from "."
import { KeyboardArrowUp, KeyboardArrowDown, UnfoldMoreTwoTone } from "@material-ui/icons/"

interface ITableHeaderProps {
    col: ITableColumn
    colSort?: boolean
    sortKey?: string
    onSort?: (key: string, sortDir: boolean) => void
    onSelectAll?: (state: boolean) => void
    // TODO set sort col and direction
    // onSetSortCol?: (col: boolean) => void
}
@observer
export default class TableHeader extends React.Component<ITableHeaderProps, {}> {
    public render() {
        const { onSelectAll, col } = this.props
        const { valkey, label, sortable, className } = col
        if (valkey === "_sel" && onSelectAll != null) {
            return (
                <th key={valkey} className={className || ""}>
                    <input
                        type="checkbox"
                        onChange={(ev) => {
                            onSelectAll(ev.target.checked)
                        }}
                    ></input>
                </th>
            )
        }
        if (valkey === "_detail") {
            return (
                <th key={valkey} className={className || ""}>
                    <span className="detail-header"></span>
                </th>
            )
        }
        return (
            <th key={valkey} className={`${className || ""} ${label.length === 0 ? "hide-sort" : ""}`}>
                <div className="headercontents">{this.sortDirection(label, valkey, sortable)}</div>
            </th>
        )
    }

    public componentDidMount() {
        return
    }

    private changeSortDir(key: any) {
        if (this.props.onSort !== undefined) {
            this.props.onSort(key, !this.props.colSort)
        }
    }

    private sortDirection(label: string, valkey: string, sortable: boolean) {
        if (sortable) {
            if (valkey === this.props.sortKey) {
                if (this.props.colSort) {
                    return (
                        <span className="sort-down">
                            <p
                                className="sortableHeader"
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            >
                                {label}
                            </p>
                            <KeyboardArrowDown
                                className="sortdirection"
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            />
                        </span>
                    )
                } else if (!this.props.colSort) {
                    return (
                        <span className="sort-up">
                            <p
                                className="sortableHeader"
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            >
                                {label}
                            </p>
                            <KeyboardArrowUp
                                className="sortdirection"
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            />
                        </span>
                    )
                }
            } else {
                return (
                    <span className={`sort-possible  ${label.length === 0 ? "hide-empty-sort" : ""}`}>
                        <p
                            className="sortableHeader"
                            onClick={() => {
                                this.changeSortDir(valkey)
                            }}
                        >
                            {label}
                        </p>
                        <UnfoldMoreTwoTone
                            className={`sortdirection`}
                            onClick={() => {
                                this.changeSortDir(valkey)
                            }}
                        />
                    </span>
                )
            }
        } else {
            return <p>{label}</p>
        }
    }
}
