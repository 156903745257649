import { Instance, types, applySnapshot } from "mobx-state-tree"

const NewsItem = types
    .model({
        newsID: types.string,
        ts: types.number,
        content: types.string,
    })
    .actions((self: any) => {
        const actions = {

        }
        return actions
    })

const VersionItem = types
    .model({
        id: types.string,
        version: types.string,
        content: types.string,
        ts: types.string,
    })
    .actions((self: any) => {
        const actions = {
        }
        return actions
    })
    .views((self) => {
        const views = {
            readabledate: () => {
                return new Date(parseInt(self.ts, 10) * 1000).toLocaleString()
            },
        }
        return views
    })

const NewsItems = types
    .model({
        items: types.array(NewsItem),
        versionitems: types.array(VersionItem),
        uptodate: types.optional(types.boolean, true),
    })
    .actions((self) => {
        const actions = {
            addItems: (list) => {
                applySnapshot(self.items, list)
            },
            addVersionItems: (list) => {
                applySnapshot(self.versionitems, list)
            },
        }
        return actions
    })

export type INewsItem = Instance<typeof NewsItem>
export type INewsItems = Instance<typeof NewsItems>
const guiInst = NewsItems.create({})
export default guiInst
