import { Ballot, BlockTwoTone, EditTwoTone, Redo, WarningOutlined } from "@material-ui/icons"
import { observer } from "mobx-react"
import Tooltip from "rc-tooltip"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { jumpTo } from "."
import { addEinteilungToAbrechnung, IAddToAbrechnungPersonaldata } from "../../api/abrechung"
import { updateEinteilungform } from "../../api/neweinteilungform"
import DetailView from "../../components/detailview"
import { IEinteilungForm } from "../../stores/einteilungforms"
import locations from "../../stores/locations"
import positions from "../../stores/positions"
import userdata from "../../stores/userdata"
import { getCookie } from "../../utils/cookiemanager"

interface IEinteilungProps {
    einteilung: IEinteilungForm,
    filter: any
}

interface IEinteilungState {
    status: string
}

@observer
class EinteilungView extends Component<IEinteilungProps, IEinteilungState> {
    constructor(props: any) {
        super(props)

        this.state = {
            status: this.props.einteilung.status,
        }

        this.renderAvailableNames = this.renderAvailableNames.bind(this)
        this.renderConfirmedNames = this.renderConfirmedNames.bind(this)
        this.renderStatus = this.renderStatus.bind(this)
    }

    public render() {
        const { einteilung, filter } = this.props
        const location = locations.find(einteilung.locationID)
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }

        const useBlocked = () => {
            if (einteilung.locationID === "5" || einteilung.locationID === "6") {
                return false
            }
            return true
        }

        return (
            <React.Fragment>
                <DetailView
                    header={() => this.renderHeader(einteilung, location)}
                    content={() => {
                        return (
                            <React.Fragment>
                                {this.renderManageButton()}
                                {this.renderApplyButton()}
                                <h3>Eingeteilt:</h3>
                                <div className="namelist">{this.renderConfirmedNames()}</div>
                                {!useBlocked() && <h3>Verfügbar:</h3>}
                                {useBlocked() && <h3>Geblockt:</h3>}
                                <div className="namelist">{this.renderAvailableNames()}</div>
                            </React.Fragment>
                        )
                    }}
                    onClose={() => jumpTo({ type: "list", filter })}
                    onEdit={() => jumpTo({ type: "edit", id: einteilung.einteilungID, filter })}
                />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        positions.load()
    }

    private renderHeader(einteilung: IEinteilungForm, location: any) {
        return (
            <React.Fragment>
                {location && <div className="locationname">{location.locationName}</div>}
                <div className="einteilung">Einteilung {einteilung.readableDate}</div>
                <div className="einteilung-title">{einteilung.title}</div>
                <div>{this.renderStatus()}</div>
            </React.Fragment>
        )
    }

    private renderStatus(): JSX.Element {
        const { einteilung } = this.props

        switch (einteilung.status) {
            case "0":
                return (
                    <div className="flex items-center">
                        <EditTwoTone></EditTwoTone>
                        <span>Entwurf</span>
                    </div>
                )
            case "1":
                return (
                    <div className="flex items-center">
                        <Ballot></Ballot>
                        <span>Offen</span>
                    </div>
                )
            case "2":
                return (
                    <div className="flex items-center">
                        <BlockTwoTone></BlockTwoTone>
                        <span>Geschlossen</span>
                    </div>
                )
            default:
                return <span></span>
        }
    }

    private renderManageButton(): JSX.Element {
        const { einteilung } = this.props
        return (
            <>
                <div
                    className="button hideinmobile"
                    onClick={() => {
                        const nextstep = () => jumpTo({ type: "manage", id: einteilung.einteilungID })
                        einteilung.updateStatus("2")
                        updateEinteilungform(einteilung.einteilungID, "2", "updatestatus", nextstep)
                    }}
                >
                    Einteilen
                </div>
                <div
                    className="button hideindesktop"
                    onClick={() => {
                        const nextstep = () => jumpTo({ type: "managemobile", id: einteilung.einteilungID })
                        einteilung.updateStatus("2")
                        updateEinteilungform(einteilung.einteilungID, "2", "updatestatus", nextstep)
                    }}
                >
                    Einteilen
                </div>
            </>
        )
    }

    private renderApplyButton(): JSX.Element {
        const { einteilung } = this.props
        const personaldata: IAddToAbrechnungPersonaldata[] = einteilung.entered.map((el) => {
            let positiontime = ""
            const customtime = einteilung.customtimes.filter((elem) => elem.pos === el.position)
            if (customtime.length > 0) {
                if (customtime[0].time !== "") {
                    positiontime = customtime[0].time
                }
            } else {
                const pos = positions.find(el.position)
                if (pos) {
                    positiontime = pos.starttime
                }
            }
            const user = userdata.find(el.id)
            let personalid = ""
            if (user) {
                personalid = user.personalID
            }
            return {
                userid: personalid,
                position: el.position,
                starttime: positiontime,
            }
        })
        return (
            <div
                className="button hideinmobile"
                onClick={() => {
                    addEinteilungToAbrechnung({
                        date: einteilung.date,
                        locationID: einteilung.locationID,
                        personaldata,
                    })
                }}
            >
                In Abrechnung<Redo></Redo>
            </div>
        )
    }

    private renderAvailableNames(): JSX.Element[] | JSX.Element {
        const { einteilung } = this.props
        // console.log("userdata", userdata)
        if (einteilung.available.length !== 0) {
            const namelist = einteilung.available.map((el, idx) => {
                const parsedName = el.name
                    .replace("u00f6", "ö")
                    .replace("u00fc", "ü")
                    .replace("u00e4", "ä")
                const usr = userdata.find(el.id)
                let name = parsedName
                if (usr) {
                    name = usr.realName
                }
                return name
            })
            return namelist.map((el, idx) => {
                return <p key={idx}>{el}</p>
            })
        }
        if (einteilung.locationID === "5" || einteilung.locationID === "6") {
            return <div>Keine verfügbaren Mitarbeiter</div>
        }
        return <div>Keine geblockten Mitarbeiter</div>
    }

    private renderConfirmedNames(): JSX.Element[] | JSX.Element {
        const { einteilung } = this.props
        if (einteilung.entered.length !== 0) {
            const namelist = einteilung.entered.map((el, idx) => {

                const user = userdata.find(el.id)
                const lastDay = () => {
                    if (user != null) {
                        if (user.warnLastDaySince) {
                            return user.readableLastDay
                        }
                    }
                    return null
                }

                const parsedName = el.name
                    .replace("u00f6", "ö")
                    .replace("u00fc", "ü")
                    .replace("u00e4", "ä")
                    .replace("u00e1", "á")
                return [parsedName, lastDay()]
            })
            return namelist.map((el, idx) => {
                return <div key={idx} className="flex items-center justify-between my-1 ">
                    <span>{el[0]}</span>
                    {el[1] && <Tooltip placement="left" overlay={`Der letzte Arbeitstag war am ${el[1]}. Der Mitarbeiter muss evtl. neu angemeldet werden.`}>
                        <div className="flex items-center font-bold cursor-help">
                            <WarningOutlined className="h-5 leading-none" />
                        </div>
                    </Tooltip>}
                </div>
            })
        }
        return <div>Bisher wurde noch niemand eingeteilt</div>
    }

    private saveFormUpdate(nextstep) {
        const { einteilung } = this.props
        const operation = "updatestatus"
        const newstatus = this.state.status
        updateEinteilungform(einteilung.einteilungID, newstatus, operation, nextstep)
    }
}

export default EinteilungView
