import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import einteilungforms, { IEinteilungForm } from "../../stores/einteilungforms"
import locations, { LOCATIONS } from "../../stores/locations"
import { myeinteilung } from "../../stores/"
import Loading from "../../components/loading"
import "./entereinteilung.css"
import { jumpTo } from "."
import DetailView from "../../components/detailview"
import { toast } from "react-toastify"

interface IListState {
    filter: "0" | "1" | "2"
    locationID: string
    alreadyclosed: string
}

interface IEnterEinteilungProps {
    einteilung: IEinteilungForm
    filter: any
    sameDay: IEinteilungForm[]
}

@observer
class EnterEinteilungDetail extends Component<IEnterEinteilungProps, IListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            filter: "1",
            locationID: "",
            alreadyclosed: "",
        }
        this.onResponse = this.onResponse.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { einteilung, filter } = this.props
        const location = locations.find(einteilung.locationID)
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading></Loading>}
                {einteilungforms.loaded && (
                    <DetailView
                        header={() => this.renderHeader(einteilung, location)}
                        // content={() => this.renderEinteilungInteraction(einteilung, filter)}
                        content={() => this.renderContent(einteilung, filter)}
                        onClose={() => jumpTo({ type: "list", filter })}
                    ></DetailView>
                )}
            </React.Fragment>
        )
    }

    private renderHeader(einteilung, location) {
        return (
            <React.Fragment>
                {location && <div className="locationname">{location.locationName}</div>}
                <div className="einteilung">Einteilung {einteilung.readableDate}</div>
                <div className="einteilung-title">{einteilung.title}</div>
            </React.Fragment>
        )
    }

    private renderContent(einteilung, filter) {
        return <>
            {this.renderEinteilungInteraction(einteilung, filter)}
            {this.renderMultiEinteilungInteraction(einteilung, filter)}
        </>
    }

    private renderOptions(einteilung) {
        if (this.state.alreadyclosed === "warn") {
            return (
                <div
                    className="rounded-md button warn-closed"
                    onClick={() => {
                        // myeinteilung.removeItems(einteilung, jumpTo({ type: "list" }))
                    }}
                >
                    Einteilung bereits geschlossen. Anwendung wird neu geladen...
                </div>
            )
        }
        if (einteilung.status === "2") {
            return (
                <div
                    className="px-5 rounded-md cursor-not-allowed button remove w-max"
                    onClick={() => {
                        // myeinteilung.removeItems(einteilung, jumpTo({ type: "list" }))
                    }}
                >
                    Einteilung geschlossen <span className="text-sm font-light">{LOCATIONS[einteilung.locationID]}</span>
                </div>
            )
        }
        if (myeinteilung.findInAvailable(einteilung.einteilungID)) {
            return (
                <div
                    className="px-5 rounded-md button remove w-max"
                    onClick={() => {
                        myeinteilung.removeFromAvailable(einteilung, (data) => this.onResponse(data, "remove", false, einteilung.locationID))
                    }}
                >
                    Entfernen <span className="text-sm font-light">{LOCATIONS[einteilung.locationID]}</span>
                </div>
            )
        }
        if (myeinteilung.findInConfirmed(einteilung.einteilungID)) {
            return (
                <div
                    className="px-5 rounded-md button remove w-max"
                    onClick={() => {
                        // myeinteilung.removeItems(einteilung, jumpTo({ type: "list" }))
                    }}
                >
                    Eingeteilt <span className="text-sm font-light">{LOCATIONS[einteilung.locationID]}</span>
                </div>
            )
        }
        return <div
            className="block px-5 bg-red-700 rounded-md w-max button"
            onClick={() => {
                myeinteilung.addToAvailable(einteilung, (data) => this.onResponse(data, "enter", false, einteilung.locationID))
            }}
        >
            Blocken: <span className="text-sm font-light">{LOCATIONS[einteilung.locationID]}</span>
        </div>
    }

    private renderMultiEinteilungInteraction(einteilung, filter) {
        const { sameDay } = this.props
        return <div>
            {sameDay && sameDay.length > 1 && <div>
                {sameDay.filter((el) => el.locationID !== einteilung.locationID).map((el, idx) => {
                    return <div key={idx}>
                        {this.renderOptions(el)}
                    </div>
                })}
            </div>}
        </div>
    }

    private renderEinteilungInteraction(einteilung: IEinteilungForm, filter): JSX.Element {



        if (this.state.alreadyclosed === "warn") {
            return (
                <div
                    className="rounded-md button warn-closed"
                    onClick={() => {
                        // myeinteilung.removeItems(einteilung, jumpTo({ type: "list" }))
                    }}
                >
                    Einteilung bereits geschlossen. Anwendung wird neu geladen...
                </div>
            )
        }
        if (einteilung.status === "2") {
            return (
                <div
                    className="rounded-md cursor-not-allowed button remove"
                    onClick={() => {
                        // myeinteilung.removeItems(einteilung, jumpTo({ type: "list" }))
                    }}
                >
                    Einteilung geschlossen
                </div>
            )
        }
        if (myeinteilung.findInAvailable(einteilung.einteilungID)) {
            return (
                <div
                    className="rounded-md button remove"
                    onClick={() => {
                        myeinteilung.removeFromAvailable(einteilung, (data) => this.onResponse(data, "remove", false, einteilung.locationID))
                    }}
                >
                    Entfernen
                </div>
            )
        }
        if (myeinteilung.findInConfirmed(einteilung.einteilungID)) {
            return (
                <div
                    className="rounded-md button remove"
                    onClick={() => {
                        // myeinteilung.removeItems(einteilung, jumpTo({ type: "list" }))
                    }}
                >
                    Eingeteilt
                </div>
            )
        }

        if (einteilung.locationID !== "6" && einteilung.locationID !== "5") {

            return (
                <div
                    className="block bg-red-700 rounded-md button"
                    onClick={() => {
                        myeinteilung.addToAvailable(einteilung, (data) => this.onResponse(data, "enter", false, einteilung.locationID))
                    }}
                >
                    Blocken
                </div>
            )
        }
        return (
            <div
                className="rounded-md button enter"
                onClick={() => {
                    myeinteilung.addToAvailable(einteilung, (data) => this.onResponse(data, "enter", true, einteilung.locationID))
                }}
            >
                Eintragen
            </div>
        )
    }

    private onResponse(res, type, jump: boolean, location: string) {
        const { filter } = this.props
        if (res === "closed") {
            this.setState({
                alreadyclosed: "warn",
            })
            setTimeout(() => document.location.reload(), 2000)
        } else {
            if (type === "enter") {
                if (location === "5" || location === "6") {
                    toast.success("Eingetragen", { autoClose: 2000 })
                } else {
                    toast.warn("Geblockt", { autoClose: 2000 })
                }
            } else {
                toast.success("Ausgetragen", { autoClose: 2000 })
            }
            if (jump) {
                jumpTo({ type: "list", filter })
            }
        }
    }
}

export default EnterEinteilungDetail
