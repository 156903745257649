import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import "./Profil.css"
import { updateApphead } from "../../utils/dommanip"
import DetailView from "../../components/detailview"
import Card from "../../components/card"
import { jumpTo } from "."

interface IProfileState {
    pw: string
    confpw: string
    edit: boolean
    recNews: boolean
    email: string
    pwchanged: boolean
    pwok: boolean
    mailvalid: boolean
    mia: boolean
    zcham: boolean
    zkoz: boolean
    heart: boolean
    meta: { address: string; mobile: string }
}

@observer
class ViewProfile extends Component<{}, IProfileState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    public pwConfirmRef: React.RefObject<HTMLInputElement>
    public mailRef: React.RefObject<HTMLInputElement>
    public newsRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)
        let metacookie = getCookie("meta")
        if (metacookie === "") {
            metacookie = '{"address": "", "mobile": ""}'
        }
        const meta = JSON.parse(metacookie)
        this.state = {
            pw: "",
            confpw: "",
            pwok: true,
            mailvalid: true,
            pwchanged: false,
            edit: false,
            recNews: getCookie("recnews") ? getCookie("recnews") === "1" : false,
            email: getCookie("mail") ? getCookie("mail") : "",
            mia: getCookie("recnews").indexOf("2") > -1,
            zcham: getCookie("recnews").indexOf("3") > -1,
            zkoz: getCookie("recnews").indexOf("4") > -1,
            heart: getCookie("recnews").indexOf("1") > -1,
            meta,
        }

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.pwConfirmRef = React.createRef()
        this.mailRef = React.createRef()
        this.newsRef = React.createRef()
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return <React.Fragment>{this.renderShowContent()}</React.Fragment>
    }

    public componentDidMount() {
        updateApphead("Profil", "")
    }

    private renderShowContent(): JSX.Element {
        return (
            <DetailView
                content={() => this.showProfile()}
                onEdit={() => {
                    this.onEdit()
                }}
            ></DetailView>
        )
    }

    private onEdit() {
        jumpTo({ type: "edit" })
    }

    private showProfile() {
        return (
            <div className="profile-view">
                <Card label="Name" content={getCookie("realname")}></Card>
                <Card label="Nutzername" content={getCookie("username")}></Card>
                <Card label="E-Mail" content={getCookie("mail")}></Card>
                <div className="my-clubs">
                    <h3>Meine Clubs:</h3>
                    <ul>
                        {JSON.parse(getCookie("recnews")).map((el, idx) => {
                            switch (el) {
                                case 1:
                                case "1":
                                    return <li key={idx}>HEART Regensburg</li>
                                case 2:
                                case "2":
                                    return <li key={idx}>MIA Nightclub</li>
                                case 3:
                                case "3":
                                    return <li key={idx}>Cafe Zinnober Cham</li>
                                case 4:
                                case "4":
                                    return <li key={idx}>Cafe Zinnober Bad Kötzting</li>
                                default:
                                    return <></>
                            }
                        })}
                    </ul>
                </div>
                <div className="my-reminders">
                    <h3>Erinnerungen:</h3>
                    <ul className="reminder-type-viewlist">
                        {JSON.parse(getCookie("mailsetting")).map((el: number[][], idx) => {
                            switch (idx) {
                                case 0:
                                    if (el[0]) {
                                        return (
                                            <ul className="reminder-type-subviewlist" key={idx}>
                                                {el.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {item}
                                                            {el.length === index + 1 ? "" : ","}
                                                        </li>
                                                    )
                                                })}
                                                <li className="wider-li">Stunde(e) vorher</li>
                                            </ul>
                                        )
                                    }
                                    return <></>
                                case 1:
                                    if (el[0]) {
                                        return (
                                            <ul className="reminder-type-subviewlist" key={idx}>
                                                {el.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {item}
                                                            {el.length === index + 1 ? "" : ","}
                                                        </li>
                                                    )
                                                })}
                                                <li className="wider-li">Tag(e) vorher</li>
                                            </ul>
                                        )
                                    }
                                    return <></>
                                case 2:
                                    if (el[0]) {
                                        return (
                                            <ul className="reminder-type-subviewlist" key={idx}>
                                                {el.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {item}
                                                            {el.length === index + 1 ? "" : ","}
                                                        </li>
                                                    )
                                                })}
                                                <li className="wider-li">Woche(n) vorher</li>
                                            </ul>
                                        )
                                    }
                                    return <></>
                                case 3:
                                    if (el[0]) {
                                        return (
                                            <ul className="reminder-type-subviewlist" key={idx}>
                                                <li>um</li>
                                                {el.map((item, index) => {
                                                    const time = ("" + item).split(".")
                                                    const hours = time[0]
                                                    const newtime = time[1] !== "5" ? ":00" : ":30"
                                                    return (
                                                        <li className="medium-width" key={index}>
                                                            {hours + newtime}
                                                        </li>
                                                    )
                                                })}
                                                <li>Uhr</li>
                                            </ul>
                                        )
                                    }
                                    return <></>
                                default:
                                    return <></>
                            }
                        })}
                    </ul>
                </div>
                <div className="my-meta">
                    <h3>Infos:</h3>
                    <div className="details-list">
                        <div className="detail-view-item">
                            <p>{this.state.meta.address}</p>
                            <div className="label">Adresse</div>
                        </div>
                        <br></br>
                        <div className="detail-view-item">
                            <p>{this.state.meta.mobile}</p>
                            <div className="label">Telefon</div>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewProfile
