// import { getCookie } from "./cookiemanager"

export const periodicNewsRequest = () => {
    // const userid = getCookie("userid")
    // tslint:disable-next-line: no-console
    console.info("live service disabled")
    // const nowTs0 = Math.round((new Date()).getTime() / 1000)
    // getnewsts(userid, nowTs0).then((res) => {
    //     newsitems.addItems(res)
    // })
    // setInterval(() => {
    //     const nowTs = Math.round((new Date()).getTime() / 1000);
    //     getnewsts(userid, nowTs).then((res) => {
    //         newsitems.addItems(res)
    //     })
    // }, 5000);
}
