import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

export interface IPositionApi {
    positionID: string
    positionName: string
    positionShort: string
    positionType: string
    sortNo: string
    locationID: string
}

export const getnews = (): Promise<IPositionApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getnews`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const sendGeneralMail = (
    action: "neweinteilungopen" | "neweinteilungclosed" | "custom",
    clubids: string[],
    einteilungIDs: string[],
    content?: string,
): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/mailservice.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                token: getCookie("token"),
                actiontype: `generalmail`,
                action,
                clubids,
                content: content ? content : "",
                einteilungIDs,
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                if (res) {
                    resolve(res.responseText)
                }
            })
    })
}
