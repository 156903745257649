import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import { IEinteilungForm } from "../../stores/einteilungforms"
import einteilungforms from "../../stores/einteilungforms"
import locations from "../../stores/locations"
import userdata from "../../stores/userdata"
import guistate from "../../stores/guistate"
import Loading from "../../components/loading"
import { jumpTo } from "."
import { Send } from "@material-ui/icons"
import Table from "../../components/table"
import { sendGeneralMail } from "../../api/mailservice"
import { DateTime } from "luxon"
import { toast } from "react-toastify"

interface IListState {
    statusfilter: string[]
    clubfilter: string[]
}

export const renderStatus = (obj) => {
    switch (obj.status) {
        case "0":
            return "Entwurf"
        case "1":
            return "Offen"
        case "2":
            return "Geschlossen"
        default:
            return ""
    }
}

interface IMailListProps {
    only: string
    // onSuccess: () => void
    // onFail: () => void
}

@observer
class MailList extends Component<IMailListProps, IListState> {
    constructor(props: IMailListProps) {
        super(props)

        this.state = {
            statusfilter: ["0", "1", "2"],
            clubfilter: ["1", "2", "3", "4", "5", "6"],
        }

        this.filter = this.filter.bind(this)
        this.confirmFilter = this.confirmFilter.bind(this)
        this.onSelectAll = this.onSelectAll.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { only } = this.props
        const { columns, data } = einteilungforms.mailView(only)
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading />}
                {/* <HeaderFilter
                    filteroptions={einteilungforms.filteroptions(true)}
                    currentstate={
                        {
                            "Status": this.state.statusfilter,
                            "Club": this.state.clubfilter
                        }}
                    onConfirm={this.confirmFilter}
                    onDateConfirm={this.onDateConfirm}
                    withdatefilter={true}
                    withfilterbutton={true}
                    onOptionFilter={this.filter}>
                </HeaderFilter> */}
                <Table
                    columns={columns}
                    data={data}
                    onSort={this.onSort}
                    sortKey={einteilungforms.sortCol}
                    colSort={einteilungforms.sortAsc}
                    onSelect={this.onSelect}
                    onSelectAll={this.onSelectAll}
                ></Table>
                {this.renderSendbutton()}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        if (!locations.loaded) {
            locations.load()
        }
        if (!userdata.loaded) {
            userdata.load()
        }
        if (einteilungforms.loaded) {
            einteilungforms.setFilterObj({ status: ["0", "1", "2"] })
        } else {
            einteilungforms.load({})
        }
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    private onOpen(item: IEinteilungForm) {
        jumpTo({ type: "view", id: item.einteilungID })
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(
                DateTime.local()
                    .plus({ month: 2 })
                    .toMillis() / 1000,
            )
        }
        if (data.to == null) {
            to = Math.round(
                DateTime.local()
                    .minus({ month: 2 })
                    .toMillis() / 1000,
            )
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        einteilungforms.load({ from, to })
        // console.log(from, to)
    }

    private onSelect(item: IEinteilungForm) {
        if (item.selected) {
            item.unselect()
        } else {
            item.select()
        }
    }

    private onSelectAll(data) {
        const { only } = this.props
        const relevantEinteilungen = einteilungforms.filter({ status: only === "onlyopen" ? "1" : "2" })
        relevantEinteilungen.forEach((item) => {
            if (data) {
                item.select()
            } else {
                item.unselect()
            }
        })
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, statusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        statusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                statusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "Status") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private confirmFilter(filter) {
        einteilungforms.setFilterObj({ locationIDs: filter.Club, status: filter.Status })
    }

    private renderSendbutton() {
        const { only } = this.props
        if (einteilungforms.countSelected() > 0) {
            return (
                <div
                    className="action-icon"
                    onClick={() => {
                        const allSelected = einteilungforms.items.filter((el) => el.selected === true)
                        const selectedEinteilungIDs = allSelected.map((el) => el.einteilungID)
                        const selectedClubIDs = allSelected.map((el) => el.locationID)
                        const actiontype = only === "onlyopen" ? "neweinteilungopen" : "neweinteilungclosed"
                        sendGeneralMail(actiontype, selectedClubIDs, selectedEinteilungIDs).then((res) => {
                            if (res === "success") {
                                toast.success("Nachrichten gesendet", { autoClose: 2500 })
                            }
                        })
                    }}
                >
                    <Send></Send>
                    <span>Senden</span>
                </div>
            )
        } else {
            return (
                <div className="action-icon disabled">
                    <Send></Send>
                    <span>Noch keine Auswahl</span>
                </div>
            )
        }
    }
}

export default MailList
