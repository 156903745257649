import menus from "./menus"
import { history, routingStore } from "./router"
// import locations from "./locations"
import MyEinteilungen from "./myeinteilung"

// const locations = Locations.create({})
const myeinteilung = MyEinteilungen.create({})
// const einteilungforms = EinteilungForms.create({})

export {
    history,
    menus,
    routingStore,
    myeinteilung,
    // locations,
    // einteilungforms,
}

// if (process.env.NODE_ENV === "development" && module.hot) {
//     module.hot.accept(["./auth"], () => {
//         // new model definitions
//         const snapshotAuth = getSnapshot(auth)
//         // auth = Auth.create(snapshotAuth)
//         renderApp()
//     })
// }
// if (module.hot) {
//     renderApp()
// }
