import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

export interface IPositionApi {
    positionID: string
    positionName: string
    positionShort: string
    positionType: string
    sortNo: string
    locationID: string
}

export interface IChangelogRes {
    id: number
    version: string
    content: string
    ts: number
}

export const getLatestVersion = (): Promise<IChangelogRes[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getversionnum`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getnews = (): Promise<IPositionApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getnews`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getnewsts = (userid: string, ts: number): Promise<IPositionApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/news.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getnewsts`,
                userid,
                ts,
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => {
                if (res) {
                    resolve(res.responseText)
                }
            })
    })
}
