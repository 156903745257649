/* <reference types="webpack-env" /> */
import "./index.scss"
import * as serviceWorker from "./serviceWorker"
import "react-app-polyfill/stable"
import * as React from "react"
import * as ReactDOM from "react-dom"
import AuthCheck from "./auth_check"
// import registerServiceWorker from "./serviceWorker"

// registerServiceWorker()

ReactDOM.render(<AuthCheck />, document.getElementById("root"))

// renderApp()

// if (module.hot) {
//     module.hot.accept(["./App"], () => {
//         // new components
//         renderApp()
//     })
// }

serviceWorker.unregister()


// // if (process.env.NODE_ENV === "development" && module.hot) {
// //     module.hot.accept(["./components/App"], () => {
// //         // new components
// //         renderApp()
// //     })
// // }

// export { renderApp }
