import "react-datepicker/dist/react-datepicker.css"

import bind from "bind-decorator"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React, { Component } from "react"
import DatePicker from "react-datepicker"
import { Redirect } from "react-router"
import Switch from "react-switch"

import { jumpTo } from "."
import { addEinteilungForm } from "../../api/neweinteilungform"
import DetailView from "../../components/detailview"
import LocationPicker from "../../components/locationpicker"
import locations from "../../stores/locations"
import positions from "../../stores/positions"
import { getCookie } from "../../utils/cookiemanager"

interface INewEinteilungState {
    selectedDate: Date
    additionalInfo: string
    additionalPos: any[]
    weekday: string
    title: string
    locationID: string
    status: boolean
    selectedTS: number
    hourdiff: string
    mode: number
}

interface IEinteilungListProps {
    filter: any
}

export const DAYS = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]

export const possModes = ["Club", "Bar"]

@observer
class EinteilungNew extends Component<IEinteilungListProps, INewEinteilungState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)

        this.state = {
            selectedDate: new Date(),
            selectedTS: DateTime.local().toMillis(),
            weekday: "",
            title: "",
            locationID: "1",
            additionalInfo: "",
            additionalPos: [],
            status: false,
            hourdiff: "0",
            mode: 0,
        }
        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.handleChange = this.handleChange.bind(this)
        this.handlePosChange = this.handlePosChange.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleInfoChange = this.handleInfoChange.bind(this)
        this.selectLocation = this.selectLocation.bind(this)
        this.submitNewDayForm = this.submitNewDayForm.bind(this)
        this.handleSwitchChange = this.handleSwitchChange.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }

        if (!locations.filled) {
            return "Loading"
        }

        const { filter } = this.props
        return (
            <React.Fragment>
                <DetailView
                    header={() => this.renderHeader()}
                    content={() => this.renderNewEinteilungForm()}
                    onClose={() => jumpTo({ type: "list", filter })}
                    onSave={() => this.submitNewDayForm()}
                />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        // if (!locations.loaded) {
        //     locations.load()
        // }
        const d = new Date()
        const n = d.getDay()
        this.setState({
            weekday: DAYS[n],
        })
        if (!positions.loaded) {
            positions.load()
        }
        if (!locations.filled) {
            locations.load()
        }
    }

    private renderHeader() {
        return <div>Neue Einteilung</div>
    }

    @bind
    private renderNewEinteilungForm() {
        const { weekday, title, selectedDate, locationID } = this.state
        const optionslist: number[] = []
        for (let i = -10; i < 11; i++) {
            optionslist.push(i)
        }
        const location = locations.find(locationID)

        if (location) {
            return (
                <>
                    {weekday}
                    <br />
                    <DatePicker dateFormat="dd.MM.yyyy" selected={selectedDate} onChange={this.handleChange} />
                    <br />
                    <LocationPicker onSelect={this.selectLocation}></LocationPicker>
                    <br />
                    <input
                        type="text"
                        placeholder="Eventname (optional)"
                        value={title}
                        onChange={this.handleTitleChange}
                    ></input>
                    <br />
                    <span>Geänderte Anfangszeiten:</span>
                    <br />
                    <select
                        defaultValue={"0"}
                        onChange={(ev) => {
                            ev.persist()
                            this.setState({
                                hourdiff: ev.target.value,
                            })
                        }}
                    >
                        {optionslist.map((el, idx) => {
                            if (el > 0) {
                                return (
                                    <option key={idx} value={el}>
                                        {el} Std. später
                                    </option>
                                )
                            } else if (el < 0) {
                                return (
                                    <option key={idx} value={el}>
                                        {Math.abs(el)} Std. früher
                                    </option>
                                )
                            }
                            return (
                                <option key={idx} value={el}>
                                    Standard
                                </option>
                            )
                        })}
                    </select>
                    <br />
                    <span>Betrieb:</span>
                    <br />
                    <select onChange={(ev) => {
                        ev.persist()
                        this.setState({
                            mode: parseInt(ev.target.value, 10),
                        })
                    }}>
                        {location.possibleModes.map((el) => {
                            return <option value={el}>{possModes[el]}</option>
                        })}
                    </select>
                    <p>Zusatzinfos</p>
                    <textarea
                        wrap="soft"
                        cols={30}
                        value={this.state.additionalInfo}
                        onChange={this.handleInfoChange}
                    ></textarea>
                    {/* <p>Anzahl Zusatzpositionen</p>
                        <input type="number" value={this.state.additionalPos} onChange={this.handlePosChange} /> */}
                    <div className="flex mt-4">
                        <Switch onChange={this.handleSwitchChange} checked={this.state.status}></Switch>
                        {this.state.status && <span className="flex items-center ml-2">Freigeben</span>}
                        {!this.state.status && <span className="flex items-center ml-2">Entwurf</span>}
                    </div>
                </>
            )
        }
        return <div />

    }

    private handleSwitchChange(val: boolean) {
        this.setState({
            status: val,
        })
    }

    private handlePosChange(event) {
        this.setState({ additionalPos: event.target.value })
    }

    private handleInfoChange(event) {
        this.setState({ additionalInfo: event.target.value })
    }

    private handleTitleChange(event) {
        this.setState({ title: event.target.value })
    }

    private submitNewDayForm() {
        const { selectedTS, status, mode } = this.state
        const parsedDate = DateTime.fromMillis(selectedTS).toFormat("yyyy-LL-dd")
        const timestamp = selectedTS / 1000
        const { title, locationID, additionalInfo, hourdiff } = this.state
        const locData = locations.find(locationID)
        if (locData) {
            const positionlist = locData.positions
            const postimes: Array<{ pos: string; time: string }> = []
            if (hourdiff !== "0") {
                positionlist.forEach((pos) => {
                    const positionDetail = positions.find(pos)
                    if (positionDetail) {
                        let newTime = ""
                        const difference = parseInt(hourdiff, 10)
                        if (difference > 0) {
                            newTime = DateTime.fromFormat(positionDetail.starttime, "HH:mm")
                                .plus({
                                    hours: parseInt(hourdiff, 10),
                                })
                                .toFormat("HH:mm")
                        } else if (difference < 0) {
                            newTime = DateTime.fromFormat(positionDetail.starttime, "HH:mm")
                                .minus({
                                    hours: Math.abs(parseInt(hourdiff, 10)),
                                })
                                .toFormat("HH:mm")
                        }
                        if (positionDetail.starttime !== "") {
                            postimes.push({ pos: positionDetail.positionID, time: newTime })
                        }
                    }
                })
            }

            const data = {
                date: parsedDate,
                ts: timestamp,
                title,
                locationID,
                additionalPos: "[]",
                additionalInfo,
                status: status ? "1" : "0",
                mode,
                postimes: JSON.stringify(postimes),
            }
            addEinteilungForm(data)
            jumpTo({ type: "list" })
        }
    }

    private selectLocation(event: any) {
        event.persist()
        this.setState({
            locationID: event.target.value,
        })
    }

    private handleChange(value: any) {
        const date = DateTime.fromJSDate(value)
        const d = new Date(value)
        const n = d.getDay()
        this.setState({
            selectedDate: d,
            selectedTS: date.toMillis(),
            weekday: DAYS[n],
        })
    }
}

export default EinteilungNew
