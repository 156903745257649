import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

export interface IUserData {
    userID: string
    username: string
    realName: string
    mail: string
    stbID: string
    role: string
    locations: string
}

export interface IUserDataResponse {
    username: string
    userID: string
    role: string
    realName: string
    personalID: string
    lastonline: string
    mail: string
    entered: string[]
    confirmed: Array<{ einteilungID: string; position: string }>
    meta: string
    preferredclubs: string
    stbID: string
    lastDay: string
}

export const getUserData = (): Promise<IUserDataResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getUserData`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getUserLohn = (id): Promise<IUserDataResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getUserLohn`,
                id,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const updateUserData = (data: IUserData): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=setstbid"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `updatePersonalData`,
                ...data,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}

export const addUser = (data: { shortcode; fullname; userid; mail; locations }): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `saveNewMitarbeiter`,
                shortcode: data.shortcode,
                fullname: data.fullname,
                userid: data.userid,
                mail: data.mail,
                locations: data.locations,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}

export const deleteUser = (personalID): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `deleteUser`,
                personalID,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}
