import "./App.scss"
import "react-toastify/dist/ReactToastify.css"

import dotenv from "dotenv"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React from "react"
import { Redirect, Router, Switch } from "react-router"
import { Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import Einteilung from "./apps/einteilung"
import Einteilungsview from "./apps/einteilungsview"
import Employees from "./apps/employees"
import EnterEinteilung from "./apps/entereinteilung"
import MailService from "./apps/mailservice"
import MyEinteilung from "./apps/myeinteilung"
import Profil from "./apps/profil"
import NewsButton from "./components/newsbutton"
import ProfileButton from "./components/profilebutton"
import SideMenu from "./components/sidebar"
import guistate from "./stores/guistate"
import menus from "./stores/menus"
import { history, historyHandler } from "./stores/router"
import { getCookie } from "./utils/cookiemanager"
import { periodicNewsRequest } from "./utils/liveservice"

dotenv.config()
const VERSION: string = process.env.REACT_APP_VERSION_NUM || "1.0.0"

export const renderClassName = (): string => {
    let classname = ""
    if (guistate.isAuth) {
        classname += " show"
    } else {
        classname += " hide"
    }
    return classname
}

interface IAppProps {
    setauth: (auth: boolean) => void
}

@observer
class App extends React.Component<IAppProps, {}> {
    constructor(props: any) {
        super(props)
        this.state = {}
        this.render = this.render.bind(this)
    }
    public render() {
        const basepath = history.location.pathname
        const { setauth } = this.props
        historyHandler(basepath)
        return (
            <>
                <div className="apphead">
                    <span className="version-no">{`v${VERSION}`}</span>
                    <span className="apphead-title">{guistate.apphead}</span>
                </div>
                <ToastContainer></ToastContainer>
                <Router history={history}>
                    <div className={"content"}>
                        <SideMenu />
                        <div className="main-content">
                            <ProfileButton setauth={setauth} />
                            <NewsButton setauth={setauth} />
                            <Switch>
                                <Route path="/einteilung" component={Einteilungsview} />
                                <Route path="/meineeinteilung" component={MyEinteilung} />
                                <Route path="/profil" component={Profil} />
                                <Route path="/entereinteilung" component={EnterEinteilung} />
                                <Route path="/admineinteilung" component={Einteilung} />
                                <Route path="/mailservice" component={MailService} />
                                <Route path="/employees" component={Employees} />
                                {this.renderRedirPath()}
                            </Switch>
                        </div>
                    </div>
                </Router>
            </>
        )
    }

    public componentDidMount() {
        DateTime.local().setZone("local")
        // tslint:disable-next-line: no-console
        console.info("Are you looking for something, punk?!")
        if (getCookie("token").length > 0) {
            const token = getCookie("token")
            const tlen = token.length
            const role = token[tlen - 1]
            menus.load(parseInt(role, 10))
            guistate.setAuth(true)
            periodicNewsRequest()
        } else {
            // tslint:disable-next-line: no-console
            console.log("no cookies set")
        }
    }

    private renderRedirPath() {
        return <Redirect to={"/einteilung"}></Redirect>
    }
}

export default App
