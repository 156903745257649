import { Instance, types, applySnapshot } from "mobx-state-tree"

const TEinteilungUser = types.model({
    id: types.string,
    name: types.string,
    position: types.optional(types.string, ""),
})

const EinteilungState = types
    .model({
        einteilungID: types.string,
        available: types.optional(types.array(TEinteilungUser), []),
        entered: types.optional(types.array(TEinteilungUser), []),
    })
    .actions((self: any) => {
        const actions = {
            clearLists: () => {
                self.available = []
                self.entered = []
            },
            containCheck: (id, list) => {
                let index = 0
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === id) {
                        index++
                    }
                }
                return index === 0
            },
            addToAvailable: (id, name) => {
                if (self.containCheck(id, self.available)) {
                    if (self.containCheck(id, self.entered)) {
                        self.available.push({ id, name })
                    }
                }
            },
            addToPosition: (id, name, position) => {
                self.entered.push({ id, name, position })
            },
            removeFromAvailable: (id) => {
                for (let i = 0; i < self.available.length; i++) {
                    if (self.available[i].id === id) {
                        self.available.splice(i, 1)
                    }
                }
            },
            removeFromPosition: (id) => {
                for (let i = 0; i < self.entered.length; i++) {
                    if (self.entered[i].id === id) {
                        self.entered.splice(i, 1)
                    }
                }
            },
        }
        return actions
    })

const EinteilungStates = types
    .model({
        items: types.array(EinteilungState),
    })
    .actions((self) => {
        const actions = {
            addtoList: (id: string) => {
                self.items.push({einteilungID: id, available: [], entered: []})
            },
            addEinteilungen: (items) => {
                applySnapshot(self.items, items)
            },
        }
        return actions
    })

export type IEinteilungState = Instance<typeof EinteilungState>
export type IEinteilungStates = Instance<typeof EinteilungStates>
const guiInst = EinteilungStates.create({})
export default guiInst
