export const toHumanDate = (toformat: string) => {
    const date = new Date(toformat).toISOString()
    const day = date.substr(8, 2)
    const month = date.substr(5, 2)
    const year = date.substr(0, 4)
    const parsedDate = `${day}.${month}.${year}`
    return parsedDate
}

export const weekDay = (toformat: string, long: boolean) => {
    const WEEKDAYS = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"]
    const WEEKDAYS_LONG = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
    const date = new Date(toformat)
    const n = date.getDay()
    const parsedDate = long ? WEEKDAYS_LONG[n] : WEEKDAYS[n]
    return parsedDate
}
