import Autosuggest from "react-autosuggest"
import React, { Component } from "react"
import "./autosuggest.css"


// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name

let usernames: Array<{ name: string, id: string }> = []

interface IAutoSuggestState {
    value: string
    id: string
    suggestions: any[]
}

interface IAutoSuggestProps {
    list: Array<{ name: string, id: string }>
    onSelectName: (name: string, id: string) => void
}

export class AutoSuggest extends Component<IAutoSuggestProps, IAutoSuggestState> {
    constructor(props) {
        super(props)

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: "",
            id: "",
            suggestions: [],
        }

        this.getSuggestions = this.getSuggestions.bind(this)
        this.renderSuggestion = this.renderSuggestion.bind(this)
    }

    public render() {
        const { value, id, suggestions } = this.state

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: "Name",
            value,
            id,
            onChange: this.onChange,
        }

        usernames = this.props.list.map((item) => {
            return { name: item.name, id: item.id }
        })

        // Finally, render it!
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        )
    }

    private renderSuggestion(suggestion) {
        const { onSelectName } = this.props
        return (
            <div id={suggestion.id} onClick={() => onSelectName(suggestion.name, suggestion.id)}>
                {suggestion.name}
            </div>)
    }

    private getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length

        return inputLength === 0
            ? []
            : usernames.filter(
                (lang) => lang.name.toLowerCase().includes(inputValue),
                // (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue,
            )
    }

    private onSuggestionSelected = () => {
        this.setState({
            value: "",
        })
    }

    private onChange = (event, { newValue }) => {
        event.persist()
        this.setState({
            value: newValue,
        })
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    private onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        })
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    private onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }
}
