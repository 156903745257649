import { observer } from "mobx-react"
import * as React from "react"
import { ITableProps, ITableData, ITableColumn } from "."
import TableData from "./data"
import { getCookie } from "../../utils/cookiemanager"

interface ITableRowProps {
    columns: ITableColumn[]
    onSelect?: (el: any, state: boolean) => void
    onOpen?: (el: any) => void
    idx: number
    el: ITableData
    openId?: string | number
    renderDetailRow?: (el: any) => JSX.Element
    oldTS?: string
}

interface ITableRowState {
    isDetailOpen: boolean
}
type TTableAddtionContent = JSX.Element | undefined
export interface ITableAdditions {
    top?: TTableAddtionContent | TTableAddtionContent[],
    betweentop?: TTableAddtionContent | TTableAddtionContent[],
    betweenbottom?: TTableAddtionContent | TTableAddtionContent[],
    bottom?: TTableAddtionContent | TTableAddtionContent[],
}

@observer
export class TableRow extends React.Component<ITableRowProps, ITableRowState> {

    constructor(props: ITableRowProps, context?: any) {
        super(props, context)
        this.state = {
            isDetailOpen: false,
        }
        this.toggleShowDetail = this.toggleShowDetail.bind(this)
    }
    public render() {
        const { el, idx, columns, onSelect, onOpen, renderDetailRow } = this.props
        const { isDetailOpen } = this.state
        return (
            <React.Fragment>
                <tr className={this.classNames(el)} >
                    {columns.map((col, idxD) => (
                        <TableData
                            key={"tdIdx" + idx + "-" + idxD}
                            col={col}
                            onOpen={onOpen}
                            onSelect={onSelect}
                            toggleShowDetail={renderDetailRow != null ? this.toggleShowDetail : undefined}
                            detailOpen={isDetailOpen}
                            el={el} />
                    ))}
                </tr>
                {renderDetailRow != null && isDetailOpen && this.renderDetailRow(el)}
            </React.Fragment>
        )
    }
    private renderDetailRow(el: any) {
        const { columns, renderDetailRow } = this.props
        if (renderDetailRow == null) {
            return
        }
        return (
            <React.Fragment>
                {/* This empty row is used to keep the odd even markers for the following rows */}
                <tr className="detail-view-empty"></tr>
                <tr className="detail-view">
                    <td className="detail-view-fist-td"></td>
                    <td colSpan={columns.length - 1}>
                        {renderDetailRow(el)}
                    </td>
                </tr>
            </React.Fragment>
        )
    }

    private toggleShowDetail() {
        this.setState({ isDetailOpen: !this.state.isDetailOpen })
    }

    private classNames(el: ITableData): string {
        const { openId } = this.props

        const cls: string[] = []
        const specialclass = getCookie("userid") === "30" ? "spirli-color" : ""
        if (el.selected) {
            cls.push("selected")
        }
        if (el.active) {
            cls.push("selected")
        }
        if (openId != null && el.id === openId) {
            cls.push("active")
        }
        return cls.join(" ") + specialclass
    }
}

@observer
export default class TableBody extends React.Component<ITableProps, {}> {

    private oldSeq: number = 0
    private oldTS: string = ""

    public render() {
        const { columns, data, onSelect, onOpen, openId, renderDetailRow } = this.props
        return (
            <tbody>
                {this.returnBanner("top")}
                {(data.length > 0) &&
                    data.map((el, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                {this.returnBanner("betweentop", el)}
                                <TableRow key={"trIdx" + idx}
                                    idx={idx}
                                    el={el}
                                    columns={columns}
                                    onOpen={onOpen}
                                    openId={openId}
                                    onSelect={onSelect}
                                    renderDetailRow={renderDetailRow}
                                />
                            </React.Fragment>
                        )
                    })
                }
                {
                    (data.length === 0) &&
                    <tr>
                        <td className="emptytable" colSpan={999}>Keine Daten</td>
                    </tr>
                }
                {this.returnBanner("bottom")}
            </tbody>
        )
    }

    private returnBanner(position: string, el?: any): TTableAddtionContent | TTableAddtionContent[] {
        const { renderRowBanner } = this.props
        if (renderRowBanner) {
            const bannerWel = renderRowBanner(el)
            if (bannerWel) {
                switch (position) {
                    case "top":
                        return bannerWel.top ? bannerWel.top : undefined
                    case "betweentop":
                        return bannerWel.betweentop ? bannerWel.betweentop : undefined
                    case "betweenbottom":
                        return bannerWel.betweenbottom ? bannerWel.betweenbottom : undefined
                    case "bottom":
                        return bannerWel.bottom ? bannerWel.bottom : undefined
                }
            }
        }
    }
}
