export interface ICookie {
    name: string
    cookiecontent: string
}

export const setadditionalcookie = (data: ICookie, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    if (exdays === -1) {
        expires = "expires=Thu, 01 Jan 1970 00: 00: 00 UTC"
    }
    const strmeta = data.cookiecontent
    strmeta.replace("ö", "oe")
    strmeta.replace("ü", "ue")
    strmeta.replace("ß", "ss")
    strmeta.replace("ä", "ae")
    document.cookie = data.name + "=" + strmeta + ";" + expires + ";path=/"
}

export const overwritecookies = (data: ICookie[]) => {
    return
}

export const getCookie = (cname: string): string => {
    const name = cname + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(";")
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

export const getAllCookies = () => {
    return
}
