import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

export interface IEinteilungFormResponse {
    einteilungID: string
    title: string
    date: string
    ts: number
    additionalInfo: string
    additionalPos: Array<{
        additionalposition: string
        personalid: string
        title: string
        name: string
        time: string
    }>
    status: string
    locationID: string
    namesentered: string[]
    available: Array<{ id: string; name: string; position: string; time?: string | "" }>
    entered: Array<{ id: string; name: string; position: string; time?: string | "" }>
    oblpos: string[]
    customtimes: Array<{ pos: string; time: string }>
    mode: number
}

export const requestActiveEinteilungen = (from?, to?): Promise<IEinteilungFormResponse[]> => {
    const id = getCookie("userid")
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getEinteilungsForms`,
                from,
                to,
                id,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(res)
        })
    })
}

export const deleteEinteilung = (id): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `deleteEinteilung`,
                id,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // fill
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}
