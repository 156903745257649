import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import einteilungforms, { IEinteilungForm } from "../../stores/einteilungforms"
import { myeinteilung } from "../../stores"
import locations from "../../stores/locations"
import guistate from "../../stores/guistate"
import userdata from "../../stores/userdata"
import Loading from "../../components/loading"
import { jumpTo as jumpToEinteilung } from "../einteilungsview"
import { CheckBoxTwoTone, IndeterminateCheckBoxTwoTone } from "@material-ui/icons"
import Table from "../../components/table"
import HeaderFilter from "../../components/Headerfilter"
import { DateTime } from "luxon"

interface IListState {
    statusfilter: string[]
    clubfilter: string[]
}

export const renderInIcon = (): JSX.Element => {
    return <CheckBoxTwoTone></CheckBoxTwoTone>
}

export const renderOutIcon = (): JSX.Element => {
    return <IndeterminateCheckBoxTwoTone></IndeterminateCheckBoxTwoTone>
}

export const DAYS = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]

interface IEnterEinteilungListProps {
    userid: string
    // clubfilter: string
}

@observer
class MyEinteilungList extends Component<IEnterEinteilungListProps, IListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            statusfilter: ["1"],
            clubfilter: ["1", "2", "3", "4"],
        }

        this.filter = this.filter.bind(this)
        this.onOpen = this.onOpen.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }

        // const { columns, data } = einteilungforms.tableView(false, clubfilter, userid, statusfilter)
        // console.log(myeinteilung.items)
        const { columns, data } = myeinteilung.tableView()
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading></Loading>}
                {
                    <HeaderFilter
                        filteroptions={einteilungforms.filteroptions(false)}
                        currentstate={{ locationIDs: "", status: "2" }}
                        onConfirm={this.confirmFilter}
                        onDateConfirm={this.onDateConfirm}
                        withdatefilter={true}
                        hidestatus={true}
                        withfilterbutton={false}
                        removeFilter={this.removeFilter}
                        onOptionFilter={() => {
                            return
                        }}
                    ></HeaderFilter>
                }
                <Table
                    columns={columns}
                    data={data}
                    onSort={this.onSort}
                    // unfolded={tag.unfolded}
                    sortKey={myeinteilung.sortCol}
                    colSort={myeinteilung.sortAsc}
                    onOpen={this.onOpen}
                ></Table>
            </React.Fragment>
        )
    }

    public componentDidMount() {
        locations.load()
        if (!userdata.loaded) {
            userdata.load()
        }

        if (einteilungforms.loaded) {
            // einteilungforms.setFilterObj({ status: ["2"] })
        } else {
            einteilungforms.load({})
        }
    }

    private onSort(key: string, dir: boolean) {
        myeinteilung.setSortCol(key)
        myeinteilung.setSortDir(dir)
    }

    private onOpen(item: IEinteilungForm) {
        jumpToEinteilung({ type: "view", id: item.einteilungID })
    }

    private removeFilter(data: { type: string; val: string }) {
        // switch (data.type) {
        //     case "locationIDs":
        //         jumpTo({ type: "list", filter: filter });
        //         break
        // }
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(DateTime.local().plus({ month: 2 }).toMillis() / 1000)
        }
        if (data.to == null) {
            to = Math.round(DateTime.local().minus({ month: 2 }).toMillis() / 1000)
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        einteilungforms.load({ from, to })
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, statusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        statusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                statusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "Status") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private confirmFilter(filter) {
        // einteilungforms.setFilterObj({ locationIDs: filter["Club"] })
    }
}

export default MyEinteilungList
