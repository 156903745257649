import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import { myeinteilung } from "../../stores"
import einteilungforms from "../../stores/einteilungforms"
import { updateApphead } from "../../utils/dommanip"
import Loading from "../../components/loading"
import MailList from "./mail_list"
import CustomMail from "./custom_mail"
import "./mailservice.css"

const basepath = "/mailservice"

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        case "view":
            return `${basepath}/view/${opt.id}`
        case "custom":
            return `${basepath}/custom`
        case "list":
            return `${basepath}/list/${opt.only}`
        default:
            return basepath
    }
})

interface IEinteilungProps
    extends RouteComponentProps<{
        id: string
    }> { }

@observer
class MailService extends Component<IEinteilungProps, {}> {
    constructor(props: any, context?: any) {
        super(props, context)

        this.list = this.list.bind(this)
        this.showMain = this.showMain.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"> </Redirect>
        }
        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.showMain} />
                <Route path={getUrl({ type: "list", only: ":only" })} exact render={this.list} />
                <Route path={getUrl({ type: "custom" })} exact render={this.createCustomMail} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Mailservice", basepath)
        if (!userdata.loaded) {
            userdata.load()
        }
        if (!einteilungforms.loaded) {
            einteilungforms.load({})
        }
    }

    private showMain(): JSX.Element {
        return this.showActions()
        // return this.list()
    }

    private showActions(): JSX.Element {
        return (
            <div className="admin-mailoptions">
                <div
                    className="button"
                    onClick={() => {
                        jumpTo({ type: "list", only: "onlyopen" })
                    }}
                >
                    <span>Öffnungstage freigeben</span>
                </div>
                <div
                    className="button"
                    onClick={() => {
                        jumpTo({ type: "list", only: "onlyclosed" })
                    }}
                >
                    <span>Einteilungen freigeben</span>
                </div>
                <div
                    className="button"
                    onClick={() => {
                        jumpTo({ type: "custom" })
                    }}
                >
                    <span>Nachricht versenden</span>
                </div>
            </div>
        )
    }

    private list({ match }): JSX.Element {
        const { only } = match.params
        const id = getCookie("userid")
        if (einteilungforms.loaded) {
            einteilungforms.items.forEach((item) => {
                if (item.available) {
                    const availableItems = item.available.filter((el) => el.id === id)
                    if (availableItems.length > 0) {
                        myeinteilung.addToAvailableList(item)
                    }
                }
            })
        }
        return <MailList only={only}></MailList>
    }

    private createCustomMail(): JSX.Element {
        return <CustomMail></CustomMail>
    }
}

export default MailService
