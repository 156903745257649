import React, { Component } from "react"
import { observer } from "mobx-react"
import { IEinteilungForm } from "../../stores/einteilungforms"
import positions from "../../stores/positions"
import userdata, { IUserItem } from "../../stores/userdata"
import locations from "../../stores/locations"
import einteilungstate from "../../stores/einteilungstate"
import DetailView from "../../components/detailview"
import { jumpTo } from "."
import Loading from "../../components/loading"
import einteilungforms from "../../stores/einteilungforms"
import { getSnapshot } from "mobx-state-tree"
import { updateEinteilungform } from "../../api/neweinteilungform"
import { exportSingleEinteilung } from "../../utils/pdfexport"
import { AutoSuggest } from "../../components/autosuggest"
import { ArrowUpward, CheckBox, CheckBoxOutlineBlank, Info, KeyboardArrowDown, KeyboardArrowUp, Warning, WarningOutlined } from "@material-ui/icons"
import { DateTime } from "luxon"
import Tooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"
import bind from "bind-decorator"

interface ITestAppState {
    dragging: boolean
    starttimes: Array<{
        pos: string
        time: string
    }>
    personalQuery?: string
    showBlocked: boolean
}

interface ITestAppProps {
    einteilung: IEinteilungForm
    filter: any
}

interface IPersonalItem { id: string, name: string }

const enteredUsers = (einteilung): Array<{ name: string; userid: string; position: string }> | undefined => {
    if (einteilung.entered.length !== 0) {
        const availablelist = einteilung.entered.map((el) => {
            const user = userdata.items.filter((item) => {
                return item.userID === el.id
            })
            if (user) {
                if (user[0]) {
                    user[0].loadUserLoehne()
                }
            }
            // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
            const usr = userdata.find(el.id)
            let name = el.name
            if (usr) {
                name = usr.realName
            }
            return { name, userid: el.id, position: el.position }
        })
        return availablelist
    }
}

const availableUsers = (einteilung): Array<{ name: string; userid: string }> | undefined => {
    if (einteilung.available.length !== 0) {
        const availablelist = einteilung.available.map((el) => {
            const user = userdata.items.filter((item) => {
                return item.userID === el.id
            })
            if (user) {
                if (user[0]) {
                    user[0].loadUserLoehne()
                }
            }
            // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
            const usr = userdata.find(el.id)
            let name = el.name
            if (usr) {
                name = usr.realName
            }
            return { name, userid: el.id }
        })
        return availablelist
    }
}

@observer
class EinteilungManage extends Component<ITestAppProps, ITestAppState> {
    constructor(props: any) {
        super(props)

        this.state = {
            dragging: false,
            starttimes: positions.items.map((el) => {
                return { pos: el.positionID, time: el.starttime }
            }),
            showBlocked: false
        }
        this.renderTimeInput = this.renderTimeInput.bind(this)
        this.changeStarttime = this.changeStarttime.bind(this)
        this.onSelectName = this.onSelectName.bind(this)
    }

    public render() {
        const { einteilung, filter } = this.props
        const availablelist = availableUsers(einteilung)
        const enteredlist = enteredUsers(einteilung)
        const availablenamelist = availablelist ? availablelist : []
        const enterednamelist = enteredlist ? enteredlist : []
        const einteilungstateitem = einteilungstate.items.filter(
            (elem) => elem.einteilungID === einteilung.einteilungID,
        )[0]

        availablenamelist.forEach((el, idx) => {
            if (el != null) {
                if (einteilungstateitem) {
                    // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
                    const usr = userdata.find(el.userid)
                    let name = el.name
                    if (usr) {
                        name = usr.realName
                    }
                    einteilungstateitem.addToAvailable(el.userid, name)
                }
            }
        })

        enterednamelist.forEach((el, idx) => {
            if (el != null) {
                if (einteilungstateitem) {
                    // const name = el.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
                    const usr = userdata.find(el.userid)
                    let name = el.name
                    if (usr) {
                        name = usr.realName
                    }
                    einteilungstateitem.addToPosition(el.userid, name, el.position)
                }
            }
        })

        const returnItem = (pos) => {
            const len = einteilung.entered.length
            for (let i = 0; i < len; i++) {
                if (pos.positionID === einteilung.entered[i].position) {
                    return this.renderEntry(einteilung.entered[i], "left")
                }
            }
            return <div className={`openslot ${this.state.dragging ? "dragging" : ""}`}></div>
        }

        const returnAdditionalItem = (pos) => {
            const len = einteilung.additionalPos.length
            for (let i = 0; i < len; i++) {
                if (pos === einteilung.additionalPos[i].additionalposition) {
                    const data = {
                        id: einteilung.additionalPos[i].additionalposition,
                        name: einteilung.additionalPos[i].name
                    }
                    return this.renderEntry(data, "left")
                }
            }
            return <div className={`openslot ${this.state.dragging ? "dragging" : ""}`}></div>
        }

        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        const locationPosList = positions.items.map((el) => {
            if (el.locationID === einteilung.locationID) {
                if (el.modesList().includes(einteilung.mode)) {
                    if (el) {
                        return el
                    }
                }
            } return undefined
        }).filter((el) => el != null)

        const filteredList = locationPosList.filter((el) => el !== undefined)

        filteredList.sort((a, b) => {
            if (a && b) {
                if (einteilung.locationID === "1" || einteilung.locationID === "2") {
                    return parseInt(a.sortNo, 10) > parseInt(b.sortNo, 10)
                        ? 1
                        : parseInt(b.sortNo, 10) > parseInt(a.sortNo, 10)
                            ? -1
                            : 0
                }
                return parseInt(a.positionID, 10) > parseInt(b.positionID, 10)
                    ? 1
                    : parseInt(b.positionID, 10) > parseInt(a.positionID, 10)
                        ? -1
                        : 0
            }
            return 0
        })

        return (
            <div className="container-drag">
                <div className="leftsection">
                    <div className="sectioncontainer">
                        {filteredList.map((el, idx) => {
                            if (el) {
                                if (el.showineinteilung !== "2") {
                                    const isObl = einteilung.oblpos.filter((elem) => elem === el.positionID).length > 0
                                    // const customtime = einteilung.customtimes.filter(
                                    //     (elem) => elem.pos === el.positionID,
                                    // )
                                    return (
                                        <div
                                            key={idx}
                                            className={"clubposition flex flex-col " + el.positionID}
                                            onDragOver={(e) => this.onDragOver(e)}
                                            onDrop={(e) => {
                                                this.onDrop(einteilungstateitem, e, el.positionID, "left")
                                            }}
                                        >
                                            <span className={`task-header highlight-${el.color}`}>
                                                {el.positionName}
                                            </span>
                                            {returnItem(el)}
                                            <input
                                                type="time"
                                                value={this.renderTimeInput(el.positionID)}
                                                onChange={(val) => {
                                                    this.changeStarttime(val, el.positionID)
                                                }}
                                            ></input>
                                            <div className="obligatory-toggle">
                                                {isObl && (
                                                    <CheckBox
                                                        onClick={() => {
                                                            const oldList = getSnapshot(einteilung.oblpos)
                                                            const newList = oldList.filter(
                                                                (item) => item !== el.positionID,
                                                            )
                                                            einteilung.updateObl(newList)
                                                        }}
                                                    ></CheckBox>
                                                )}
                                                {!isObl && (
                                                    <CheckBoxOutlineBlank
                                                        onClick={() => {
                                                            const oldList = getSnapshot(einteilung.oblpos)
                                                            const newList = [...oldList, el.positionID]
                                                            einteilung.updateObl(newList)
                                                        }}
                                                    ></CheckBoxOutlineBlank>
                                                )}
                                                <p>Obligatorisch</p>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                            return <></>
                        })}
                        <hr></hr>
                        {einteilung.additionalPos.map((el, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={"clubposition"}
                                    onDragOver={(e) => this.onDragOver(e)}
                                    onDrop={(e) => {
                                        this.onDrop(einteilungstateitem, e, el.additionalposition, "left")
                                    }}
                                >
                                    <input
                                        className="task-header"
                                        type="text"
                                        value={"test"}
                                        onChange={(val) => {
                                            this.changeAdditionalTitle(val, el.additionalposition)
                                        }}
                                    ></input>
                                    {returnAdditionalItem(el)}
                                    <input
                                        type="time"
                                        value={this.renderTimeInput(el.additionalposition)}
                                        onChange={(val) => {
                                            this.changeAdditionalStarttime(val, el.additionalposition)
                                        }}
                                    ></input>
                                </div>
                            )
                        })}
                    </div>
                    {/* this.renderAddPositionButton() */}
                </div>
                <div className="rightsection">
                    <DetailView
                        header={() => this.renderHeader()}
                        content={() => this.renderContent(einteilungstateitem)}
                        onClose={() => jumpTo({ type: "list", filter })}
                        onSave={() => this.saveEinteilung(einteilungstateitem, () => jumpTo({ type: "list", filter }))}
                        customActions={this.renderCustomActions()}
                    ></DetailView>
                </div>
            </div>
        )
    }

    public componentDidMount() {
        positions.load()
        if (!locations.loaded) {
            locations.load()
        }
        if (!userdata.loaded) {
            userdata.load()
        }
    }

    private changeAdditionalTitle(val, pos) {
        const { einteilung } = this.props
        const additionalPos = einteilung.additionalPos.filter((el) => el.additionalposition === pos)
        if (additionalPos.length > 0) {
            additionalPos[0].title = val
        }
    }

    private changeAdditionalStarttime(val, pos) {
        const { einteilung } = this.props
        const additionalPos = einteilung.additionalPos.filter((el) => el.additionalposition === pos)
        if (additionalPos.length > 0) {
            additionalPos[0].time = val
        }
    }

    private renderTimeInput(el): string {
        const { einteilung } = this.props
        const customtime = einteilung.customtimes.filter((elem) => elem.pos === el)
        if (customtime.length > 0) {
            if (customtime[0].time !== "") {
                return customtime[0].time
            }
        }
        const storetime = positions.items.filter((item) => item.positionID === el)[0]
        if (storetime) {
            return storetime.starttime
        }
        return "21:00"
    }

    private changeStarttime(ev, pos: string) {
        ev.persist()
        const position = positions.find(pos)
        const { einteilung } = this.props
        if (position) {
            position.changeStarttime(ev.target.value)
            const tobeupdated = einteilung.entered.filter((el) => el.position === pos)[0]
            if (tobeupdated) {
                einteilung.updateEnteredTime(pos, ev.target.value)
            }
        }
    }

    private renderHeader() {
        const { einteilung } = this.props
        const location = locations.find(einteilung.locationID)
        const usernames = userdata.items.map((el) => {
            return { name: el.realName, id: el.userID }
        })

        const allowAdminUserAdd = () => {
            switch (einteilung.locationID) {
                case "5":
                case "6":
                    return true
            }
            return false
        }

        // const usernames = [{name: "Marco Jakob", id: "1"}, {name: "Fabian Stauber", id: "2"}]
        return (
            <React.Fragment>
                {location && <div className="locationname">{location.locationName}</div>}
                <div className="einteilung">Einteilung {einteilung.readableDate}</div>
                <div className="einteilung-title">{einteilung.title}</div>
                {allowAdminUserAdd() && this.renderAdminUserAdd(usernames)}
            </React.Fragment>
        )
    }

    @bind
    private renderAllPersonal() {
        const { einteilung } = this.props

        const { showBlocked } = this.state
        // const { einteilung } = this.props
        // const blocked = einteilung.available.map((el) => el.id)
        const users = userdata.items.map((el) => {
            return el
        })
        const enteredUsers = einteilung.entered.map((el) => {
            return el.id
        })

        const blockedUsers = einteilung.available.map((el) => {
            return el.id
        })


        return <div className={`pb-5 all-personal-list-wrp ${showBlocked ? "open" : ""}`}>

            <div className="h-full overflow-auto all-personal-list">
                {users.sort((a, b) => {
                    if (a.realName < b.realName)
                        return -1;
                    if (a.realName > b.realName)
                        return 1;
                    return 0;
                }).filter((el) => {

                    if (!el.preferredLocations().includes("2") && !el.preferredLocations().includes("3") && !el.preferredLocations().includes("4")) {
                        return false
                    }
                    if (enteredUsers.includes(el.userID)) {
                        return false
                    }
                    if (blockedUsers.includes(el.userID)) {
                        return false
                    }

                    const query = this.state.personalQuery
                    if (query) {
                        return el._searchable.includes(query.toLowerCase())
                    }
                    return true
                }).map((el) => {
                    return this.renderEntry(el, "right")
                })}
            </div>
        </div>
    }

    @bind
    private renderBlocked() {
        const { einteilung } = this.props
        const { showBlocked } = this.state
        return <div className={`blocked-list-wrp ${showBlocked ? "" : "collapsed"}`}>
            <hr></hr>
            <div className="flex items-center pb-2 pl-2 rounded-md cursor-pointer hover:bg-gray-800 hover:text-white hover:border-gray-700" onClick={() => {
                this.setState({
                    ...this.state,
                    showBlocked: !showBlocked
                })
            }}>
                Geblockt
                {showBlocked && <KeyboardArrowDown />}
                {!showBlocked && <KeyboardArrowUp />}
            </div>
            <div className="overflow-y-auto blocked-list">
                {einteilung.available.map((el, idx) => {
                    return <div className="flex items-center ml-3 font-light" key={idx}>
                        <span>{el.name.replace("u00f6", "ö")
                            .replace("u00fc", "ü")
                            .replace("u00e4", "ä")
                            .replace("u00e1", "á")}</span>
                        <div className="text-gray-700" onClick={() => {
                            einteilung.removeFromAvailable(el.id)
                        }}>
                            <ArrowUpward />
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    @bind
    private renderContent(item) {
        const { einteilung } = this.props
        const showAllPersonal = () => {
            switch (einteilung.locationID) {
                case "5":
                case "6":
                    return false
            }
            return true
        }
        return (
            <React.Fragment>
                <div
                    className={`droppable h-full w-full ${this.state.dragging ? "dragging" : ""}`}
                    onDragOver={(e) => this.onDragOver(e)}
                    onDrop={(e) => this.onDrop(item, e, "complete", "right")}
                >
                    {showAllPersonal() && <input placeholder="Filter" className="text-xl rounded-md all-personal-filter" value={this.state.personalQuery} onChange={(ev) => {
                        this.setState({
                            ...this.state,
                            personalQuery: ev.target.value
                        })
                    }} />}
                    {showAllPersonal() && this.renderAllPersonal()}
                    {showAllPersonal() && this.renderBlocked()}
                    {!showAllPersonal() && einteilung.available.map((el) => {
                        return this.renderEntry(el, "right")
                    })}
                </div>
            </React.Fragment>
        )
    }

    private renderCustomActions() {
        const { einteilung } = this.props
        return [
            <div
                className="formbutton"
                onClick={() => {
                    const data = einteilung.entered.map((el) => {
                        const posName = positions.items.filter((elem) => elem.positionID === el.position)
                        if (posName.length > 0) {
                            const name = el.name
                                .replace("u00f6", "ö")
                                .replace("u00fc", "ü")
                                .replace("u00e4", "ä")
                                .replace("u00e1", "á")
                            return {
                                name,
                                position: posName[0].positionName,
                                starttime: "",
                            }
                        } return undefined
                    }).filter((el) => el != null)
                    if (data.length > 0) {
                        exportSingleEinteilung(
                            einteilung,
                            positions.items.filter((el) => el.locationID === einteilung.locationID),
                        )
                    }
                }}
            >
                Export
            </div>
        ]
    }

    private renderAdminUserAdd(list) {
        return <AutoSuggest onSelectName={this.onSelectName} list={list}></AutoSuggest>
    }

    private onSelectName(name, id) {
        // console.log(name)
        // console.log(id)
        const { einteilung } = this.props
        einteilung.addToAvailable(id, name)
    }

    private getLohn(id): { colorcode: string; lohn: string | number } {
        const user = userdata.find(id)
        let colorcode = ""

        if (user) {
            let gesamtlohn = 0
            let maxlohn = 0
            user.loehne.forEach((el) => {
                if (el.lohn > 0) {
                    maxlohn += 450
                }
                gesamtlohn += el.lohn
            })
            const ratio = gesamtlohn / maxlohn
            if (ratio >= 1) {
                colorcode = "color-warn"
            } else if (ratio <= 0.7) {
                colorcode = "color-ok"
            } else if (ratio <= 0.99) {
                colorcode = "color-info"
            }
            return { colorcode, lohn: gesamtlohn }
        }
        return { colorcode, lohn: "…" }
    }

    private getOtherdays(id) {
        const otherDays: Array<{ ts: number; posName: string; date: string; location: string; locID: string }> = []
        einteilungforms.items.forEach((item: IEinteilungForm) => {
            if (item.entered) {
                const entereditems = item.entered.filter((el) => el.id === id)
                if (entereditems.length > 0) {
                    const posName = positions.find(entereditems[0].position)
                    const parsedDate = DateTime.fromFormat(item.date, "yyyy-MM-dd").toFormat("dd.MM.yyyy")
                    const ts = DateTime.fromFormat(item.date, "yyyy-MM-dd").toSeconds()
                    const locationData = locations.find(item.locationID)
                    if (posName && locationData) {
                        otherDays.push({
                            ts,
                            posName: posName.positionName,
                            date: parsedDate,
                            location: locationData.locationName,
                            locID: item.locationID,
                        })
                    }
                }
            }
        })
        otherDays.sort((a, b) => {
            if (a && b) {
                return a.ts > b.ts ? 1 : b.ts > a.ts ? -1 : 0
            }
            return 0
        })
        return otherDays
    }

    private renderEntry(item: IPersonalItem | IUserItem, source): JSX.Element {
        const { einteilung } = this.props

        // const availableIds = einteilung.available.map((el) => el.id)

        const useBlock = !["5", "6"].includes(einteilung.locationID)

        let id = ""
        let name = ""
        if (item["id"] != null) {
            id = (item as IPersonalItem).id
            name = (item as IPersonalItem).name
        } else {
            id = (item as IUserItem).userID
            name = (item as IUserItem).realName
        }

        let loadSalaries = () => {
            switch (einteilung.locationID) {
                case "5":
                case "6":
                    return true
                default:
                    return false
            }
        }

        const lohninfos = () => {
            switch (einteilung.locationID) {
                case "5":
                case "6":
                    return this.getLohn(id)
                default:
                    return { lohn: "", colorcode: "" }
            }
        }
        const { lohn, colorcode } = lohninfos()
        const days = this.getOtherdays(id)
        // const name = item.name.replace("u00f6", "ö").replace("u00fc", "ü").replace("u00e4", "ä")
        const usr = userdata.find(id)
        let lastday: string | null = null
        if (usr) {
            name = usr.realName
            if (usr.warnLastDaySince) {
                lastday = usr.readableLastDay
            }
        }
        const showWarning = days.filter(
            (el) => el.date === einteilung.readableDate && el.locID !== einteilung.locationID,
        )

        return (
            <div
                key={id}
                onDragStart={(e) => this.onDragStart(e, id, name, source)}
                onDrag={(e) => this.onDrag(e)}
                draggable
                className={`draggable ${!useBlock ? (einteilung.available.map((el) => el.id).includes(id) ? "bg-red-600" : "") : ""}`}
            >
                <span>{name}</span>
                <div className="flex h-full salary-so-far">
                    {loadSalaries() && <><span>Bisher: </span><span className="ml-1">{lohn} €</span>
                        <div className={"status " + colorcode}></div></>}
                    {lastday && <Tooltip placement="left" overlay={`Der letzte Arbeitstag war am ${lastday}. Der Mitarbeiter muss evtl. neu angemeldet werden.`}>
                        <div className="flex items-center font-bold cursor-help">
                            <WarningOutlined className="h-5 leading-none" />
                        </div>
                    </Tooltip>}
                </div>
                {days.length > 0 && (
                    <div className="other-days-wrp">
                        {showWarning.length > 0 && (
                            <Tooltip
                                placement="left"
                                overlay={days.map((day, idx) => {
                                    if (day.date === einteilung.readableDate && day.locID !== einteilung.locationID) {
                                        return (
                                            <>
                                                <span className="warning-day">
                                                    Bereits eingeteilt am selben Tag
                                                </span>
                                                {idx !== days.length - 1 && (
                                                    <div className="h-7" />
                                                )}
                                            </>
                                        )
                                    }
                                    if (day.date !== einteilung.readableDate) {
                                        return (
                                            <>
                                                <span>
                                                    {this.renderDayData(day)}
                                                </span>
                                                {idx !== days.length - 1 && (
                                                    <div className="h-7" />
                                                )}
                                            </>
                                        )
                                    }
                                    return <></>
                                })}
                            >
                                <Warning className="warn"></Warning>
                            </Tooltip>
                        )}
                        {!(showWarning.length > 0) && days[0].date !== einteilung.readableDate && (
                            <Tooltip
                                placement="left"
                                overlay={days.map((day, idx) => {
                                    if (day.date !== einteilung.readableDate) {
                                        return (
                                            <>
                                                <span>
                                                    {this.renderDayData(day)}
                                                </span>
                                                {idx !== days.length - 1 && (
                                                    <div className="h-7" />
                                                )}
                                            </>
                                        )
                                    }
                                    return <></>
                                })}
                            >
                                <Info className="info"></Info>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        )
    }

    private renderDayData(day) {
        return <>
            <span>{day.location}</span>
            <span className="w-3">: </span>
            <span className="mt-2">{day.posName}</span>
            <span className="w-3"> am </span>
            <span className="pl-2 mt-2">{day.date}</span>
        </>
    }

    private onDragStart = (ev, id, name, source) => {
        this.setState({
            dragging: true,
        })
        ev.dataTransfer.setData("id", id)
        ev.dataTransfer.setData("name", name)
        ev.dataTransfer.setData("dir", source)
        // console.log("DRAG START")
    }

    private onDrag = (ev) => {
        ev.persist()
        ev.target.style.cursor = "grabbing"
        ev.preventDefault()
    }

    private onDragOver = (ev) => {
        ev.preventDefault()
    }

    private onDrop = (item, ev, cat, target) => {
        const { einteilung } = this.props
        const id = ev.dataTransfer.getData("id")
        const name = ev.dataTransfer.getData("name")
        const from = ev.dataTransfer.getData("dir")
        const useBlock = !["5", "6"].includes(einteilung.locationID)
        this.setState({
            dragging: false,
        })
        if (target === "left") {
            if (from === "right") {
                const position = positions.find(cat)
                if (position) {
                    const alreadyEntered = einteilung.entered.filter((el) => el.position === position.positionID)

                    if (alreadyEntered.length > 0) {
                        const oldID = alreadyEntered[0].id
                        const oldName = alreadyEntered[0].name
                        if (!useBlock) {
                            einteilung.addToAvailable(oldID, oldName)
                        }
                        einteilung.removeFromEntered(oldID, oldName)
                    }
                    einteilung.addToEntered(id, name, cat, position.starttime)
                    if (useBlock) {
                        einteilung.removeFromAvailable(id)
                    }
                }
            } else if (from === "left") {
                const position = positions.find(cat)
                if (position) {
                    const alreadyEntered = einteilung.entered.filter((el) => el.position === position.positionID)
                    if (alreadyEntered.length > 0) {
                        const oldID = alreadyEntered[0].id
                        const oldName = alreadyEntered[0].name
                        if (!useBlock) {
                            einteilung.addToAvailable(oldID, oldName)
                        }
                        einteilung.removeFromEntered(oldID, oldName)
                    }
                    einteilung.updateEntered(id, cat, position.starttime)
                }
            }
        } else if (target === "right") {
            if (from === "left") {
                einteilung.removeFromEntered(id, name)
                // if (useBlock) {
                //     einteilung.addToAvailable(id, name)
                // }
            }
        }
    }

    private saveEinteilung(item, nextstep) {
        const { einteilung } = this.props
        const entered = JSON.stringify(getSnapshot(einteilung.entered))
        const available = JSON.stringify(getSnapshot(einteilung.available))
        const oblpos = JSON.stringify(getSnapshot(einteilung.oblpos))
        const customtime = JSON.stringify(getSnapshot(einteilung.customtimes))
        updateEinteilungform(
            einteilung.einteilungID,
            [entered, available, oblpos, customtime],
            "updateeinteilung",
            nextstep,
        )
        // this.saveAndConfirm()
    }
}

export default EinteilungManage
