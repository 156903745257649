import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import EinteilungList from "./einteilung_list"
import EinteilungNew from "./einteilung_new"
import EinteilungView from "./einteilung_view"
import EinteilungEdit from "./einteilung_edit"
// import EinteilungManage from "./einteilung_fill"
import einteilungforms from "../../stores/einteilungforms"
import { updateApphead } from "../../utils/dommanip"
import Loading from "../../components/loading"
import "./einteilung.css"
import EinteilungManage from "./einteilung_manage"
import qs from "qs"
import EinteilungManageMobile from "./einteilung_manage_mobile"

const basepath = "/admineinteilung"

interface IGetUrlEinteilung {
    id: string
    filter: any
    type: string
}

type TGetUrlExtended = TGetUrl | IGetUrlEinteilung

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrlExtended): string => {
    const filter = opt.filter !== undefined ? "?" + qs.stringify(opt.filter) : ""
    switch (opt.type) {
        case "new":
            return `${basepath}/new${filter}`
        case "view":
            return `${basepath}/view/${opt.id}${filter}`
        case "edit":
            return `${basepath}/edit/${opt.id}${filter}`
        case "list":
            return `${basepath}/list${filter}`
        case "manage":
            return `${basepath}/manage/${opt.id}${filter}`
        case "managemobile":
            return `${basepath}/managemobile/${opt.id}`
        default:
            return basepath
    }
})

interface IEinteilungProps
    extends RouteComponentProps<{
        id: string
    }> { }

@observer
class Einteilung extends Component<IEinteilungProps, {}> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    constructor(props: any, context?: any) {
        super(props, context)

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.list = this.list.bind(this)
        this.listHandler = this.listHandler.bind(this)
        this.newItem = this.newItem.bind(this)
        this.viewItem = this.viewItem.bind(this)
        this.editItem = this.editItem.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!einteilungforms.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "list" })} exact render={this.listHandler} />
                <Route path={getUrl({ type: "new" })} exact render={this.newItem} />
                <Route path={getUrl({ type: "edit", id: ":id" })} exact render={this.editItem} />
                <Route path={getUrl({ type: "view", id: ":id" })} exact render={this.viewItem} />
                <Route path={getUrl({ type: "manage", id: ":id" })} exact render={this.manageItem} />
                <Route path={getUrl({ type: "managemobile", id: ":id" })} exact render={this.manageMobileItem} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Einteilung (Admin)", basepath)
        if (!userdata.loaded) {
            userdata.load()
        }
        // DateTime.local().setZone("local");
        if (!einteilungforms.loaded) {
            einteilungforms.load({})
        }
    }

    private listHandler({ match, location }: IEinteilungProps): JSX.Element {
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        return this.list(filter, "", "list")
    }

    private list(searchparams: any, einteilungid: string, type: string): JSX.Element {
        if (searchparams) {
            const filterObject = searchparams
            einteilungforms.setFilterObj(filterObject)
        }
        // const defaultClub = JSON.parse(getCookie("recnews"))
        // let clubnum = "1"
        // if (defaultClub.length > 0) {
        //     clubnum = defaultClub[0] + ""
        // }
        return (
            <EinteilungList
                type={type}
                clubfilter={searchparams.locationIDs ? searchparams.locationIDs : ""}
                id={einteilungid}
                status={searchparams.status ? searchparams.status : ""}
            />
        )
    }

    private newItem({ location }: IEinteilungProps): JSX.Element {
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        return (
            <React.Fragment>
                <div className="leftsection">{this.list(filter, "", "new")}</div>
                <div className="rightsection">
                    <EinteilungNew filter={filter}></EinteilungNew>
                </div>
            </React.Fragment>
        )
    }

    private editItem({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params
        const einteilung = einteilungforms.find(id)
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        if (einteilung) {
            return (
                <React.Fragment>
                    <div className="leftsection">{this.list(filter, id, "edit")}</div>
                    <div className="rightsection">
                        <EinteilungEdit filter={filter} einteilung={einteilung}></EinteilungEdit>
                    </div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    private manageItem({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params
        const einteilung = einteilungforms.find(id)
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        if (einteilung) {
            return (
                <React.Fragment>
                    <EinteilungManage filter={filter} einteilung={einteilung}></EinteilungManage>
                    {/* EinteilungManage einteilung={einteilung}></EinteilungManage> */}
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    private manageMobileItem({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params
        const einteilung = einteilungforms.find(id)
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        if (einteilung) {
            return (
                <React.Fragment>
                    <EinteilungManageMobile filter={filter} einteilung={einteilung}></EinteilungManageMobile>
                    {/* EinteilungManage einteilung={einteilung}></EinteilungManage> */}
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    private viewItem({ match, location }: IEinteilungProps): JSX.Element {
        const { id } = match.params
        const einteilung = einteilungforms.find(id)
        const { search } = location
        const filter = qs.parse(search.replace("?", ""))
        if (einteilung) {
            return (
                <React.Fragment>
                    <div className="leftsection">{this.list(filter, id, "view")}</div>
                    <div className="rightsection">
                        <EinteilungView filter={filter} einteilung={einteilung}></EinteilungView>
                    </div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Einteilung
