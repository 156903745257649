import { Instance, types, applySnapshot } from "mobx-state-tree"
import { getpositions, IPositionApi } from "../api/positions"
import { sortFn } from "../utils/sort"
import { get as oGet } from "object-path"

export interface IPosition extends Instance<typeof Position> {}
export interface IPositions extends Instance<typeof Positions> {}

let listInstance: IPositions

export const Position = types
    .model({
        positionID: types.string,
        positionName: types.string,
        positionShort: types.string,
        positionType: types.string,
        locationID: types.string,
        sortNo: types.string,
        starttime: types.optional(types.string, ""),
        customstarttime: types.optional(types.string, ""),
        showineinteilung: types.optional(types.string, "0"),
        modes: types.string,
    })
    .preProcessSnapshot((snapshot) => {
        // const ltTypes = ["AC", "TZ", "FS"]
        const overwrite = {
            customstarttime: snapshot.starttime,
        }
        return Object.assign({}, snapshot, overwrite)
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select() {
                self.selected = true
            },
            unselect() {
                self.selected = false
            },
            changeStarttime(time) {
                self.starttime = time
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get sortPosition() {
                if (self.locationID === "1" || self.locationID === "6") {
                    return parseInt(self.sortNo, 10)
                }
                return parseInt(self.positionID, 10)
            },
            get color() {
                switch (self.positionType) {
                    case "1":
                    case "11":
                        return "blue"
                    case "2":
                    case "3":
                    case "4":
                    case "12":
                        return "green"
                    case "6":
                    case "7":
                    case "14":
                        return "yellow"
                }
                return "gray"
            },
            modesList: (): number[] => {
                if (self.modes === "") {
                    return []
                }
                return JSON.parse(self.modes)
            },
        }
        return views
    })

export const getSortEl = (item: IPosition) => {
    if (item.positionName != null) {
        return item.positionName || 0
    }
    return 0
}

const sortGetVAl = (obj: IPosition, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<IPositionApi[]> => getpositions()

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const filterItems = (items: IPosition[], query: string | null, sortCol: string, sortAsc: boolean): IPosition[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()
            return false
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const Positions = types
    .model({
        items: types.array(Position),
        filled: types.optional(types.boolean, false),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: false,
        sortCol: "sortPosition",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            addItems: (item: IPosition) => {
                if (!actions.isContained(item.positionID)) {
                    self.items.push(item)
                }
            },
            clearList: () => {
                self.items.clear()
            },
            isContained: (id: string) => {
                let contains = 0
                self.items.toJSON().forEach((el) => {
                    if (el.positionID === id) {
                        contains++
                    }
                })
                return contains !== 0
            },
            setLoaded: () => {
                self.filled = true
            },
            load: () => {
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                try {
                    actions.clearList()
                    getpositions().then((res: IPositionApi[]) => {
                        // res.map((item) => actions.addItems(item))
                        applySnapshot(self.items, res)
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            find: (id: string) => {
                return self.items.find((el) => el.positionID === id)
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: () => {
                return views.filter(self.searchstring, self.sortCol)
            },
            selected: () => {
                return views.filtered().filter((el) => el)
            },
        }
        return views
    })

listInstance = Positions.create({})
export default listInstance
// export default Locations
