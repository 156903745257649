import { Instance, types, applySnapshot } from "mobx-state-tree"
import { requestActiveEinteilungen } from "../api/geteinteilungen"
import { addMeToEinteilung, removeMeFromEinteilung } from "../api/neweinteilungform"
import { getCookie } from "../utils/cookiemanager"
import { ITableObject } from "../components/table"
import { toHumanDate } from "../utils/dateformatter"
import locations from "./locations"
import positions from "./positions"
import userdata from "./userdata"
import einteilungforms, { IEinteilungForm } from "./einteilungforms"
import { sortFn } from "../utils/sort"
import { get as oGet } from "object-path"

export type IMyEinteilung = Instance<typeof MyEinteilung>
export type IMyEinteilungs = Instance<typeof MyEinteilungen>

export const MyEinteilung = types
    .model({
        einteilungID: types.string,
        locationID: types.string,
        title: types.string,
        date: types.string,
        status: types.string,
    })
    .volatile((self) => ({}))
    .actions((self) => {
        const actions = {}
        return actions
    })
    .views((self) => {
        const views = {}
        return views
    })

export const getSortEl = (item: IMyEinteilung) => {
    if (item.title != null) {
        return item.title || 0
    }
    return 0
}

const sortGetVAl = (obj: IMyEinteilung, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<any> => requestActiveEinteilungen()

export interface IFilter {
    query?: string
}

export const MyEinteilungen = types
    .model({
        confirmed: types.array(MyEinteilung),
        available: types.array(MyEinteilung),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: false,
        sortCol: "date",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IFilter) => {
                return (self.filterObj = fObj)
            },
            addToConfirmedList: (item: IMyEinteilung) => {
                if (!actions.isEnteredin(item.einteilungID, self.confirmed.toJSON())) {
                    self.confirmed.push({
                        einteilungID: item.einteilungID,
                        locationID: item.locationID,
                        date: item.date,
                        title: item.title,
                        status: item.status,
                    })
                }
            },
            addToAvailableList: (item: IMyEinteilung) => {
                if (!actions.isEnteredin(item.einteilungID, self.available.toJSON())) {
                    self.available.push({
                        einteilungID: item.einteilungID,
                        locationID: item.locationID,
                        date: item.date,
                        title: item.title,
                        status: item.status,
                    })
                }
            },
            addToAvailable: (item: IEinteilungForm, nextstep?: any) => {
                const userid = getCookie("userid")
                const realname = getCookie("realname")
                addMeToEinteilung({
                    myID: userid,
                    name: realname,
                    einteilungID: item.einteilungID,
                }).then((res) => {
                    if (res === "successful") {
                        item.addToAvailable(userid, realname)
                        actions.addToAvailableList(item)
                    }
                    if (nextstep) {
                        nextstep(res)
                    }
                })
            },
            addItems: (item: IMyEinteilung, nextstep?: any) => {
                // let newitem: any = {}
                // applySnapshot(newitem, item)
                // const userid = getCookie("userid")
                // const realname = getCookie("realname")
                // addMeToEinteilung({
                //     myID: userid,
                //     name: realname,
                //     einteilungID: item.einteilungID,
                // }).then(() => {
                //     actions.addToConfirmedList(item)
                //     if (nextstep) {
                //         nextstep()
                //     }
                // })
            },
            removeItems: (item: IMyEinteilung, nextstep?: any) => {
                const userid = getCookie("userid")
                const realname = getCookie("realname")
                removeMeFromEinteilung({
                    myID: userid,
                    name: realname,
                    einteilungID: item.einteilungID,
                }).then(() => {
                    self.confirmed.remove(item)
                    if (nextstep) {
                        nextstep()
                    }
                })
            },
            removeFromAvailable: (item: IEinteilungForm, nextstep?: any) => {
                const userid = getCookie("userid")
                const realname = getCookie("realname")
                removeMeFromEinteilung({
                    myID: userid,
                    name: realname,
                    einteilungID: item.einteilungID,
                }).then((res) => {
                    if (res === "successful") {
                        item.removeFromAvailable(userid, realname)
                        actions.removeAvailableById(item.einteilungID)
                    }
                    if (nextstep) {
                        nextstep(res)
                    }
                })
            },
            removeById: (id: string) => {
                const filteredlist = self.confirmed.filter((el) => el.einteilungID !== id)
                applySnapshot(self.confirmed, filteredlist)
            },
            removeAvailableById: (id: string) => {
                const filteredlist = self.available.filter((el) => el.einteilungID !== id)
                applySnapshot(self.available, filteredlist)
            },
            clearList: () => {
                self.confirmed.clear()
            },
            setLoaded: () => {
                self.loaded = true
            },
            isEnteredin: (id: string, list) => {
                let contains = 0
                list.forEach((el) => {
                    if (el.einteilungID === id) {
                        contains++
                    }
                })
                return contains !== 0
            },
            load: () => {
                try {
                    actions.clearList()
                    requestActiveEinteilungen().then((res: IMyEinteilung[]) => {
                        // res.map((item) => actions.addItems(item))
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.confirmed.length
            },
            find: (id: string) => {
                return self.confirmed.find((el) => el.einteilungID === id)
            },
            findInConfirmed: (id: string) => {
                return self.confirmed.find((el) => el.einteilungID === id)
            },
            findInAvailable: (id: string) => {
                return self.available.find((el) => el.einteilungID === id)
            },
            filter: (query: string | null | any): IMyEinteilung[] => {
                return self.confirmed.sort(sortFn(sortGetVAl, self.sortAsc, self.sortCol))
                //  items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
                //  return filterItems(
                //    self.confirmed,
                //    query,
                //    self.sortCol,
                //    self.sortAsc
                //  );
                // if (query == null) {
                //     return self.items
                // }
                // if (typeof query === "string") {
                //     return filterItems(self.items, { query }, self.sortCol, self.sortAsc)
                // }
                // return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: (): IMyEinteilung[] => {
                if (self.filterObj !== {}) {
                    // console.log(views.filter(self.setFilterObj).map(el => el))
                    return views.filter(self.filterObj)
                }
                return views.filter(self.searchstring)
            },
            first: () => {
                return self.confirmed[0]
            },
            filteroptions: () => {
                return []
            },
            tableView: () => {
                const parsedDate = (obj: IMyEinteilung) => {
                    return toHumanDate(obj.date)
                }

                const parsedClub = (obj: IMyEinteilung) => {
                    const location = locations.find(obj.locationID)
                    if (location) {
                        return location.locationName
                    }
                    return ""
                }

                const renderPosition = (obj: IMyEinteilung) => {
                    const myID = getCookie("userid")
                    const user = userdata.items.filter((el) => el.userID === myID)
                    if (user.length > 0) {
                        const userconfirmed = user[0].confirmed()
                            ? user[0].confirmed().filter((el) => el.einteilungID === obj.einteilungID)
                            : []
                        if (userconfirmed.length > 0) {
                            const positionList = positions.items.filter(
                                (el) => el.positionID === userconfirmed[0].position,
                            )
                            if (positionList.length > 0) {
                                return positionList[0].positionName
                            }
                        }
                    }
                    return ""
                }

                const renderStarttime = (obj: IMyEinteilung) => {
                    const myID = getCookie("userid")
                    const user = userdata.items.filter((el) => el.userID === myID)
                    if (user.length > 0) {
                        const userconfirmed = user[0].confirmed()
                            ? user[0].confirmed().filter((el) => el.einteilungID === obj.einteilungID)
                            : []
                        if (userconfirmed.length > 0) {
                            const einteilung = einteilungforms.find(userconfirmed[0].einteilungID)
                            if (einteilung) {
                                const customtime = einteilung.customtimes.filter(
                                    (elem) => elem.pos === userconfirmed[0].position,
                                )
                                if (customtime.length > 0) {
                                    if (customtime[0].time !== "") {
                                        return customtime[0].time
                                    }
                                }
                                // const res = einteilung.findForPos(userconfirmed[0].position)
                                // if (res) {
                                //     return res.time
                                // }
                            }
                            const positionList = positions.items.filter(
                                (el) => el.positionID === userconfirmed[0].position,
                            )
                            if (positionList.length > 0) {
                                return positionList[0].starttime
                            }
                        }
                    }
                    return ""
                }

                const data: ITableObject = {
                    columns: [
                        {
                            label: "Datum",
                            small: true,
                            viewFn: parsedDate,
                            valkey: "date",
                            sortable: true,
                        },
                        {
                            label: "Position",
                            small: true,
                            valkey: "position",
                            viewFn: renderPosition,
                            sortable: false,
                        },
                        {
                            label: "Start",
                            small: true,
                            valkey: "time",
                            viewFn: renderStarttime,
                            sortable: false,
                        },
                        {
                            label: "Club",
                            small: true,
                            viewFn: parsedClub,
                            valkey: "location",
                            sortable: false,
                        },
                    ],
                    data: views.filtered(),
                }

                return data
            },
        }
        return views
    })
// const listInstance = MyEinteilungen.create({})
// export default listInstance
export default MyEinteilungen
