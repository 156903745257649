import { Instance, types, applySnapshot } from "mobx-state-tree"
import { requestActiveEinteilungen, IEinteilungFormResponse } from "../api/geteinteilungen"
import { toHumanDate, weekDay } from "../utils/dateformatter"
import ListLoader from "./mixins/listloader"
import { ITableObject } from "../components/table"
import locations from "./locations"
import einteilungstate from "./einteilungstate"
import {
    renderInIcon,
    renderOutIcon,
    renderInAndClosedIcon,
    renderOutAndClosedIcon,
} from "../apps/entereinteilung/entereinteilung_list"
import { sortFn } from "../utils/sort"
import { get as oGet } from "object-path"
import { renderStatus } from "../apps/einteilung/einteilung_list"
import { getCookie } from "../utils/cookiemanager"
import { DateTime } from "luxon"
import uuid from "react-uuid"

export type IEinteilungForm = Instance<typeof EinteilungForm>
export type IEinteilungForms = Instance<typeof EinteilungForms>
// const IConfirmedEinteilung = types.model({
//     userID: types.string,
//     positionID: types.string,
// })

// let listInstance: IEinteilungForms

const TEinteilungItem = types.model({
    id: types.string,
    name: types.string,
    position: types.optional(types.string, ""),
    time: types.optional(types.string, ""),
})

const TAdditionalPosItem = types.model({
    additionalposition: types.string,
    personalid: types.string,
    title: types.string,
    name: types.string,
    time: types.string,
})

const TCustomTime = types.model({
    pos: types.identifier,
    time: types.string,
})

export const EinteilungForm = types
    .model({
        einteilungID: types.string,
        locationID: types.string,
        title: types.string,
        date: types.string,
        ts: types.number,
        status: types.string,
        additionalInfo: types.string,
        additionalPos: types.optional(types.array(TAdditionalPosItem), []),
        available: types.optional(types.array(TEinteilungItem), []),
        entered: types.optional(types.array(TEinteilungItem), []),
        oblpos: types.optional(types.array(types.string), []),
        customtimes: types.optional(types.array(TCustomTime), []),
        mode: types.number,
    })
    .volatile((self) => ({
        selected: false,
        active: false,
    }))
    .actions((self) => {
        const actions = {
            select: () => {
                self.selected = true
            },
            unselect: () => {
                self.selected = false
            },
            setActive: () => {
                self.active = true
            },
            setInactive: () => {
                self.active = false
            },
            updateStatus: (newstatus: string) => {
                self.status = newstatus
            },
            addAdditionalPos: () => {
                self.additionalPos.push({
                    additionalposition: uuid(),
                    personalid: "",
                    title: "Zusatz",
                    name: "",
                    time: "",
                })
            },
            removeFromEntered: (id, name) => {
                applySnapshot(
                    self.entered,
                    self.entered.filter((el) => el.id !== id),
                )
                // removeMeFromEinteilung({einteilungID: self.einteilungID, name, myID: id})
            },
            removeFromAvailable: (id) => {
                applySnapshot(
                    self.available,
                    self.available.filter((el) => el.id !== id),
                )
            },
            addToEntered: (id, name, cat, time) => {
                self.entered.push({ id, name, position: cat, time })
                // addMeToEinteilungConfirmed({ einteilungID: self.einteilungID, name, myID: id })
            },
            addToAvailable: (id, name) => {
                self.available.push({ id, name, position: "", time: "" })
                // addToMyEntered(id, self.einteilungID)
            },
            findForPos: (pos: string) => {
                return self.entered.find((el) => {
                    return el.position === pos
                })
            },
            updateObl: (val: string[]) => {
                return self.oblpos.replace(val)
            },
            updateCustomtimes: (val: Array<{ pos: string; time: string }>) => {
                return self.customtimes.replace(val)
            },
            updateEntered: (id, cat, time) => {
                const newentered = self.entered.map((el) => {
                    if (el.id === id) {
                        return { id: el.id, name: el.name, position: cat, time }
                    }
                    return { id: el.id, name: el.name, position: el.position, time }
                })
                self.entered.clear()
                applySnapshot(self.entered, newentered)
            },
            updateEnteredTime: (posid, time) => {
                const customTime = self.customtimes.filter((el) => el.pos === posid)
                if (customTime.length > 0) {
                    customTime[0].time = time
                } else {
                    self.customtimes.push({ pos: posid, time })
                }
                // const newentered = self.entered.map((el) => {
                //     if (el.position === posid) {
                //         return { id: el.id, name: el.name, position: el.position, time }
                //     }
                //     return { id: el.id, name: el.name, position: el.position, time: el.time }
                // })
                // self.entered.clear()
                // applySnapshot(self.entered, newentered)
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get readableDate() {
                return toHumanDate(self.date)
            },
        }
        return views
    })

export const getSortEl = (item: IEinteilungForm) => {
    if (item.title != null) {
        return item.title || 0
    }
    return 0
}

const sortGetVAl = (obj: IEinteilungForm, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<any> => getlocations()

export interface IEinteilungformFilter {
    query?: string
    locationIDs?: string[]
    status?: string[]
    mystatus?: string[]
}

const filterItems = (
    items: IEinteilungForm[],
    filter: IEinteilungformFilter = {},
    sortCol: string = "date",
    sortAsc: boolean,
): IEinteilungForm[] => {
    if (Object.keys(filter).length <= 0) {
        return items.slice().sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((item) => {
            // let returnindex = 0
            if (filter.locationIDs) {
                if (!(filter.locationIDs.indexOf(item.locationID) > -1)) {
                    return false
                }
            }
            if (filter.status) {
                if (!(filter.status.indexOf(item.status) > -1)) {
                    return false
                }
            }
            if (filter.mystatus) {
                const myID = getCookie("userid")
                if (filter.mystatus.indexOf("0") > -1 && filter.mystatus.indexOf("1") > -1) {
                    // filler
                } else {
                    if (filter.mystatus.indexOf("0") > -1) {
                        const enteredin = item.entered.filter((el) => el.id === myID)
                        const availableIn = item.available.filter((el) => el.id === myID)
                        if (enteredin.length > 0) {
                            // returnindex++
                            return false
                        }
                        if (availableIn.length > 0) {
                            // returnindex++
                            return false
                        }
                    }
                    if (filter.mystatus.indexOf("1") > -1) {
                        const enteredin = item.entered.filter((el) => el.id === myID)
                        const availableIn = item.available.filter((el) => el.id === myID)
                        if (enteredin.length === 0 && availableIn.length === 0) {
                            return false
                        }
                    }
                }
            }
            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

const loadEinteilungForms = (): Promise<IEinteilungFormResponse[]> => requestActiveEinteilungen()

export const EinteilungForms = types.compose(
    types
        .model({
            items: types.optional(types.array(EinteilungForm), []),
            loaded: types.optional(types.boolean, false),
        })
        .volatile((self) => ({
            searchstring: "",
            filterObj: {},
            sortAsc: false,
            sortCol: "date",
        }))
        .actions((self) => {
            const actions = {
                setSearchString: (crit: string) => {
                    return (self.searchstring = crit)
                },
                setSortCol: (col: string) => {
                    return (self.sortCol = col)
                },
                setSortDir: (asc: boolean) => {
                    return (self.sortAsc = asc)
                },
                setFilterObj: (fObj: IEinteilungformFilter) => {
                    return (self.filterObj = fObj)
                },
                addItems: (item: IEinteilungForm) => {
                    self.items.push(item)
                },
                clearList: () => {
                    self.items.clear()
                },
                setLoaded: () => {
                    self.loaded = true
                },
                addToEinteilungStates: (res) => {
                    einteilungstate.addEinteilungen(res)
                },
                addToEinteilungen: (res: IEinteilungForm) => {
                    self.items.push(res)
                },
                load: (loadopts: { from?: number; to?: number }) => {
                    let from = loadopts.from
                    let to = loadopts.to
                    if (loadopts.from == null) {
                        from = Math.round(DateTime.local().plus({ month: 2 }).toMillis() / 1000)
                    }
                    if (loadopts.to == null) {
                        to = Math.round(DateTime.local().minus({ day: 1 }).toMillis() / 1000)
                    }
                    // to: newest date
                    // from: oldest date
                    // if (guistate.client_id == null || loaded) {
                    //     return
                    // }

                    try {
                        actions.clearList()
                        requestActiveEinteilungen(from, to).then((res: IEinteilungFormResponse[]) => {
                            try {
                                applySnapshot(self.items, res)
                                try {
                                    actions.addToEinteilungStates(res)
                                } catch (error) {
                                    console.error(error)
                                }
                                actions.setLoaded()
                            } catch (err) {
                                console.error(err)
                            }
                        })
                    } catch (err) {
                        console.error(err)
                    }
                },
            }
            return actions
        })
        .views((self) => {
            const views = {
                count: () => {
                    return self.items.length
                },
                countSelected: () => {
                    const selected = self.items.filter((el) => el.selected === true)
                    return selected.length
                },
                countForLocation: (locationID, status?: string) => {
                    return self.items.filter((el) => el.status === status && el.locationID === locationID).length
                },
                selectedItems: (): IEinteilungForm[] => {
                    const selected = self.items.filter((el) => el.selected === true)
                    return selected
                },
                unselectAll: () => {
                    self.items.forEach((el) => el.unselect())
                },
                find: (id: string) => {
                    return self.items.find((el) => {
                        return el.einteilungID === id
                    })
                },
                filter: (query: string | null | any): IEinteilungForm[] => {
                    if (query == null) {
                        return self.items
                    }
                    // return self.items
                    if (typeof query === "string") {
                        return filterItems(self.items, { query }, self.sortCol, self.sortAsc)
                    }
                    return filterItems(self.items, query, self.sortCol, self.sortAsc)
                },
                onlyOpen: (): IEinteilungForm[] => {
                    return views.filter({ status: "1" })
                },
                onlyClosed: (): IEinteilungForm[] => {
                    return views.filter({ status: "2" })
                },
                filtered: (status): IEinteilungForm[] => {
                    if (self.filterObj !== {}) {
                        return views.filter(self.filterObj)
                    }
                    return views.filter(self.searchstring)
                },
                restricted: (status: string): IEinteilungForm[] => {
                    if (self.filterObj !== {}) {
                        self.setFilterObj({ ...self.filterObj, status: [status] })
                        return views.filter(self.filterObj)
                    }
                    return views.filter(self.searchstring)
                },
                first: () => {
                    return self.items[0]
                },
                filteroptions: (showstatus: boolean, showmystatus?: boolean) => {
                    const filteroptions = [
                        {
                            filtertitle: "locationIDs",
                            type: "multi",
                            filterlist: [
                                { value: "6", label: "Heart" },
                                { value: "2", label: "MIA" },
                                { value: "3", label: "Z Cham" },
                                { value: "4", label: "Z Köz" },
                            ],
                        },
                    ]
                    if (showstatus) {
                        filteroptions.push({
                            filtertitle: "status",
                            type: "multi",
                            filterlist: [
                                { value: "0", label: "Entwurf" },
                                { value: "1", label: "Offen" },
                                { value: "2", label: "Geschlossen" },
                            ],
                        })
                    }
                    if (showmystatus) {
                        filteroptions.push({
                            filtertitle: "mystatus",
                            type: "multi",
                            filterlist: [
                                { value: "0", label: "Nicht eingetragen" },
                                { value: "1", label: "Eingetragen" },
                                //  { value: "2", label: "Eingeteilt" }
                            ],
                        })
                    }
                    return filteroptions
                },
                mailView: (type: string) => {
                    const weekday = (obj: IEinteilungForm) => {
                        return weekDay(obj.date, true)
                    }

                    const parsedDate = (obj: IEinteilungForm) => {
                        return toHumanDate(obj.date) + " " + weekday(obj)
                    }

                    const parsedClub = (obj: IEinteilungForm) => {
                        const location = locations.find(obj.locationID)
                        if (location) {
                            return location.locationName
                        }
                        return ""
                    }

                    const viewtype = () => {
                        switch (type) {
                            case "all":
                                return views.filter({})
                            case "onlyopen":
                                return views.onlyOpen()
                            default:
                                return views.onlyClosed()
                        }
                    }

                    const data: ITableObject = {
                        columns: [
                            {
                                label: "Datum",
                                small: true,
                                viewFn: parsedDate,
                                valkey: "date",
                                sortable: true,
                            },
                            {
                                label: "Club",
                                small: true,
                                viewFn: parsedClub,
                                valkey: "location",
                                sortable: false,
                            },
                            {
                                label: "Titel",
                                small: true,
                                valkey: "title",
                                sortable: false,
                            },
                        ],
                        data: viewtype(),
                    }
                    return data
                },
                tableView: (
                    showentered: boolean,
                    filter: string[] | null,
                    userid: string | null,
                    statusfilter: string[],
                    restrictto?: "0" | "1" | "2",
                ) => {
                    const countEntered = (obj: IEinteilungForm) => {
                        const location = locations.find(obj.locationID)
                        if (location) {
                            const posCount = location.positionIDs.split(";").length
                            return obj.available.length + " | " + obj.entered.length + " | " + posCount
                        }
                        return ""
                    }

                    const weekday = (obj: IEinteilungForm) => {
                        return weekDay(obj.date, true)
                    }

                    const parsedDate = (obj: IEinteilungForm) => {
                        return toHumanDate(obj.date) + " " + weekday(obj)
                    }

                    const parsedClub = (obj: IEinteilungForm) => {
                        const location = locations.find(obj.locationID)
                        if (location) {
                            return location.locationName
                        }
                        return ""
                    }

                    const returnEnterIcon = (obj: IEinteilungForm): JSX.Element => {
                        if (userid) {
                            const availableIn = obj.available.filter((el) => el.id === userid)
                            const enteredIn = obj.entered.filter((el) => el.id === userid)
                            if (obj.status === "2") {
                                return renderOutAndClosedIcon()
                            }
                            if (availableIn.length > 0) {
                                return renderInIcon()
                            } else if (enteredIn.length > 0) {
                                return renderInAndClosedIcon()
                            }
                        }
                        return renderOutIcon()
                    }

                    const data: ITableObject = {
                        columns: [
                            {
                                label: "Datum",
                                small: true,
                                viewFn: parsedDate,
                                valkey: "date",
                                sortable: true,
                                className: "showinmobile col-3",
                            },
                            {
                                label: "Club",
                                small: true,
                                viewFn: parsedClub,
                                valkey: "location",
                                sortable: false,
                                className: "showinmobile col-3",
                            },
                            {
                                label: "Titel",
                                small: true,
                                valkey: "title",
                                sortable: false,
                                className: "showinmobile col-3",
                            },
                        ],
                        data: restrictto ? views.restricted(restrictto) : views.filtered(statusfilter),
                    }

                    if (showentered) {
                        data.columns.push(
                            {
                                label: "Eingetragen",
                                small: true,
                                viewFn: countEntered,
                                className: "hideinmobile",
                                valkey: "entered",
                                sortable: false,
                            },
                            {
                                label: "Status",
                                small: true,
                                viewFn: renderStatus,
                                className: "hideinmobile",
                                valkey: "status",
                                sortable: false,
                            },
                        )
                    }

                    if (userid) {
                        data.columns.push({
                            label: "",
                            small: true,
                            viewFn: returnEnterIcon,
                            valkey: "eingetragen",
                            sortable: true,
                        })
                    }

                    return data
                },
            }
            return views
        })
        .actions((self) => {
            return {
                setActive: (id: string) => {
                    const elem = self.find(id)
                    if (elem) {
                        elem.setActive()
                        self.items.forEach((el) => {
                            if (el.einteilungID !== id) {
                                el.setInactive()
                            }
                        })
                    }
                },
            }
        }),
    ListLoader<any>(EinteilungForm, loadEinteilungForms),
)

const iInstance: IEinteilungForms = EinteilungForms.create({})
export default iInstance
