import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

export type TReminder = [HourReminder[], DayReminder[], WeekReminder[]]

export type HourReminder = 1 | 2 | 4 | 6 | 8 | 10 | 12 | 16 | 20 | 24
export type DayReminder = 1 | 2 | 3 | 4 | 5 | 6 | 7
export type WeekReminder = 1 | 2 | 3

export interface IProfileUpdate {
    email: string
    news: string[]
    remindersettings: number[][]
    meta: { address: string; mobile: string }
}

export interface IPasswordUpdate extends IProfileUpdate {
    password: string
}

// tslint:disable-next-line: no-empty-interface
export interface ILoginResult {}

export const updateData = (data: IProfileUpdate): Promise<any> => {
    const { email, news, remindersettings, meta } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/profile.php"
    const id = getCookie("userid")
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `update`,
                userid: id,
                metainfo: `{"address": "${meta.address}", "mobile": "${meta.mobile}"}`,
                newsettings: [email, news, JSON.stringify(remindersettings)],
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => resolve(res.responseText))
    })
}

export const updatePw = (data: IPasswordUpdate): Promise<any> => {
    const { email, news, remindersettings, password, meta } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/profile.php"
    const id = getCookie("userid")
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `updatepw`,
                userid: id,
                password,
                metainfo: `{"address": "${meta.address}", "mobile": "${meta.mobile}"}`,
                newsettings: [email, JSON.stringify(news), JSON.stringify(remindersettings)],
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .always((res: any) => resolve(res.responseText))
    })
}
