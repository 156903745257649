import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import "./employees.css"

import "react-toastify/dist/ReactToastify.css"
import DetailView from "../../components/detailview"
import { jumpTo } from "."
import { IUserItem } from "../../stores/userdata"
import bind from "bind-decorator"
import Card from "../../components/card"
import { LOCATIONS } from "stores/locations"
// import Filelist from "components/filelist"

interface IEmployeesViewProps {
    user: IUserItem
}

@observer
class EmployeesView extends Component<IEmployeesViewProps, {}> {
    public render() {
        const { user } = this.props
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <DetailView
                header={() => this.renderHeader(user)}
                content={() => this.renderInfo()}
                onClose={() => jumpTo({ type: "list" })}
                onEdit={() =>
                    jumpTo({
                        type: "edit",
                        id: user.userID,
                    })
                }
            ></DetailView>
        )
    }

    private renderHeader(user: IUserItem) {
        return <h3>{user.realName}</h3>
    }

    @bind
    private renderInfo() {
        const { user } = this.props
        return (
            <>
                <Card dark={true} content={user.realName} label="Name"></Card>
                <br></br>
                <Card dark={true} content={user.username} label="Nutzername"></Card>
                <br></br>
                <Card dark={true} content={user.readableMail} label="E-Mail"></Card>
                <br></br>
                <Card dark={true} content={user.readableonline} label="Zuletzt online"></Card>
                <br></br>
                <Card
                    dark={true}
                    content={user.parsedMeta.address !== "" ? user.parsedMeta.address : "-"}
                    label="Adresse"
                ></Card>
                <br></br>
                <Card
                    dark={true}
                    content={user.parsedMeta.mobile !== "" ? user.parsedMeta.mobile : "-"}
                    label="Telefon"
                ></Card>
                <br></br>
                <Card
                    className={"h-36"}
                    dark={true}
                    content={<div className="flex flex-col">{user.preferredLocations().map((locationID) => {
                        return <span className="text-sm font-semibold">{LOCATIONS[locationID]}</span>
                    })}</div>}
                    label="Clubs"
                />
                {/* <Filelist edit={false} userID={user.userID} path="contracts/" /> */}
            </>
        )
    }
}

export default EmployeesView
