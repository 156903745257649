
import { createBrowserHistory } from "history"
import { RouterStore, syncHistoryWithStore } from "mobx-react-router"

const browserHistory = createBrowserHistory({basename: "carpenoctem"})
export const routingStore = new RouterStore()
export const history = syncHistoryWithStore(browserHistory, routingStore)

export const historyHandler = (path: string) => {
    // const oldpath = history.location.pathname.split("/")[1]
    // const newpath = path
    history.location.pathname = "/"
    history.push(path)
}
