import { Instance, types } from "mobx-state-tree"
export type TMenuIcons = "edit" | "eintragen" | "meineeinteilung" | "einteilung" | "mail"

interface IMenu {
    title: string
    path: string
    icon: string
    minrole: number
}

export type IGuiState = Instance<typeof Menu>
export type IMenus = Instance<typeof Menus>

const Menu = types
    .model({
        title: types.optional(types.string, ""),
        path: types.optional(types.string, ""),
        icon: types.optional(types.string, ""),
        minrole: types.optional(types.number, 0),
    })
    .actions((self: any) => {
        const actions = {}
        return actions
    })

const Menus = types
    .model({
        items: types.array(Menu),
    })
    .volatile((self) => ({
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            load: (userrole: number) => {
                actions.clearList()
                const allmenus = [
                    { title: "Einteilung (Admin)", icon: "edit", path: "admineinteilung", minrole: 2 },
                    { title: "Mailservice", icon: "mail", path: "mailservice", minrole: 2 },
                    { title: "Eintragen", icon: "eintragen", path: "entereinteilung", minrole: 4 },
                    { title: "Meine Einteilungen", icon: "myeinteilung", path: "meineeinteilung", minrole: 4 },
                    { title: "Einteilung", icon: "einteilung", path: "einteilung", minrole: 4 },
                    { title: "Nutzer", icon: "users", path: "employees", minrole: 2 },
                ]
                allmenus.forEach((el) => {
                    if (el.minrole >= userrole) {
                        actions.addItem(el)
                    }
                })
                self.loaded = true
            },
            clearList: () => {
                self.items.clear()
            },
            addItem: (elem: IMenu) => {
                self.items.push(elem)
            },
        }
        return actions
    })

const iInstance: IMenus = Menus.create({})
export default iInstance
