import React, { Component } from "react"
import { observer } from "mobx-react"
import { RadioButtonChecked, RadioButtonUnchecked, Cancel } from "@material-ui/icons"
import "./headerfilter.css"
import HeaderDatePicker from "../datepicker"
import locations from "../../stores/locations"

interface IHeaderFilterState {
  filteropen: boolean
}

interface IHeaderFilterProps {
  filteroptions?: Array<{ filtertitle: string, type: string, filterlist: any[] }>
  currentstate: any
  withdatefilter?: boolean
  withfilterbutton?: boolean
  hidestatus: boolean
  removeFilter: ({ type, val }) => void
  onReset?: () => void
  onConfirm: (filter: any) => void
  onDateConfirm?: (data: any) => void
  // onOptionFilter: (
  //     filteroption: string,
  //     add: boolean,
  //     truncate: boolean,
  //     source: string,
  // ) => void
  onOptionFilter: (
    filteroption: string | string[] | boolean | null | undefined,
    truncate: boolean,
    filtertype: string,
  ) => void
  customContent?: React.ReactNode
}

@observer
class HeaderFilter extends Component<IHeaderFilterProps, IHeaderFilterState> {
  constructor(props: any) {
    super(props)

    this.state = {
      filteropen: false,
    }

    this.confirmDate = this.confirmDate.bind(this)
    this.returnActiveFilters = this.returnActiveFilters.bind(this)
  }

  public render() {
    const { filteropen } = this.state
    const { currentstate, withdatefilter, withfilterbutton, customContent } = this.props
    return (
      <React.Fragment key="">

        <div key="1" className="header-filter">
          {withdatefilter && (
            <HeaderDatePicker
              onDateConfirm={this.confirmDate}
            ></HeaderDatePicker>
          )}
          {
            this.returnActiveFilters(currentstate)
          }
          <div className="filter-wrp">
            {withfilterbutton && <div
              key="filter-button"
              className="filter-button"
              onClick={() => {
                this.setState({
                  filteropen: true,
                })
              }}
            >
              Filter
            </div>}
            {customContent}
          </div>
        </div>
        {filteropen && (
          <div
            key="2"
            className={"filter-dialog" + this.getSizeClass(currentstate)}
          >
            <p>Filter</p>
            {this.renderOptions()}
            <div className="dialog-buttons">
              <div
                className="dialog-button single"
                onClick={() => {
                  this.setState({
                    filteropen: false,
                  })
                }}
              >
                Schließen
              </div>
              {/* <div
                    className="dialog-button"
                    onClick={() => {
                      this.confirmFilter(currentstate);
                      this.setState({
                        filteropen: false
                      });
                    }}
                  >
                    Anwenden
                  </div> */}
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  private renderOptions() {
    const { filteroptions } = this.props
    if (filteroptions) {
      return (
        <React.Fragment>
          {
            filteroptions.map((item, idx) => {
              return (
                <React.Fragment key={"option_" + idx}>
                  <hr></hr>
                  <div className={"filter-area " + idx} key={idx}>
                    {item.type === "multi" &&
                      this.renderCheckboxes(item.filterlist, item.filtertitle)
                    }
                    {item.type === "single" &&
                      this.renderRadiobuttons(item.filterlist, item.filtertitle)
                    }
                  </div>
                </React.Fragment>
              )
            })
          }
        </React.Fragment>
      )
    }
    return undefined
  }

  private renderCheckboxes(options: Array<{ label: string, value: string }>, src) {
    const { currentstate, onOptionFilter } = this.props
    return options.map((el, idx) => {
      return (
        <React.Fragment key={idx}>
          <div className="filter-checkboxes">
            <div className="checkbox-wrapper">
              {this.renderChecked(currentstate[src], el.value) && (
                <RadioButtonChecked
                  onClick={() => {
                    onOptionFilter(el.value, false, src)
                  }}
                ></RadioButtonChecked>
              )}
              {!this.renderChecked(currentstate[src], el.value) && (
                <RadioButtonUnchecked
                  onClick={() => {
                    onOptionFilter(el.value, false, src)
                  }}
                ></RadioButtonUnchecked>
              )}
            </div>
            <label htmlFor={"checkbox_" + idx}>{el.label}</label>
          </div>
        </React.Fragment>
      )
    })
  }

  private returnActiveFilters(currstate) {
    const { removeFilter, hidestatus } = this.props
    const returnedFilters: Array<{ type: string, val: string }> = []
    Object.keys(currstate).forEach((elem) => {
      switch (elem) {
        case "locationIDs":
          // const returnedlocation = "Alle Clubs"
          const loc = locations.find(currstate[elem])
          if (loc) {
            returnedFilters.push({
              type: "locationIDs",
              val: loc.locationName,
            })
          }
          break
        case "mystatus":
          let returnedmystatus = ""
          if (currstate[elem] === "0") {
            returnedmystatus = "Nicht eingetragen"
          } else if (currstate[elem] === "1") {
            returnedmystatus = "Eingetragen"
          }
          returnedFilters.push({ type: "mystatus", val: returnedmystatus })
          break
        case "status":
          let returnedstatus = ""
          if (currstate[elem] === "0") {
            returnedstatus = "Entwurf"
          } else if (currstate[elem] === "1") {
            returnedstatus = "Offen"
          } else if (currstate[elem] === "2") {
            returnedstatus = "Geschlossen"
          }
          if (!hidestatus) {
            returnedFilters.push({ type: "status", val: returnedstatus })
          }
          break
      }
    })
    return <div className="activefilters" key="activefilters">
      {
        returnedFilters.map((el, idx) => {
          if (el.val !== "") {
            return (
              <div className="activefilter-elem" key={"activefilter_" + idx}>
                <Cancel
                  onClick={() => {
                    removeFilter(el)
                  }}
                />
                <span>{el.val}</span>
              </div>
            )
          }
          return <div key="empty" ></div>
        })
      }
    </div>
  }

  private confirmFilter(currentstate) {
    const { onConfirm } = this.props
    onConfirm(currentstate)
  }

  private confirmDate(date) {
    const { onDateConfirm } = this.props
    if (onDateConfirm) {
      onDateConfirm(date)
    }
  }

  private renderChecked(currentstate, value): boolean {
    let checked = false
    if (currentstate.indexOf("" + value) > -1) {
      checked = true
    }
    return checked
  }

  private renderRadiobuttons(options: Array<{ label: string, value: string }>, src) {
    return options.map((el, idx) => {
      return <input key={idx} type="radio" value={el.value}>{el.label}</input>
    })
  }

  private getSizeClass(options) {
    let sizeclass = " small"
    const len = Object.keys(options).length
    if (len === 2) {
      sizeclass = " medium"
    } else if (len === 3) {
      sizeclass = " large"
    }
    return sizeclass
  }
}

export default HeaderFilter
