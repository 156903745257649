import { observer } from "mobx-react"
import * as React from "react"
import TableBody, { ITableAdditions } from "./body"
import TableHeader from "./header"
import "./table.scss"
import { getCookie } from "../../utils/cookiemanager"

type TKeyFunc = (obj: any) => string | number | boolean | JSX.Element
export interface ITableColumn {
    label: string
    valkey: string
    sortable: boolean
    width?: number
    small?: boolean
    viewFn?: TKeyFunc
    className?: string
}

export interface ITableData {
    [key: string]: any
}

export interface ITableObject {
    columns: ITableColumn[]
    data: ITableData[]
}

export interface ITableProps extends ITableObject {
    onOpen?: (el: any) => void
    unfolded?: boolean
    colSort?: boolean
    sortKey?: string
    onSort?: (key: string, sortDir: boolean) => void
    onSelect?: (el: any, state: boolean) => void
    onSelectAll?: (state: boolean) => void
    openId?: string | number
    renderDetailRow?: (el: any) => JSX.Element
    renderRowBanner?: (el?: any) => ITableAdditions | undefined
    renderDelete?: boolean
}

@observer
export default class Table extends React.Component<ITableProps, {}> {
    public render() {
        const { columns, onSelect, onSort, renderDetailRow, renderDelete, onSelectAll, sortKey, colSort } = this.props
        const colCount = columns.length
        if (onSelect != null) {
            columns.unshift({ valkey: "_sel", width: 10, label: "", sortable: true })
        }
        if (renderDetailRow != null) {
            columns.unshift({ valkey: "_detail", width: -10, label: "", sortable: false })
        }
        if (renderDelete) {
            columns.unshift({ valkey: "_delete", width: -10, label: "", sortable: false })
        }

        const specialclass = getCookie("userid") === "30" ? "spirli-color" : ""

        return (
            <div className="table">
                <div className="tbl-header">
                    <table cellPadding="0" cellSpacing="0">
                        {this.colGroup(columns, colCount, true)}
                        <thead className={specialclass}>
                            <tr>
                                {columns.map((col, idx) => (
                                    <TableHeader
                                        key={"th" + idx}
                                        onSelectAll={onSelectAll}
                                        col={col}
                                        onSort={onSort}
                                        sortKey={sortKey}
                                        colSort={colSort}
                                    />
                                ))}
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="tbl-content">
                    <table cellPadding="0" cellSpacing="0">
                        {this.colGroup(columns, colCount)}
                        <TableBody {...this.props} />
                    </table>
                </div>
            </div>
        )
    }

    private colGroup(columns: ITableColumn[], colCount: number, isHead = false): JSX.Element {
        return (
            <colgroup>
                {columns.map((col, idx) => (
                    <col
                        key={(isHead ? "ch" : "cd ") + idx}
                        className={"col_" + col.valkey + " " + (col.className || "")}
                        width={col.width != null ? col.width + 30 : 100 / colCount + "%"}
                    ></col>
                ))}
            </colgroup>
        )
    }
}
