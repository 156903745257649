import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import { IEinteilungForm } from "../../stores/einteilungforms"
import einteilungforms from "../../stores/einteilungforms"
import locations from "../../stores/locations"
import userdata from "../../stores/userdata"
import guistate from "../../stores/guistate"
import Loading from "../../components/loading"
import { jumpTo } from "."
import { Add, Launch, Lock, Edit } from "@material-ui/icons"
import Table from "../../components/table"
import HeaderFilter from "../../components/Headerfilter"
import { DateTime } from "luxon"
import { bulkupdateEinteilungFormStatus } from "../../api/neweinteilungform"

interface IListState {
    statusfilter: string[]
    clubfilter: string[]
}

export const renderStatus = (obj) => {
    switch (obj.status) {
        case "0":
            return "Entwurf"
        case "1":
            return "Offen"
        case "2":
            return "Geschlossen"
        default:
            return ""
    }
}

interface IEinteilungListProps {
    clubfilter: string
    status: string
    type: string
    id: string
}

@observer
class EinteilungList extends Component<IEinteilungListProps, IListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            statusfilter: ["0", "1", "2"],
            clubfilter: ["1", "2", "3", "4", "5", "6"],
        }

        this.filter = this.filter.bind(this)
        this.filterFunc = this.filterFunc.bind(this)
        this.onOpen = this.onOpen.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.confirmFilter = this.confirmFilter.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { clubfilter, statusfilter } = this.state
        const { columns, data } = einteilungforms.tableView(true, clubfilter, null, statusfilter)
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading />}
                <HeaderFilter
                    filteroptions={einteilungforms.filteroptions(true)}
                    currentstate={{
                        locationIDs: this.props.clubfilter,
                        status: this.props.status,
                    }}
                    hidestatus={false}
                    removeFilter={this.removeFilter}
                    onConfirm={this.confirmFilter}
                    onDateConfirm={this.onDateConfirm}
                    onOptionFilter={this.filterFunc}
                    withdatefilter={true}
                    withfilterbutton={true}
                />
                <Table
                    columns={columns}
                    data={data}
                    onSort={this.onSort}
                    // unfolded={tag.unfolded}
                    onSelect={this.onSelect}
                    sortKey={einteilungforms.sortCol}
                    colSort={einteilungforms.sortAsc}
                    onOpen={this.onOpen}
                ></Table>
                {this.renderNewEinteilungButton()}
                {einteilungforms.countSelected() > 0 && (
                    <>
                        {this.renderOpenBulkOpButton()}
                        {this.renderCloseBulkOpButton()}
                        {this.renderDraftBulkOpButton()}
                    </>
                )}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        if (!locations.loaded) {
            locations.load()
        }
        if (!userdata.loaded) {
            userdata.load()
        }
        if (!einteilungforms.loaded) {
            /* einteilungforms.setFilterObj({ status: ["0", "1", "2"] });
    } else { */
            einteilungforms.load({})
        }
    }

    private onSelect(item: IEinteilungForm) {
        if (item.selected) {
            item.unselect()
        } else {
            item.select()
        }
    }

    private renderNewEinteilungButton() {
        return (
            <div
                className="action-icon"
                onClick={() => {
                    jumpTo({ type: "new" })
                }}
            >
                <Add></Add>
                <span>Neue Einteilung</span>
            </div>
        )
    }

    private renderOpenBulkOpButton() {
        return (
            <div
                className="action-icon bulk-open"
                onClick={() => {
                    const ids = einteilungforms.selectedItems().map((el) => el.einteilungID)
                    bulkupdateEinteilungFormStatus({ ids, status: "1" }).then((res) => {
                        if (res === "success") {
                            einteilungforms.selectedItems().forEach((elem) => elem.updateStatus("1"))
                        }
                    })
                }}
            >
                <Launch></Launch>
                <span>Freigeben</span>
            </div>
        )
    }

    private renderCloseBulkOpButton() {
        return (
            <div
                className="action-icon bulk-close"
                onClick={() => {
                    const ids = einteilungforms.selectedItems().map((el) => el.einteilungID)
                    bulkupdateEinteilungFormStatus({ ids, status: "2" }).then((res) => {
                        if (res === "success") {
                            einteilungforms.selectedItems().forEach((elem) => elem.updateStatus("2"))
                        }
                    })
                }}
            >
                <Lock></Lock>
                <span>Schließen</span>
            </div>
        )
    }

    private renderDraftBulkOpButton() {
        return (
            <div
                className="action-icon bulk-draft"
                onClick={() => {
                    const ids = einteilungforms.selectedItems().map((el) => el.einteilungID)
                    bulkupdateEinteilungFormStatus({ ids, status: "0" }).then((res) => {
                        if (res === "success") {
                            einteilungforms.selectedItems().forEach((elem) => elem.updateStatus("0"))
                        }
                    })
                }}
            >
                <Edit></Edit>
                <span>Entwurf</span>
            </div>
        )
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    private onOpen(item: IEinteilungForm) {
        const { clubfilter, status } = this.props
        const parsedFilter: any = {
            locationIDs: clubfilter,
            status,
        }
        jumpTo({ type: "view", id: item.einteilungID, filter: parsedFilter })
    }

    private removeFilter(data: { type: string; val: string }) {
        const { type } = this.props
        switch (data.type) {
            case "locationIDs":
                const parsedFilter: any = {
                    locationIDs: "",
                    status: this.props.status,
                }
                jumpTo({ type, filter: parsedFilter })
                break
            case "status":
                const parsedFilterWoStatus: any = {
                    locationIDs: this.props.clubfilter,
                    status: "",
                }
                jumpTo({ type, filter: parsedFilterWoStatus })
                break
        }
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(
                DateTime.local()
                    .plus({ month: 2 })
                    .toMillis() / 1000,
            )
        }
        if (data.to == null) {
            to = Math.round(
                DateTime.local()
                    .minus({ month: 2 })
                    .toMillis() / 1000,
            )
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        einteilungforms.load({ from, to })
        // console.log(from, to)
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, statusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        statusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                statusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "Status") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private filterFunc(
        filterstr: string | string[] | boolean | null | undefined,
        truncate: boolean,
        filtertype: string,
    ) {
        let einteilungFilter: any = {}
        const savedState: any = einteilungforms.filterObj || {}
        if (!truncate) {
            Object.keys(savedState).forEach((elem: any) => {
                if (savedState[elem] == null || savedState[elem] === "") {
                    einteilungFilter[elem] = null
                } else {
                    einteilungFilter[elem] = savedState[elem]
                }
            })
            einteilungFilter[filtertype] = filterstr
        } else {
            einteilungFilter = {}
        }
        // console.log(this.props.type, ": ", einteilungFilter)
        jumpTo({
            type: this.props.type ? this.props.type : "list",
            id: this.props.id,
            filter: einteilungFilter,
        })
    }

    private confirmFilter(filter) {
        //     einteilungforms.setFilterObj({
        //       locationIDs: filter["Club"],
        //       status: filter["Status"]
        //     });
    }
}

export default EinteilungList
