import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import positions from "../../stores/positions"
import { IEinteilungForm } from "../../stores/einteilungforms"
import locations from "../../stores/locations"
import userdata from "../../stores/userdata"
import { jumpTo } from "."
import DetailView from "../../components/detailview"
import { exportSingleEinteilung } from "../../utils/pdfexport"
import { getSnapshot } from "mobx-state-tree"
import { SaveAlt } from "@material-ui/icons"

interface IEinteilungProps {
    einteilung: IEinteilungForm
    filter: any
}

interface IEinteilungState {
    status: string
}

@observer
class EinteilungView extends Component<IEinteilungProps, IEinteilungState> {
    constructor(props: any) {
        super(props)

        this.state = {
            status: this.props.einteilung.status,
        }
    }

    public render() {
        const { einteilung, filter } = this.props
        const location = locations.find(einteilung.locationID)

        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const useBlocked = () => {
            if (einteilung.locationID === "5" || einteilung.locationID === "6") {
                return false
            }
            return true
        }
        return (
            <React.Fragment>
                <DetailView
                    header={() => this.renderHeader(einteilung, location)}
                    content={() => {
                        return (
                            <React.Fragment>
                                {einteilung.additionalInfo !== "" && this.renderAdditionalInfo()}
                                {this.renderPosList()}
                                {!useBlocked() && <><h4 className="available-h4">Optional:</h4>
                                    {this.renderAvailable()}</>}

                                {/* this.renderPrintButton() */}
                            </React.Fragment>
                        )
                    }}
                    onClose={() => jumpTo({ type: "list", filter })}
                ></DetailView>
            </React.Fragment>
        )
    }

    public componentDidMount() {
        positions.load()
    }

    private renderAdditionalInfo() {
        const { einteilung } = this.props
        return <p>{einteilung.additionalInfo}</p>
    }

    private renderAvailable() {
        const { einteilung } = this.props
        return (
            <div className="einteilung-available">
                <table>
                    <tbody>
                        {einteilung.available.map((el, idx) => {
                            const usr = userdata.find(el.id)
                            let name = el.name
                            if (usr) {
                                name = usr.realName
                            }
                            return (
                                <tr key={idx}>
                                    <td>{name}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    private renderPosList() {
        const { einteilung } = this.props
        // const positionlist = positions.items.map((el) => {
        //   if (el.locationID == einteilung.locationID) {
        //     return el
        //   }
        // })
        const locationPosList = positions.items.map((el) => {
            if (el.locationID === einteilung.locationID) {
                if (el.modesList().includes(einteilung.mode)) {
                    if (el) {
                        return el
                    }
                }
            }
            return undefined
        })

        const filteredList = locationPosList.filter((el) => el !== undefined)

        filteredList.sort((a, b) => {
            if (a && b) {
                if (einteilung.locationID === "1" || einteilung.locationID === "2" || einteilung.locationID === "6") {
                    return parseInt(a.sortNo, 10) > parseInt(b.sortNo, 10)
                        ? 1
                        : parseInt(b.sortNo, 10) > parseInt(a.sortNo, 10)
                            ? -1
                            : 0
                }
                return parseInt(a.positionID, 10) > parseInt(b.positionID, 10)
                    ? 1
                    : parseInt(b.positionID, 10) > parseInt(a.positionID, 10)
                        ? -1
                        : 0
            }
            return 0
        })
        if (filteredList) {
            return (
                <div className="einteilung-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Position</th>
                                <th>Mitarbeiter</th>
                                <th>Start</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((item, idx) => {
                                if (item) {
                                    const eingeteilt = einteilung.findForPos(item.positionID)
                                    const isObl =
                                        einteilung.oblpos.filter((elem) => elem === item.positionID).length > 0
                                    const show = item.showineinteilung === "1" || eingeteilt != null || isObl
                                    if (show) {
                                        if (eingeteilt) {
                                            const usr = userdata.find(eingeteilt.id)
                                            let name = eingeteilt.name
                                            if (usr) {
                                                name = usr.realName
                                            }
                                            return (
                                                <tr key={idx}>
                                                    <td className={`highlight-${item.color}`}>{item.positionName}</td>
                                                    <td>{name}</td>
                                                    <td className="time-td">{this.renderTime(item)}</td>
                                                </tr>
                                            )
                                        } else {
                                            return (
                                                <tr key={idx} className={`${isObl ? "needed" : ""}`}>
                                                    <td className={`highlight-${item.color}`}>{item.positionName}</td>
                                                    <td>{isObl ? "offen" : "-"}</td>
                                                    <td className="time-td">{this.renderTime(item)}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                }
                                return <></>
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    private renderTime(el) {
        const { einteilung } = this.props
        const einteilungPos = einteilung.customtimes.filter((elem) => elem.pos === el.positionID)
        if (einteilungPos.length > 0) {
            if (einteilungPos[0].time !== "") {
                return einteilungPos[0].time
            }
        }
        return el.starttime
    }

    private renderHeader(einteilung: IEinteilungForm, location: any) {
        return (
            <React.Fragment>
                {location && <div className="locationname">{location.locationName}</div>}
                <div className="einteilung">Einteilung {einteilung.readableDate}</div>
                <div className="einteilung-title">{einteilung.title}</div>
                <div className="printbutton">
                    <SaveAlt
                        onClick={() => {
                            const einteilungen = getSnapshot(einteilung)
                            const data = einteilungen.entered.map((el) => {
                                const posName = positions.items.filter((elem) => elem.positionID === el.position)
                                if (posName.length > 0) {
                                    return {
                                        name: el.name,
                                        position: posName[0].positionName,
                                        starttime: "",
                                    }
                                }
                                return undefined
                            }).filter((el) => el != null)
                            if (data.length > 0) {
                                exportSingleEinteilung(
                                    einteilung,
                                    positions.items.filter((el) => el.locationID === einteilung.locationID),
                                )
                            }
                        }}
                    ></SaveAlt>
                </div>
            </React.Fragment>
        )
    }

    private renderPrintButton(): JSX.Element[] | JSX.Element {
        const { einteilung } = this.props
        return (
            <div
                className="export-pdf button"
                onClick={() => {
                    const einteilungen = getSnapshot(einteilung)
                    const data = einteilungen.entered.map((el) => {
                        const posName = positions.items.filter((elem) => elem.positionID === el.position)
                        if (posName.length > 0) {
                            return {
                                name: el.name,
                                position: posName[0].positionName,
                                starttime: "",
                            }
                        }
                        return undefined
                    }).filter((el) => el != null)
                    if (data.length > 0) {
                        exportSingleEinteilung(
                            einteilung,
                            positions.items.filter((el) => el.locationID === einteilung.locationID),
                        )
                    }
                }}
            >
                Als PDF herunterladen
            </div>
        )
    }
}

export default EinteilungView
