import React, { Component } from "react"
import { observer } from "mobx-react"
import "./loading.css"
import LoadingIcon from "../../img/25.svg"

@observer
class Loading extends Component<{}, {}> {
    public render() {
        return (
            <div className="loading-wrp">
                <img alt={"Lädt..."} src={LoadingIcon} />
            </div>
        )
    }
}

export default Loading
