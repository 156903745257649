import React from "react"
import { login } from "../../api/login"
import { observer } from "mobx-react"
import guistate from "../../stores/guistate"
import "./login.css"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { updateApphead } from "../../utils/dommanip"
import einteilungforms from "../../stores/einteilungforms"
import dotenv from "dotenv"
import { jumpTo } from "apps/profil"

dotenv.config()
const VERSION: string = process.env.REACT_APP_VERSION_NUM || "0.5.0"
type TPwStates = "password" | "text"

interface ILoginState {
    pwstate: TPwStates
    errormsg: string
}

interface ILoginProps {
    setauth: (auth: boolean) => void
}

@observer
class Login extends React.Component<ILoginProps, ILoginState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)
        this.state = {
            pwstate: "password",
            errormsg: "",
        }
        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.tryLogin = this.tryLogin.bind(this)
        this.setPWVisible = this.setPWVisible.bind(this)
        this.setPWHidden = this.setPWHidden.bind(this)
        this.handleEnterKey = this.handleEnterKey.bind(this)
    }

    public render() {
        return (
            <div className="login-wrapper">
                <div className="versionnumber">{`v${VERSION}`}</div>
                <div className="login">
                    <input type="text" ref={this.nameRef} placeholder="Benutzername"></input>
                    <input ref={this.pwRef} placeholder="Passwort" type={this.state.pwstate}></input>
                    {this.state.pwstate === "password" && <Visibility onClick={() => this.setPWVisible()}></Visibility>}
                    {this.state.pwstate === "text" && (
                        <VisibilityOff onClick={() => this.setPWHidden()}></VisibilityOff>
                    )}
                    <button className="border-0 rounded-md" onClick={() => this.tryLogin()}>Login</button>
                    <br></br>
                    <span className="error-msg">{this.state.errormsg}</span>
                    <br></br>
                    <a href="https://aurora.uber.space/zinnoberapi/passwordreset/" target="_blank" rel="noreferrer">
                        Password vergessen?
                    </a>
                    <footer>
                        <p>
                            Bitte beachten Sie, dass zur Nutzung dieser Anwendung funktionale Cookies gesetzt werden
                            müssen.
                        </p>
                        <p>
                            Bei Fragen wenden Sie sich bitte an
                            <a href="mailto:support@auroramedien.de"> support@auroramedien.de</a>
                        </p>
                    </footer>
                </div>
            </div>
        )
    }

    public componentDidMount() {
        updateApphead("Login", "")
        document.addEventListener("keypress", this.handleEnterKey, false)
    }

    public componentWillUnmount() {
        document.removeEventListener("keypress", this.handleEnterKey, false)
    }

    private handleEnterKey(ev) {
        if (ev.keyCode === 13) {
            this.tryLogin()
        }
    }

    private setPWVisible() {
        this.setState({
            pwstate: "text",
        })
    }

    private setPWHidden() {
        this.setState({
            pwstate: "password",
        })
    }

    private tryLogin() {
        const { setauth } = this.props
        if (this.nameRef.current && this.pwRef.current) {
            if (this.nameRef.current.value !== "" && this.pwRef.current.value) {
                login(this.nameRef.current.value, this.pwRef.current.value).then((response: any) => {
                    if (response === "wrong pw") {
                        this.setState({
                            errormsg: "Falsches Passwort",
                        })
                    } else if (response === "unknown user") {
                        this.setState({
                            errormsg: "Nutzername nicht bekannt, bitte wenden Sie sich an Ihren Administrator",
                        })
                    } else {
                        guistate.setLoginData(
                            response.username,
                            response.id,
                            response.role,
                            response.realName,
                            true,
                            response.mail,
                            response.recnews,
                            response.mailsettings,
                            response.meta,
                            response.token,
                        )
                        einteilungforms.reset()
                        einteilungforms.load({})
                        document.removeEventListener("keyPress", this.handleEnterKey, false)
                        setauth(true)

                        if (response.mail === "") {
                            jumpTo({ type: "edit" })
                        }
                    }
                })
            }
        }
    }
}

export default Login
